import ActStatusCircle from "../../../UIElements/StatusCircle";
import Cookies from "js-cookie";
import EnumsContext from "../../../../context/enums-context";
import { formatCurrency, formatDate, formatShortDate } from "../../../../Utils/Utils";
import useApi from "../../../../Utils/BackendClient";
import { useContext, useEffect, useRef, useState } from "react";
import useGetEnums from "../../../../Utils/EnumsUtils";
import { useLocation, useNavigate } from "react-router-dom";

import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { confirmDialog, ConfirmDialog } from "primereact/confirmdialog";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { Sidebar } from "primereact/sidebar";
import { SplitButton } from "primereact/splitbutton";
import { Toast } from 'primereact/toast';
import { Toolbar } from "primereact/toolbar";

import commonStyle from "../../CommonStyles.module.css";
import { useFormik } from "formik";
import { InputText } from "primereact/inputtext";
import { Message } from "primereact/message";

const ProductsReception = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const toast = useRef(null);
    const buttonRef = useRef(null);
    const ctx = useContext(EnumsContext);
    const { createPayment, deleteReceptionAct, fetchActUnpaidBalance, fetchReceptionActs, finalizeReceptionAct } = useApi();
    const currencies = ["UAH", "USD", "EUR"];
    const paymentMethods = ["Термінал", "Готівка", "IBAN"];

    const [loading, setLoading] = useState(true);
    const [totalRecords, setTotalRecords] = useState(0);
    const [totalSumUAH, setTotalSumUAH] = useState(0);
    const [unDividedSum, setUnDividedSum] = useState(0);
    const [acts, setActs] = useState(null);
    const [visible, setVisible] = useState(false);
    const [lazyState, setlazyState] = useState({
        first: 0,
        rows: 10,
        page: 1,
        sortField: "created_at",
        sortOrder: 1,
        filters: {
            cagent: { value: null, matchMode: 'contains' },
            division: { value: null, matchMode: 'contains' },
            pay_status: { value: null, matchMode: 'contains' }
        }
    });
    const [reloadData, setReloadData] = useState(true);

    useGetEnums(["divisions", "partners", "pay_statuses"]);

    useEffect(() => {
        if (location.state !== null) {
            toast.current.show(location.state.toast);
        }
        location.state = null;
    }, [location.state, location]);

    const formik = useFormik({
        initialValues: {
            id: null,
            cagent_id: null,
            payments: []
        },
        enableReinitialize: true,
        validate: values => {
            const errors = {};

            values.payments.forEach((payment, index) => {
                if (payment.paymentMethod === null || payment.paymentMethod === '') {
                    if (!errors.payments) errors.payments = [];
                    errors.payments[index] = { paymentMethod: 'Вкажіть спосіб оплати' };
                }
            });

            return errors
        },
        onSubmit: values => {

            let paymentRequest = [];

            values.payments.forEach(payment => {

                let paymentType = "CASH";
                if (payment.paymentMethod === "Термінал") {
                    paymentType = "CARD";
                }
                else if (payment.paymentMethod === "IBAN") {
                    paymentType = "IBAN";
                }

                const singlePaymentRequest = {
                    act_id: values.id,
                    date: formatShortDate(Date.now()),
                    sum: payment.sum,
                    currency: payment.currency,
                    type: paymentType,
                    method: "OUTCOME",
                    cagent_id: values.cagent_id != null ? values.cagent_id : null,
                }

                paymentRequest.push(singlePaymentRequest);
            });

            const callBackEnd = async () => {

                const response = await createPayment(paymentRequest);

                console.log(response);

                if (response.status === 201) {
                    toast.current.show({ severity: "success", summary: 'Оплата', detail: 'Оплата пройшла успішно', life: 2000 });
                    setReloadData(!reloadData);
                    setVisible(false);
                }
                else if (response.status === 403) {
                    toast.current.show({ severity: "error", summary: "Помилка продажу", detail: "Користувач не має прав проводити оплату", life: 3000 });
                }
                else {
                    toast.current.show({ severity: "error", summary: "Помилка продажу", detail: "Помилка створення платежу у валюті, код помилки:" + response.status, life: 3000 });
                }
            }

            callBackEnd();
        }
    });

    useEffect(() => {
        let convertedAmount = 0;
  
        if (formik.values.payments.length !== 0) {
           formik.values.payments.forEach(payment => {
              convertedAmount += payment.sum * (payment.currency === "UAH" ? 1 : Cookies.get(`${payment.currency}Rate`));
           });
        }
        
        setUnDividedSum(totalSumUAH - convertedAmount);
  
    }, [formik.values.payments, totalSumUAH]);

    useEffect(() => {
        const fetchData = async () => {

            const response = await fetchReceptionActs(lazyState);

            if (response.status === 200) {

                setTotalRecords(response.data.count);
                setActs(response.data.results);

            }
            else {
                setActs([]);
                toast.current.show({ severity: "error", summary: "Помилка завантаження списку актів", detail: "Помилка сервера " + response.status, life: 3000 });
            }
            setLoading(false);
        }

        fetchData();

    }, [reloadData, lazyState, fetchReceptionActs]);

    const FinalizeActHandler = (rowData) => {

        const finalizeAct = async () => {

            const response = await finalizeReceptionAct(rowData.id);

            if (response.status !== 200) {
                toast.current.show({ severity: "error", summary: "Помилка фіналізації", detail: "Помилка сервера " + response.status, life: 3000 });
            }
            else {
                toast.current.show({ severity: 'success', summary: 'Фіналізація', detail: 'Акт фіналізовано', life: 3000 });
            }

            setReloadData(!reloadData);
        }

        finalizeAct();
    }

    const DeleteButtonHandler = rowData => {
        const deleteAct = async () => {

            const response = await deleteReceptionAct(rowData.id);

            if (response.status !== 204) {
                toast.current.show({ severity: "error", summary: "Помилка видалення", detail: "Помилка сервера " + response.status, life: 3000 });
            }
            else {
                toast.current.show({ severity: "success", summary: 'Видалення', detail: 'Акт успішно видалено', life: 3000 });
            }
            setReloadData(!reloadData);
        }

        deleteAct();
    }

    const PaymentHandler = (rowData) => {

        const getReceptionActDetails = async () => {
            const response = await fetchActUnpaidBalance(rowData.id);
            console.log(response.data);

            let amountUAH = 0;

            if (response.status === 200) {
                formik.setFieldValue("id", rowData.id);
                formik.setFieldValue("cagent_id", rowData.cagent);
                response.data.forEach(payment => {
                    amountUAH += payment.sum * (payment.currency === "UAH" ? 1 : Cookies.get(`${payment.currency}Rate`));
                    payment.paymentMethod = null;
                });
                formik.setFieldValue("payments", response.data);
                setTotalSumUAH(amountUAH);
                setVisible(true);
            }
            else {
                toast.current.show({ severity: "error", summary: 'Помилка отримання даних', detail: `Помилка отримання даних акту ${rowData.id}`, life: 3000 });
            }
        }

        getReceptionActDetails();
    }

    const handlePaymentsAmountChange = (index, newAmount) => {
        const newPayments = [...formik.values.payments];
        newPayments[index].sum = newAmount;
        formik.setFieldValue('payments', newPayments);
    };

    const handlePaymentsCurrencyChange = (index, newCurrency) => {
        const newPayments = [...formik.values.payments];
        newPayments[index].currency = newCurrency;
        formik.setFieldValue('payments', newPayments);
    };

    const handlePaymentMethodChange = (index, value) => {
        const newPayments = [...formik.values.payments];
        newPayments[index].paymentMethod = value;
        formik.setFieldValue('payments', newPayments);
    };

    const deletePayment = (index) => {
        console.log(index);
        const newPayments = [...formik.values.payments];
        newPayments.splice(index, 1);
        formik.setFieldValue('payments', newPayments);
    };

    const addPaymentToFormik = () => {
        const newPayment = [
           ...formik.values.payments,
           { currency: "UAH", sum: 0.00, paymentMethod: null }
        ];
        formik.setFieldValue('payments', newPayment);
    };

    const CreateNewSaleButtonHandler = () => {
        navigate("/productsreception/newreception");
    }

    const onPage = (event) => {
        event.page = event.page + 1;
        setlazyState(event);
    };

    const onSort = (event) => {
        event.page = lazyState.page;
        setlazyState(event);
    }

    const onFilter = (event) => {
        event.page = 1;
        setlazyState(event);
    }

    const cagentRowFilterTemplate = (options) => {
        return (
            <Dropdown
                value={options.value}
                options={Object.entries(ctx.partners).map(([id, name]) => ({ label: name, value: id }))}
                onChange={(e) => options.filterApplyCallback(e.value)}
                optionLabel="label"
                className="p-column-filter"
                style={{ minWidth: '14rem' }}
            />
        );
    };

    const divisionRowFilterTemplate = (options) => {
        return (
            <Dropdown
                value={options.value}
                options={Object.entries(ctx.divisions).map(([id, name]) => ({ label: name, value: id }))}
                onChange={(e) => options.filterApplyCallback(e.value)}
                optionLabel="label"
                className="p-column-filter"
                style={{ minWidth: '10rem' }}
            />
        );
    };

    const payStatusRowFilterTemplate = (options) => {
        return (
            <Dropdown
                value={options.value}
                options={Object.entries(ctx.pay_statuses).map(([id, name]) => ({ label: name, value: id }))}
                onChange={(e) => options.filterApplyCallback(e.value)}
                optionLabel="label"
                className="p-column-filter"
                style={{ minWidth: '10rem' }}
            />
        );
    };

    const confirmDelete = (rowData) => {
        confirmDialog({
            group: 'headless',
            header: 'Підтвердження',
            message: 'Підтвердіть видалення акту',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: "Так",
            rejectLabel: "Hi",
            defaultFocus: 'accept',
            accept: () => { DeleteButtonHandler(rowData) }
        });
    }

    const confirmFinalize = (event, rowData) => {
        confirmDialog({
            group: 'headless',
            header: 'Підтвердження',
            message: 'Після фіналізації акту, буде відсутня можливість його редагування',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: "Так",
            rejectLabel: "Hi",
            defaultFocus: 'accept',
            accept: () => { FinalizeActHandler(rowData) }
        });
    }

    const EditProductHandler = rowData => {
        const route = '/productsreception/editreception/' + rowData.id;
        navigate(route, { state: { id: rowData.id } });
    }

    /*const printPriceTag = rowData => {

        const getActDetails = async() => {
            const url = process.env.REACT_APP_BACKEND_URL + "receive_acts/get_details/" + rowData.id + "/";
            const options = {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json'
                }
            };

            const response = await authenticatedRequest(url, options);

            if (response.status === 200) {

                const parsedResponse = await response.json();

                const productsToPrint = [];

                if (parsedResponse.identified_products !== undefined && parsedResponse.identified_products.length > 0) {
                    parsedResponse.identified_products.forEach(product => {
                        const newProduct = {};
                        newProduct.remaining_id = product.id;
                        newProduct.accounting_type = "INDIVIDUAL";
                        productsToPrint.push(newProduct);
                    });
                }

                if (parsedResponse.unidentified_products !== undefined && parsedResponse.unidentified_products.length > 0) {
                    parsedResponse.unidentified_products.forEach(product => {
                        const newProduct = {};
                        newProduct.remaining_id = product.product_id;
                        newProduct.accounting_type = "PIECE";
                        productsToPrint.push(newProduct);
                    });
                }

                return productsToPrint;
            }
            else{
                toast.current.show({ severity: "error", summary: "Помилка завантаження деталей акту", detail: "Помилка сервера " + response.status, life: 3000 });
                return null;
            }
        }

        const printPriceTags = async() => {

            const productsToPrintPrice = await getActDetails();
            console.log(productsToPrintPrice);

            const url = process.env.REACT_APP_BACKEND_URL + "remainings/get_pdf_with_qr_code/";
            const options = {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(productsToPrintPrice)
            };

            const response = await authenticatedRequest(url, options);
            if(response.status === 200){
                console.log(response);
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);

                const link = document.createElement('a');
                link.href = url;
        
                // Set a filename
                link.download = `price_${rowData.id}.pdf`; // Change based on your needs
                document.body.appendChild(link);
                link.click();
        
                // Clean up
                link.remove();
                window.URL.revokeObjectURL(url);
            }
            else{
                toast.current.show({ severity: 'error', summary: 'Помилка завантаження', detail: 'Помилка завантадення цінника', life: 3000 });
            }
        }

        printPriceTags();
    } */

    const toolbarRightTemplate = () => {
        return (<div className="flex flex-wrap gap-2">
            <Button label="Додати акт прийому товару" severity="success" className={commonStyle.addButton} onClick={CreateNewSaleButtonHandler} />
        </div>)
    }

    const formatAdditionalButtons = (rowData) => {
        let buttons = [];
        if (rowData.status !== "FINALIZED") {
            buttons.push({
                label: 'Фіналізувати',
                icon: 'pi pi-lock',
                command: (event) => { confirmFinalize(event, rowData) }
            });
        }
        else {
            if (rowData.pay_status !== "PAYED") {
                buttons.push({
                    label: 'Оплатити',
                    icon: 'pi pi-money-bill',
                    command: () => { PaymentHandler(rowData) }
                });
            }
        }

        if (rowData.can_be_deleted) {
            buttons.push({
                label: 'Видалити',
                icon: 'pi pi-trash',
                command: () => { confirmDelete(rowData) }
            });
        }

        buttons = buttons.length > 0 ? buttons : null;
        return buttons
    };

    const actionBodyTemplate = (rowData) => {
        const buttons = formatAdditionalButtons(rowData);

        const buttonLabel = rowData.status !== "FINALIZED" ? "Редагувати" : "Деталі акту";
        const buttonIcon = rowData.status !== "FINALIZED" ? "pi pi-pencil" : "pi pi-eye";

        return (
            <>
                {buttons !== null ? (
                    <SplitButton
                        label={buttonLabel}
                        icon={buttonIcon}
                        onClick={() => EditProductHandler(rowData)}
                        model={buttons}
                        text
                        ref={buttonRef} // Set the ref here
                    />
                ) : (
                    <Button
                        label={buttonLabel}
                        icon={buttonIcon}
                        onClick={() => EditProductHandler(rowData)}
                        text
                    />
                )}
            </>
        );
    };

    return (<>
        <Toast ref={toast} />
        <Toolbar className="mb-4" end={toolbarRightTemplate}></Toolbar>
        <div className='grid'>
            <div className='col-12 justify-content-center'>
                <DataTable value={acts} stripedRows lazy rows={lazyState.rows} paginator onPage={onPage} onSort={onSort} sortField={lazyState.sortField} sortOrder={lazyState.sortOrder}
                    first={lazyState.first} loading={loading} totalRecords={totalRecords} rowsPerPageOptions={[10, 20, 50]}
                    filterDisplay="row" dataKey="id" onFilter={onFilter} filters={lazyState.filters} emptyMessage="Актів не знайдено" tableStyle={{ minWidth: '60rem' }} >
                    <Column field="act_number" header="Номер акту" sortable />
                    <Column field="act_date" dataType="date" header="Дата акту" sortable />
                    <Column field="cagent" header="Контрагент" sortable filter filterElement={cagentRowFilterTemplate} body={rowData => ctx.partners[rowData.cagent]} style={{ width: '13%' }} />
                    <Column field="division" header="Підрозділ" sortable filter filterElement={divisionRowFilterTemplate} body={rowData => ctx.divisions[rowData.division]} style={{ width: '10%' }} />
                    <Column field="amountUAH" header="Сума акту, грн" body={rowData => formatCurrency(rowData.amountUAH, "UAH")} />
                    <Column field="pay_status" header="Статус оплати" filter filterElement={payStatusRowFilterTemplate} body={rowData => ctx.pay_statuses[rowData.pay_status]} style={{ width: '10%' }} />
                    <Column field="pay_due_date" header="Дата оплати" />
                    <Column field="status" header="Статус" style={{ width: '4%' }} body={rowData => <ActStatusCircle rowData={rowData} />} sortable />
                    <Column field="created_by" header="Менеджер" sortable />
                    <Column field="created_at" header="Дата" body={(rowData) => formatDate(rowData.created_at)} sortable />
                    <Column field="action" body={actionBodyTemplate} />
                </DataTable>
            </div>
            <ConfirmDialog group="headless" />
        </div>
        <Sidebar className="responsive-sidebar" header="Оплата акту прийому" visible={visible} position="right" onHide={() => setVisible(false)}>
            <form onSubmit={formik.handleSubmit}>
                {formik.values.payments.map((currencyAmount, index) => (
                    <div className="grid py-3" key={index}>
                        <div className="col-10 p-inputgroup py-0 flex-1">
                            <span className={`${commonStyle.currencySpan} p-inputgroup-addon`}>
                                <Dropdown
                                    className={commonStyle.currencyDropDown}
                                    value={currencyAmount.currency}
                                    onChange={e => handlePaymentsCurrencyChange(index, e.value)}
                                    options={currencies.map(currency => ({ label: currency, value: currency }))}
                                />
                            </span>
                            <InputText
                                name={`currencyValuePair[${index}].amount`}
                                value={currencyAmount.sum}
                                onChange={e => handlePaymentsAmountChange(index, e.target.value)}
                            />
                        </div>
                        <div className="col-2">
                            <Button className="noStyleButton" icon="pi pi-trash" severity="danger" type='button' text onClick={() => deletePayment(index)} />
                        </div>
                        <div className="col-10 py-0">
                            <Dropdown
                                className={(formik.errors.payments && formik.errors.payments[index] && formik.touched.payments && formik.touched.payments[index]) ? `${commonStyle.fullWidth} p-invalid` : `${commonStyle.fullWidth}`}
                                value={currencyAmount.paymentMethod}
                                onChange={e => handlePaymentMethodChange(index, e.value)}
                                options={paymentMethods.map(method => ({ label: method, value: method }))}
                                placeholder="Спосіб оплати"
                            />
                            {formik.errors.payments && formik.errors.payments[index] && formik.touched.payments && formik.touched.payments[index] && (
                                <small className={commonStyle.errorSmall}>{formik.errors.payments[index].paymentMethod}</small>
                            )}
                        </div>
                    </div>
                ))}
                <div className='mb-2'>
                    <Message className={commonStyle.fullWidth} severity={unDividedSum > 0 ? "warn" : "success"} text={`Нерозподілена сума: ${unDividedSum} грн.`} />
                </div>
                <div>
                    <Button label="+ Додати валюту" severity="info" type="button" className={`${commonStyle.fullWidth} ${commonStyle.editButton}`} onClick={addPaymentToFormik} />
                    {formik.values.payments.length !== 0 && <Button label="Оплатити" severity="success" type="submit" className={`${commonStyle.fullWidth} ${commonStyle.addButton} mt-1`} />}
                </div>
            </form>
        </Sidebar>
    </>);
}

export default ProductsReception;