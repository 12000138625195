import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useState, useEffect, useRef } from "react";
import useApi from "../../../../Utils/BackendClient";
import { convertSumWithLatestCurrencyRate, formatCurrency, formatShortDate, systemCurrencies, systemPaymentMethods } from "../../../../Utils/Utils";
import { InputText } from "primereact/inputtext";
import { Chip } from "primereact/chip";
import { Button } from "primereact/button";

import commonStyle from "../../CommonStyles.module.css";
import { Sidebar } from "primereact/sidebar";
import { useFormik } from "formik";
import { Message } from "primereact/message";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { Toast } from "primereact/toast";

const Settlements = () => {

    const toast = useRef();
    const { createPayment, fetchSettlements } = useApi();

    const [expandedRows, setExpandedRows] = useState([])
    const [settlements, setSettlements] = useState([]);
    const [loading, setLoading] = useState(true);
    const [totalRecords, setTotalRecords] = useState(0);
    const [visible, setVisible] = useState(false);
    const [totalDebt, setTotalDebt] = useState(0);
    const [remainingDebt, setRemainingDebt] = useState(0);
    const [reloadData, setReloadData] = useState(true);
    const [isReceivable, setIsReceivable] = useState(false);
    const [tableFilters, setTableFilters] = useState({
        name: { value: "", matchMode: 'contains' },
    });
    const [lazyState, setlazyState] = useState({
        first: 0,
        rows: 10,
        page: 1,
        sortField: "name",
        sortOrder: 1,
        filters: {
            name: { value: "", matchMode: 'contains' }
        }
    });

    useEffect(() => {
        const fetchData = async () => {
            const response = await fetchSettlements(lazyState);
            if (response.status === 200) {
                console.log(response.data);
                setTotalRecords(response.data.count);
                setSettlements(response.data.results);
            }
            setLoading(false);
        };
        fetchData();
    }, [lazyState, fetchSettlements, reloadData]);

    const formik = useFormik({
        initialValues: {
            id: null,
            cagent_id: null,
            payments: [
                { currency: "UAH", sum: 0.00, paymentMethod: null }
            ]
        },
        validate: values => {
            const errors = {};

            values.payments.forEach((payment, index) => {
                if (payment.paymentMethod === null || payment.paymentMethod === '') {
                    if (!errors.payments) errors.payments = [];
                    errors.payments[index] = { paymentMethod: 'Вкажіть спосіб оплати' };
                }
            });

            return errors
        },
        onSubmit: async values => {

            let paymentRequest = [];
            values.payments.forEach(payment => {

                const singlePaymentRequest = {
                    date: formatShortDate(Date.now()),
                    sum: payment.sum,
                    currency: payment.currency,
                    type: payment.paymentMethod,
                    method: isReceivable ? "INCOME" : "OUTCOME",
                    cagent_id: values.cagent_id != null ? values.cagent_id : null,
                    distributed_cagent_id: values.cagent_id,
                }

                paymentRequest.push(singlePaymentRequest);
            });

            if(paymentRequest.length > 0){

                const response = await createPayment(paymentRequest);

                if (response.status === 201) {
                    toast.current.show({severity: "success", summary: 'Оплата', detail: 'Оплата пройшла успішно', life: 2000});
                    onHide();
                    formik.resetForm();
                    setReloadData(!reloadData);
                }
                else if (response.status === 403) {
                    toast.current.show({ severity: "error", summary: "Помилка продажу", detail: "Користувач не має прав проводити оплату", life: 3000 });
                }
                else {
                    toast.current.show({ severity: "error", summary: "Помилка продажу", detail: "Помилка створення платежу у валюті, код помилки:" + response.status, life: 3000 });
                }
            }

        }
    });

    useEffect(() => {
        let totalPayments = 0;

        if (formik.values.payments.length !== 0) {
            formik.values.payments.forEach(payment => {
                totalPayments += convertSumWithLatestCurrencyRate(payment.currency, payment.sum);
            });
        }

        setRemainingDebt(totalDebt - totalPayments);

    }, [formik.values.payments, totalDebt]);

    const addPaymentToFormik = () => {
        const newPayment = [
           ...formik.values.payments,
           { currency: "UAH", sum: 0.00, paymentMethod: null }
        ];
        formik.setFieldValue('payments', newPayment);
    };

    const handlePaymentsAmountChange = (index, newAmount) => {
        const newPayments = [...formik.values.payments];
        newPayments[index].sum = newAmount;
        formik.setFieldValue('payments', newPayments);
    };

    const handlePaymentsCurrencyChange = (index, newCurrency) => {
        const newPayments = [...formik.values.payments];
        newPayments[index].currency = newCurrency;
        formik.setFieldValue('payments', newPayments);
    };

    const handlePaymentMethodChange = (index, value) => {
        const newPayments = [...formik.values.payments];
        newPayments[index].paymentMethod = value;
        formik.setFieldValue('payments', newPayments);
    };

    const deletePayment = (index) => {
        console.log(index);
        const newPayments = [...formik.values.payments];
        newPayments.splice(index, 1);
        formik.setFieldValue('payments', newPayments);
    };

    const onPage = (event) => {
        event.page = event.page + 1;
        setlazyState(event);
    };

    const onSort = (event) => {
        event.page = lazyState.page;
        setlazyState(event);
    }

    const onFilter = (event) => {
        event.page = 1;
        setTableFilters(event.filters);
    }

    const filterApply = () => {
        setlazyState(prevState => ({
            ...prevState,
            filters: tableFilters
        }))
    }

    const clearFilter = (paramName, value) => {
        setlazyState(prevState => ({
            ...prevState,
            filters: {
                ...prevState.filters,
                [paramName]: {
                    ...prevState.filters[paramName],
                    value: value
                }
            }
        }));
    }

    const clearFilterFromChips = (paramName, value) => {
        clearFilter(paramName, value);

        setTableFilters(prevState => ({
            ...prevState,
            [paramName]: {
                value: value
            }
        }));
    }

    const formatFilterChips = () => {
        const element = [];

        Object.keys(lazyState.filters).forEach((filterKey) => {
            const filter = lazyState.filters[filterKey];

            if (filter.value && filter.value !== "") {

                let translatedKey = filterKey;
                let changedValue = filter.value;
                let removeValue = null;


                if (filterKey === "name") {
                    translatedKey = "Назва контрагента";
                    removeValue = "";
                }

                element.push(
                    <Chip
                        key={filterKey}
                        label={`${translatedKey}: ${changedValue}`}
                        removable
                        onRemove={() => clearFilterFromChips(filterKey, removeValue)}
                    />
                );
            }
        });

        return <div className="flex flex-wrap gap-2">{element}</div>;
    }

    const onHide = () => {
        setVisible(false);
    }

    const tableHeader = (
        <div className="flex justify-content-between align-items-center w-full">
            {formatFilterChips()}
        </div>
    );

    const expandedRowTemplate = (rowData) => {
        const data = [];
        systemCurrencies.forEach(currency => {
            let currencyData = {};
            currencyData.currency = currency;
            currencyData.receivable = formatCurrency(rowData.receivable_by_currency.find(x => x.currency === currency)?.sum ?? 0, currency);
            currencyData.overdue_receivable = formatCurrency(rowData.overdue_receivable_by_currency.find(x => x.currency === currency)?.sum ?? 0, currency);
            currencyData.payable = formatCurrency(rowData.payable_by_currency.find(x => x.currency === currency)?.sum ?? 0, currency);
            currencyData.overdue_payable = formatCurrency(rowData.overdue_payable_by_currency.find(x => x.currency === currency)?.sum ?? 0, currency);

            data.push(currencyData);
        });

        return (
            <DataTable value={data}>
                <Column style={{ width: '5%' }} headerStyle={{ display: 'none' }}/>
                <Column field="currency" style={{ width: '15%' }} headerStyle={{ display: 'none' }}/>
                <Column field="receivable" header="Дебіторська заборгованість" body={(data) => <span style={{ color: "#03C04A"}}>{data.receivable}</span>} 
                        style={{ width: '20%' }} headerStyle={{ display: 'none' }}/>
                <Column field="overdue_receivable" header="(в тому числі прострочена)" body={(data) => <span style={{ color: "#03C04A"}}>{data.overdue_receivable}</span>} 
                        style={{ width: '20%' }} headerStyle={{ display: 'none' }}/>
                <Column field="payable_UAH" header="Кредиторська заборгованість" body={(data) => <span style={{ color: "#EF4444"}}>{data.payable}</span>} 
                        style={{ width: '20%' }} headerStyle={{ display: 'none' }}/>
                <Column field="payable_UAH" header="(в тому числі прострочена)" body={(data) => <span style={{ color: "#EF4444"}}>{data.overdue_payable}</span>} 
                        style={{ width: '20%' }} headerStyle={{ display: 'none' }}/>
            </DataTable>
        );
    };

    const inputTemplate = (options) => {
        return (
            <InputText
                value={options.value}
                onChange={
                    (e) => options.filterApplyCallback(e.target.value)
                }
            />
        );
    }

    const actionBodyTemplate = (rowData) => {
        return (<div className="flex flex-wrap gap-2">
            <Button
                icon="pi pi-sign-in"
                className={`p-button-rounded p-button-success ${rowData.receivable_UAH === 0 ? commonStyle.closeButton : commonStyle.addButton}`}
                tooltip={"Оплатити дебіторську заборгованість"}
                disabled={rowData.receivable_UAH === 0}
                tooltipOptions={{ showOnDisabled: true, position: "left" }}
                onClick={() => {
                    formik.setFieldValue("cagent_id", rowData.id);
                    setIsReceivable(true);
                    setTotalDebt(rowData.receivable_UAH);
                    setVisible(true);
                }}
            />
            <Button
                icon="pi pi-sign-out"
                className={`p-button-rounded p-button-error ${rowData.payable_UAH === 0 ? commonStyle.closeButton : commonStyle.deleteButton}`}
                tooltip={"Оплатити кредиторську заборгованість"}
                disabled={rowData.payable_UAH === 0}
                tooltipOptions={{ showOnDisabled: true, position: "left" }}
                onClick={() => {
                    formik.setFieldValue("cagent_id", rowData.id);
                    setIsReceivable(false);
                    setTotalDebt(rowData.payable_UAH);
                    setVisible(true);
                }} />
        </div>
        );
    };

    return (<>
        <Toast ref={toast} />
        <div className="grid mt-3">
            <div className="col-12 justify-content-center">
                <DataTable
                    value={settlements}
                    header={tableHeader}
                    lazy
                    rows={lazyState.rows}
                    paginator
                    onPage={onPage}
                    first={lazyState.first}
                    loading={loading}
                    totalRecords={totalRecords}
                    onSort={onSort}
                    sortField={lazyState.sortField}
                    sortOrder={lazyState.sortOrder}
                    onFilter={onFilter}
                    filters={tableFilters}
                    onRowToggle={(e) => setExpandedRows(e.data)}
                    expandedRows={expandedRows}
                    rowExpansionTemplate={expandedRowTemplate} // Add this for expandable rows
                    rowsPerPageOptions={[10, 20, 50]}
                >
                    <Column expander style={{ width: '5%' }} />
                    <Column field="name" header="Назва" sortable filter filterElement={inputTemplate} onFilterApplyClick={filterApply} onFilterClear={() => clearFilter("name", "")} showFilterMatchModes={false} style={{ width: '15%' }} />
                    <Column field="receivable_UAH" header="Дебіторська заборгованість" sortable body={(rowData) => <span style={{ color: "#03C04A" }}>{formatCurrency(rowData.receivable_UAH, "UAH")}</span>} style={{ width: '20%' }} />
                    <Column field="overdue_receivable_UAH" header="(в тому числі прострочена)" sortable body={(rowData) => <span style={{ color: "#03C04A" }}>{formatCurrency(rowData.overdue_receivable_UAH, "UAH")}</span>} style={{ width: '15%' }} />
                    <Column field="payable_UAH" header="Кредиторська заборгованість" sortable body={(rowData) => <span style={{ color: "#EF4444" }}>{formatCurrency(rowData.payable_UAH, "UAH")}</span>} style={{ width: '20%' }} />
                    <Column field="oerdue_payable_UAH" header="(в тому числі прострочена)" sortable body={(rowData) => <span style={{ color: "#EF4444" }}>{formatCurrency(rowData.oerdue_payable_UAH, "UAH")}</span>} style={{ width: '15%' }} />
                    <Column field="action" body={(rowData) => actionBodyTemplate(rowData)} />
                </DataTable>
                <Sidebar className="responsive-sidebar" header="Оплата кредиторської заборгованості" visible={visible} position="right" onHide={onHide}>
                    <form onSubmit={formik.handleSubmit}>
                        {formik.values.payments.length === 0 && (
                            <div className="mb-2">
                                <Message className='sideBarMessage'
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        textAlign: 'center',
                                        width: '100%',
                                    }}
                                    severity="error"
                                    text="Додайте валюту і спосіб оплати"
                                />
                            </div>
                        )}
                        {formik.values.payments.map((currencyAmount, index) => (
                            <div className="grid py-3" key={index}>
                                <div className="col-10 p-inputgroup py-0 flex-1">
                                    <span className={`${commonStyle.currencySpan} p-inputgroup-addon`}>
                                        <Dropdown
                                            className={commonStyle.currencyDropDown}
                                            value={currencyAmount.currency}
                                            onChange={e => handlePaymentsCurrencyChange(index, e.value)}
                                            options={systemCurrencies.map(currency => ({ label: currency, value: currency }))}
                                        />
                                    </span>
                                    <InputNumber
                                        name={`currencyValuePair[${index}].amount`}
                                        minFractionDigits={2}
                                        value={currencyAmount.sum}
                                        onChange={e => handlePaymentsAmountChange(index, e.value)}
                                    />
                                </div>
                                <div className="col-2">
                                    <Button className="noStyleButton" icon="pi pi-trash" severity="danger" type='button' text onClick={() => deletePayment(index)} />
                                </div>
                                <div className="col-10 py-0">
                                    <Dropdown
                                        className={formik.errors.payments && formik.errors.payments[index] && formik.touched.payments && formik.touched.payments[index] ? `${commonStyle.fullWidth} p-invalid` : `${commonStyle.fullWidth}`}
                                        value={currencyAmount.paymentMethod}
                                        onChange={e => handlePaymentMethodChange(index, e.value)}
                                        options={Object.entries(systemPaymentMethods).map(([label, value]) => ({ label, value }))}
                                        placeholder="Спосіб оплати"
                                    />
                                    {formik.errors.payments && formik.errors.payments[index] && formik.touched.payments && formik.touched.payments[index] && (
                                        <small className={commonStyle.errorSmall}>{formik.errors.payments[index].paymentMethod}</small>
                                    )}
                                </div>
                            </div>
                        ))}
                        <div className='mb-2'>
                            <Message
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    textAlign: 'center',
                                    width: '100%',
                                }}
                                severity={`${remainingDebt <= 0 ? "success" : "error"}`}
                                text={`Неоплачений борг: ${formatCurrency(remainingDebt, "UAH")}`}
                            />
                        </div>
                        <div>
                            <Button label="+ Додати валюту" severity="info" type="button" className={`${commonStyle.fullWidth} ${commonStyle.editButton}`} onClick={addPaymentToFormik} />
                            {formik.values.payments.length !== 0 && <Button label="Оплатити" severity="success" type="submit" className={`${commonStyle.fullWidth} ${commonStyle.addButton} mt-1`} />}
                        </div>
                    </form>
                </Sidebar>
            </div>
        </div>
    </>
    );
};

export default Settlements;