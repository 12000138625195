// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PageBar_breadcrumb__\\+s9TQ {
    border: none;
    background: #f9fafb;
}

.PageBar_borderless__UiW4u {
    border: none;
}

.PageBar_userPicture__YTgj4{
    height: 5rem;
    width: 5rem;
    border-radius: 50%;
}

.PageBar_userBlock__GXwn3 div{
    display: inline-flex;
    vertical-align: middle;
}

.PageBar_userBlock__GXwn3 div div{
    display: block;
    margin-left: 6px;
    margin-right: 6px;
    text-align: center;
    padding-top: 5px;
}

.PageBar_form-control__09Bea:focus {
    box-shadow: none;
    border-color: #BA68C8
}

.PageBar_profile-button__W3B4u {
    background: rgb(99, 39, 120);
    box-shadow: none;
    border: none
}

.PageBar_profile-button__W3B4u:hover {
    background: #682773
}

.PageBar_profile-button__W3B4u:focus {
    background: #682773;
    box-shadow: none
}

.PageBar_profile-button__W3B4u:active {
    background: #682773;
    box-shadow: none
}

.PageBar_back__3f-Hj:hover {
    color: #682773;
    cursor: pointer
}

.PageBar_labels__Z5o8Z {
    font-size: 11px
}

.PageBar_add-experience__MOBWi:hover {
    background: #BA68C8;
    color: #fff;
    cursor: pointer;
    border: solid 1px #BA68C8
}

.PageBar_flex-container__CRA69 {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.PageBar_flex-item__6K6p3 {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/components/content/PageBar.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,kBAAkB;AACtB;;AAEA;IACI,oBAAoB;IACpB,sBAAsB;AAC1B;;AAEA;IACI,cAAc;IACd,gBAAgB;IAChB,iBAAiB;IACjB,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB;AACJ;;AAEA;IACI,4BAA4B;IAC5B,gBAAgB;IAChB;AACJ;;AAEA;IACI;AACJ;;AAEA;IACI,mBAAmB;IACnB;AACJ;;AAEA;IACI,mBAAmB;IACnB;AACJ;;AAEA;IACI,cAAc;IACd;AACJ;;AAEA;IACI;AACJ;;AAEA;IACI,mBAAmB;IACnB,WAAW;IACX,eAAe;IACf;AACJ;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,iBAAiB;AACrB","sourcesContent":[".breadcrumb {\n    border: none;\n    background: #f9fafb;\n}\n\n.borderless {\n    border: none;\n}\n\n.userPicture{\n    height: 5rem;\n    width: 5rem;\n    border-radius: 50%;\n}\n\n.userBlock div{\n    display: inline-flex;\n    vertical-align: middle;\n}\n\n.userBlock div div{\n    display: block;\n    margin-left: 6px;\n    margin-right: 6px;\n    text-align: center;\n    padding-top: 5px;\n}\n\n.form-control:focus {\n    box-shadow: none;\n    border-color: #BA68C8\n}\n\n.profile-button {\n    background: rgb(99, 39, 120);\n    box-shadow: none;\n    border: none\n}\n\n.profile-button:hover {\n    background: #682773\n}\n\n.profile-button:focus {\n    background: #682773;\n    box-shadow: none\n}\n\n.profile-button:active {\n    background: #682773;\n    box-shadow: none\n}\n\n.back:hover {\n    color: #682773;\n    cursor: pointer\n}\n\n.labels {\n    font-size: 11px\n}\n\n.add-experience:hover {\n    background: #BA68C8;\n    color: #fff;\n    cursor: pointer;\n    border: solid 1px #BA68C8\n}\n\n.flex-container {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n}\n\n.flex-item {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    margin-left: 1rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"breadcrumb": `PageBar_breadcrumb__+s9TQ`,
	"borderless": `PageBar_borderless__UiW4u`,
	"userPicture": `PageBar_userPicture__YTgj4`,
	"userBlock": `PageBar_userBlock__GXwn3`,
	"form-control": `PageBar_form-control__09Bea`,
	"profile-button": `PageBar_profile-button__W3B4u`,
	"back": `PageBar_back__3f-Hj`,
	"labels": `PageBar_labels__Z5o8Z`,
	"add-experience": `PageBar_add-experience__MOBWi`,
	"flex-container": `PageBar_flex-container__CRA69`,
	"flex-item": `PageBar_flex-item__6K6p3`
};
export default ___CSS_LOADER_EXPORT___;
