import { Card } from "primereact/card";
import { useContext, useEffect, useRef, useState } from "react";
import useApi from "../../../../Utils/BackendClient";
import { UserPermissionContext } from "../../../../context/UserPermissionProvider";

import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";


const DashBoards = () => {

    const { fetchCostsWidget, fetchProductCountWidget, fetchRevenueWidget, fetchServicesGivenWidget } = useApi();
    const permCtx = useContext(UserPermissionContext);
    const toast = useRef(null);

    const [expenses, setExpenses] = useState();
    const [expensesResult, setExpensesResult] = useState();
    const [expensesLoaded, setExpensesLoaded] = useState(false);
    const [productsSold, setProductsSold] = useState();
    const [productsResult, setProductsResult] = useState();
    const [productsSoldLoaded, setProductsSoldLoaded] = useState(false);
    const [servicesSold, setServicesSold] = useState();
    const [servicesResult, setServicesResult] = useState();
    const [servicesSoldLoaded, setServicesSoldLoaded] = useState(false);
    const [revenue, setRevenue] = useState();
    const [revenueResult, setRevenueResult] = useState();
    const [revenueLoaded, setRevenueLoaded] = useState(false);

    const calculateResult = (widgetData) => {

        let result = 0;

        if(widgetData !== null){
            if (widgetData.previous_value !== 0) {
                result = Math.round((widgetData.current_value / widgetData.previous_value) * 100 - 100);
            }
            else {
                if (widgetData.current_value !== 0) {
                    result = 100;
                }
            }
        }

        const style = result === 0 ? "" : result > 0 ? "text-green-500" : "text-red-500";

        return {
            result: result,
            style: style
        }
    }

    useEffect(() => {

        const fetchData = async () => {

            const response = await fetchCostsWidget();
            if(response.status === 200)
            {
                setExpenses(response.data);
                setExpensesResult(calculateResult(response.data));
                setExpensesLoaded(true);
            }
            else{
                let errorMessage = "";
                if(response.status === 403){
                    errorMessage = "Користувач не має доступу до цього віджету"
                }
                else{
                    errorMessage = "Помилка завантаження віджету" + response.status;
                }

                setExpenses([]);
                setExpensesResult(calculateResult(null));
                toast.current.show({ severity: "error", summary: "Помилка віджету витрат", detail: errorMessage, life: 3000 });
            }
        };

        fetchData();
    }, [fetchCostsWidget]);

    useEffect(() => {

        const fetchData = async () => {

            const response = await fetchProductCountWidget();
            if(response.status === 200){
                setProductsSold(response.data);
                setProductsResult(calculateResult(response.data))
                setProductsSoldLoaded(true);
            }
            else
            {
                let errorMessage = "";
                if(response.status === 403){
                    errorMessage = "Користувач не має доступу до цього віджету"
                }
                else{
                    errorMessage = "Помилка завантаження віджету" + response.status;
                }

                setProductsSold([]);
                setProductsResult(calculateResult(null));
                toast.current.show({ severity: "error", summary: "Помилка віджету кількості проданих товарів", detail: errorMessage, life: 3000 });
            }
        };

        fetchData();
    }, [fetchProductCountWidget]);

    useEffect(() => {

        const fetchData = async () => {

            const response = await fetchServicesGivenWidget();
            if(response.status === 200){
                setServicesSold(response.data);
                setServicesResult(calculateResult(response.data));
                setServicesSoldLoaded(true);
            }
            else{
                let errorMessage = "";
                if(response.status === 403){
                    errorMessage = "Користувач не має доступу до цього віджету"
                }
                else{
                    errorMessage = "Помилка завантаження віджету" + response.status;
                }

                setServicesSold([]);
                setServicesResult(calculateResult(null));
                toast.current.show({ severity: "error", summary: "Помилка віджету кількості наданих послу", detail: errorMessage, life: 3000 });
            }
        };

        fetchData();
    }, [fetchServicesGivenWidget]);

    useEffect(() => {

        const fetchData = async () => {

            const response = await fetchRevenueWidget();
            if(response.status === 200){
                setRevenue(response.data);
                setRevenueResult(calculateResult(response.data));
                setRevenueLoaded(true);
            }
            else{
                let errorMessage = "";
                if(response.status === 403){
                    errorMessage = "Користувач не має доступу до цього віджету"
                }
                else{
                    errorMessage = "Помилка завантаження віджету" + response.status;
                }
                setRevenue([]);
                setRevenueResult(calculateResult(null));
                toast.current.show({ severity: "error", summary: "Помилка віджету доходу", detail: errorMessage, life: 3000 });
            }
        };

        fetchData();
    }, [fetchRevenueWidget]);

    return (<>
    <Toast ref={toast}/>
    {permCtx.permissions?.includes("dashboards.view_dashboard") && <div className="grid">
        <div className="col-12 lg:col-6 xl:col-3">
            <Card className="mb-0">
                {!expensesLoaded && <div className="card flex justify-content-center">
                    <ProgressSpinner />
                </div>}
                {expensesLoaded && (<><div className="flex justify-content-between mb-3">
                    <div>
                        <span className="block text-500 font-medium mb-3">{expenses?.name}</span>
                        <div className="text-900 font-medium text-xl">{expenses?.current_value.toLocaleString("ua-UA", { style: 'currency', currency: "UAH" })}</div>
                    </div>
                    <div className="flex align-items-center justify-content-center bg-blue-100 border-round" style={{ width: "3rem", height: "3rem" }}>
                        <i className="pi pi-shopping-cart text-blue-500 text-xl" />
                    </div>
                </div>
                    <span className={`${expensesResult?.style} font-medium`}>{expensesResult?.result}% </span>
                    <span className="text-500">в період з {expenses?.start_date} до {expenses?.end_date}</span>
                </>)}
            </Card>
        </div>
        <div className="col-12 lg:col-6 xl:col-3">
            <Card className="mb-0">
                {!productsSoldLoaded && <div className="card flex justify-content-center">
                    <ProgressSpinner />
                </div>}
                {productsSoldLoaded && (<><div className="flex justify-content-between mb-3">
                    <div>
                        <span className="block text-500 font-medium mb-3">{productsSold?.name}</span>
                        <div className="text-900 font-medium text-xl">{productsSold?.current_value}</div>
                    </div>
                    <div className="flex align-items-center justify-content-center bg-orange-100 border-round" style={{ width: "3rem", height: "3rem" }}>
                        <i className="pi pi-phone text-orange-500 text-xl" />
                    </div>
                </div>
                    <span className={`${productsResult?.style} font-medium`}>{productsResult?.result}% </span>
                    <span className="text-500">в період з {productsSold?.start_date} до {productsSold?.end_date}</span>
                </>)}
            </Card>
        </div>
        <div className="col-12 lg:col-6 xl:col-3">
            <Card className="mb-0">
                {!servicesSoldLoaded && <div className="card flex justify-content-center">
                    <ProgressSpinner />
                </div>}
                {servicesSoldLoaded && (<><div className="flex justify-content-between mb-3">
                    <div>
                        <span className="block text-500 font-medium mb-3">{servicesSold?.name}</span>
                        <div className="text-900 font-medium text-xl">{servicesSold?.current_value}</div>
                    </div>
                    <div className="flex align-items-center justify-content-center bg-cyan-100 border-round" style={{ width: "3rem", height: "3rem" }}>
                        <i className="pi pi-wrench text-cyan-500 text-xl" />
                    </div>
                </div>
                    <span className={`${servicesResult?.style} font-medium`}>{servicesResult?.result}% </span>
                    <span className="text-500">в період з {servicesSold?.start_date} до {servicesSold?.end_date}</span>
                </>)}
            </Card>
        </div>
        <div className="col-12 lg:col-6 xl:col-3">
            <Card className="mb-0">
                {!revenueLoaded && <div className="card flex justify-content-center">
                    <ProgressSpinner />
                </div>}
                {revenueLoaded && (<>
                <div className="flex justify-content-between mb-3">
                    <div>
                        <span className="block text-500 font-medium mb-3">{revenue?.name}</span>
                        <div className="text-900 font-medium text-xl">{revenue?.current_value.toLocaleString("ua-UA", { style: 'currency', currency: "UAH" })}</div>
                    </div>
                    <div className="flex align-items-center justify-content-center bg-green-100 border-round" style={{ width: "3rem", height: "3rem" }}>
                        <i className="pi pi-money-bill text-green-500 text-xl" />
                    </div>
                </div>
                    <span className={`${revenueResult?.style} font-medium`}>{revenueResult?.result}% </span>
                    <span className="text-500">в період з {revenue?.start_date} до {revenue?.end_date}</span>
                </>)}
            </Card>
        </div>
    </div>}
    </>)
}

export default DashBoards;