import useAuthenticatedRequest from "../../../../Utils/TokenrRefreshUtils";
import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Translate } from "../../../../Utils/TranslationUtils";

import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Divider } from "primereact/divider";
import { FloatLabel } from "primereact/floatlabel";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";

import commonStyle from "../../CommonStyles.module.css";

const NewUserRole = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const toast = useRef(null);
    const authenticatedRequest = useAuthenticatedRequest();
    
    const [isEdit, setIsEdit] = useState(false);
    const [permissions, setPermissions] = useState([]);
    const [roleName, setRoleName] = useState("");

    useEffect(() => {
        const getRolePernissions = async(id) => {
            let url = process.env.REACT_APP_BACKEND_URL + "roles/get_details/" + id + "/";
            const options = {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json'
                }
            };
            const response = await authenticatedRequest(url, options);
            if(response.status === 200)
            {
                const parsedResponse = await response.json();
                setRoleName(parsedResponse.name);
                if(parsedResponse != null && (parsedResponse.permissions != null && parsedResponse.permissions.length > 0)){
                    return parsedResponse.permissions;
                }
                else{
                    return [];
                }
            }
            else{
                toast.current.show({ severity: "error", summary: "Помилка отримання пермісій", detail: "Пермісії для ролі не було отримано", life: 2000 })
                return [];
            }
        }

        const fetchData = async () => {
            let url = process.env.REACT_APP_BACKEND_URL + "enums/permissions/";
            const options = {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json'
                }
            };

            const response = await authenticatedRequest(url, options);
            const parsedResponse = await response.json();

            if(location.state != null){
                setIsEdit(true);
                const appliedPermissions = await getRolePernissions(location.state.id);

                if(appliedPermissions.length > 0){
                    parsedResponse.forEach(permission => {
                        if(appliedPermissions.some(item => item.codename === permission.codename)){
                            permission.allowed = true;
                        }
                    }); 
                }
                else{
                    parsedResponse.forEach(permission => {
                        permission.allowed = false;
                    });
                }
            }
            else{
                parsedResponse.forEach(permission => {
                    permission.allowed = false;
                });
            }

            setPermissions(parsedResponse);
        }

        fetchData();
    }, [authenticatedRequest, location]);

    const formik = useFormik({
        initialValues: {
            name: roleName,
            permissions: permissions
        },
        enableReinitialize: true,
        validate: values => {
            const errors = [];
            if (values.name === null || values.name === "") {
                errors.name = "Введіть назву ролі";
            }
            return errors;
        },
        onSubmit: values => {
            const requestBody = {
                name: values.name,
                permissions: []
            };
            values.permissions.forEach(permission => {
                const allowedPermission = {};
                if(permission.allowed){
                    allowedPermission.codename = permission.codename;
                    requestBody.permissions.push(allowedPermission);
                }
            });

            const createOrEditRole = async() => {
                const url = isEdit ? process.env.REACT_APP_BACKEND_URL + "roles/update/" + location.state.id + "/" : process.env.REACT_APP_BACKEND_URL + "roles/create/";
                const options = {
                    method: isEdit ? "PUT" : "POST",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(requestBody)
                };

                const response = await authenticatedRequest(url, options);

                const successCode = isEdit ? 200 : 201;

                if(response.status === successCode)
                {
                    const parsedResponse = await response.json();
                    const toastMessage = { severity: "success", summary: isEdit ? "Успіх редагування" : "Успіх створення", detail: "Роль " + parsedResponse.name + (isEdit ? " успішно відредаговано" : " успішно створено"), life: 3000 }
                    navigate('/roles', { state: { toast: toastMessage } });
                }
                else{
                    toast.current.show({ severity: "error", summary: isEdit ? "Помилка редагування" : "Помилка створення", detail: isEdit ? "Роль не відредаговано" : "Роль не було створено", life: 2000 })
                }
            }

            createOrEditRole();
        }
    });

    const ReturnButtonHandler = () => {
        navigate("/roles");
    }

    const mainToolbarLeftTemplate = () => {
        return (<h3>Створення нової ролі користувача</h3>);
    }

    const mainToolbarRightTemplate = () => {
        return (
            <div className="flex flex-wrap gap-2">
                <Button label="Зберегти" className={commonStyle.addButton} severity="success" type="submit" />
                <Button label="Вийти" className={commonStyle.deleteButton} severity="secondary" type="button" onClick={ReturnButtonHandler} />
            </div>
        );
    }

    const groupByApp = (permissions) => {
        return permissions.reduce((acc, permission) => {
            const { app } = permission;
            if (!acc[app]) {
                acc[app] = [];
            }
            acc[app].push(permission);
            return acc;
        }, {});
    };

    const PermissionsList = ({ permissions }) => {
        const groupedPermissions = groupByApp(permissions);

        const handleRadioChange = (permissionId) => {
            const updatedPermissions = permissions.map(permission => {
                if (permission.id === permissionId) {
                    return { ...permission, allowed: !permission.allowed }; // Toggle allowed state
                }
                return permission;
            });

            // Update Formik's state
            formik.setFieldValue('permissions', updatedPermissions);
        };

        const handleGroupRadioChange = (app) => {
            const isChecked = isGroupChecked(app);
            permissions.forEach(element => {
                if(element.app === app){
                    element.allowed = !isChecked;
                }
            });

            formik.setFieldValue("permissions", permissions);
        }

        const isGroupChecked = (app) => {
            let isChecked = true;
            permissions.forEach(element => {
                if(element.app === app){
                    if(!element.allowed){
                        isChecked = false;
                    }
                }
            });

            return isChecked;
        }

        return (
            <div>
                {Object.keys(groupedPermissions).map(app => (<>
                    <div key={"group" + app} className="flex flex-row align-items-center">
                        <Checkbox 
                        className="mr-2" 
                        checked={isGroupChecked(app)}
                        onChange={() => handleGroupRadioChange(app)} />
                        <h3>{Translate(app)}</h3>
                    </div>
                    <Divider className="mt-0"/>
                    <div key={app} className="grid mb-4">
                        {groupedPermissions[app].map(permission => (
                            <div className="col-4 flex align-items-center" key={permission.id}>
                                <Checkbox
                                    checked={permission.allowed}
                                    onChange={() => handleRadioChange(permission.id)}
                                />
                                <label className="ml-2">{permission.name}</label>
                            </div>
                        ))}
                    </div>
                    </>))}
            </div>
        );
    };

    return (
        <form onSubmit={formik.handleSubmit}>
            <Toast ref={toast} />
            <Toolbar className="mb-4" start={mainToolbarLeftTemplate} end={mainToolbarRightTemplate} />
            <div className="grid nested-grid">
                <div className={`${commonStyle.leftMenu} col-3`}>
                    <div className={commonStyle.formInput}>
                        <div className="p-inputgroup flex-column">
                            <FloatLabel>
                                <InputText
                                    name="name"
                                    value={formik.values.name}
                                    onChange={(e) => formik.setFieldValue('name', e.target.value)}
                                    className={formik.errors.name && formik.touched.name ? 'p-invalid' : ''}
                                />
                                <label>Назва ролі</label>
                            </FloatLabel>
                            {formik.errors.name && formik.touched.name && (<small className={commonStyle.errorSmall}>{formik.errors.name}</small>)}
                        </div>
                    </div>
                </div>
                <div className="col-9">
                    <PermissionsList permissions={formik.values.permissions} />
                </div>
            </div>
        </form>
    );
}

export default NewUserRole;