// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Add a border to all tree node elements */
.p-tree {
    background: none;
    border: 0;
}

.p-tree .p-tree-container .p-treenode {
    padding: 0;
    border: 1px solid #e5e7eb;
    color: #6b7280;
    background: #f9fafb;
    border-radius: 6px;
    margin-bottom: 0.1rem;
}`, "",{"version":3,"sources":["webpack://./src/components/content/PageContent/ProductsAndServices/AdditionalStyles.css"],"names":[],"mappings":"AAAA,2CAA2C;AAC3C;IACI,gBAAgB;IAChB,SAAS;AACb;;AAEA;IACI,UAAU;IACV,yBAAyB;IACzB,cAAc;IACd,mBAAmB;IACnB,kBAAkB;IAClB,qBAAqB;AACzB","sourcesContent":["/* Add a border to all tree node elements */\n.p-tree {\n    background: none;\n    border: 0;\n}\n\n.p-tree .p-tree-container .p-treenode {\n    padding: 0;\n    border: 1px solid #e5e7eb;\n    color: #6b7280;\n    background: #f9fafb;\n    border-radius: 6px;\n    margin-bottom: 0.1rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
