import { createContext, useState } from "react";
import useApi from "../Utils/BackendClient";

const OrgInfoContext = createContext();

const OrgInfoProvider = ({ children }) => {
    const [orgLogo, setOrgLogo] = useState(null);
    const { fetchOrganizationDetails } = useApi();
    
    const loadOrgData = async () => {
        
        const response = await fetchOrganizationDetails();

        if (response.status === 200) {
            setOrgLogo(response.data.logo_url);
        }
    };

    const cleanOrgInfo = () =>{
        setOrgLogo(null);
    }

    const value = {
        orgLogo,
        loadOrgData,
        cleanOrgInfo
    };

    return (
        <OrgInfoContext.Provider value={value}>
            {children}
        </OrgInfoContext.Provider>
    );
}

export { OrgInfoProvider, OrgInfoContext };