// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html {
    font-size: 14px;
}

body {
font-family: var(--font-family);
font-weight: normal;
background: var(--surface-ground);
color: var(--text-color);
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
}

.NavigationBar_card__hQqYq {
background: var(--surface-card);
padding: 2rem;
border-radius: 10px;
margin-bottom: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/components/Navigation/NavigationBar.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;;AAEA;AACA,+BAA+B;AAC/B,mBAAmB;AACnB,iCAAiC;AACjC,wBAAwB;AACxB,mCAAmC;AACnC,kCAAkC;AAClC;;AAEA;AACA,+BAA+B;AAC/B,aAAa;AACb,mBAAmB;AACnB,mBAAmB;AACnB","sourcesContent":["html {\n    font-size: 14px;\n}\n\nbody {\nfont-family: var(--font-family);\nfont-weight: normal;\nbackground: var(--surface-ground);\ncolor: var(--text-color);\n-webkit-font-smoothing: antialiased;\n-moz-osx-font-smoothing: grayscale;\n}\n\n.card {\nbackground: var(--surface-card);\npadding: 2rem;\nborder-radius: 10px;\nmargin-bottom: 1rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": `NavigationBar_card__hQqYq`
};
export default ___CSS_LOADER_EXPORT___;
