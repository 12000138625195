import { Tooltip } from "primereact/tooltip";

import commonStyle from "../content/CommonStyles.module.css";

const ProductStatusCircle = ({ rowData, type }) => {
    return (
        <div className="align-content-center">
            {rowData.is_active ? (
                <div
                    className={`${commonStyle[type]} ${commonStyle.active}`}
                    data-pr-tooltip="Активний"
                    data-pr-position="top"
                    id={`statusCircle-${rowData.id}`}
                ></div>
            ) : (
                <div
                    className={`${commonStyle[type]} ${commonStyle.inactive}`}
                    data-pr-tooltip="Деактивований"
                    data-pr-position="top"
                    id={`statusCircle-${rowData.id}`}
                ></div>
            )}
            <Tooltip target={`#statusCircle-${rowData.id}`} />
        </div>
    );
}

export default ProductStatusCircle;