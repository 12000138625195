import { buildGroupHierarchy, findGroupCode } from "../../../../Utils/Utils";
import useApi from "../../../../Utils/BackendClient";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";

import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { FloatLabel } from "primereact/floatlabel";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Tag } from "primereact/tag";
import { TabPanel, TabView } from "primereact/tabview";
import { Toolbar } from "primereact/toolbar";
import { TreeSelect } from "primereact/treeselect";

import commonStyle from "../../CommonStyles.module.css";

const ServiceGroupCreation = () => {

    const fieldTypes = ["STRING", "INTEGER", "FLOAT", "DATE"];
    const statusList = ["Активний", "Деактивований"];
    
    const navigate = useNavigate();
    const location = useLocation();
    const { createServiceGroup, fetchServicesExtended, fetchServiceGroupDetails, updateServiceGroup } = useApi();

    const [visible, setVisible] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [sortedGroups, setSortedGroups] = useState([]);
    const [group, setGroup] = useState({
        name: '',
        root_group: null,
        description: '',
        is_active: null,
        fields: [],
    });

    useEffect(() => {

        const getServicesExtended = async () => {

            const response = await fetchServicesExtended();

            if (response.status === 200) {
                const groups = buildGroupHierarchy(response.data);
                setSortedGroups(groups);

                if (location.state !== null) {

                    setIsEdit(true);
                    const fetchData = async () => {

                        const response = await fetchServiceGroupDetails(location.state.id);

                        if(response.status === 200){
                            response.data.is_active = response.data.is_active ? "Активний" : "Деактивований";
                            response.data.root_group = findGroupCode(groups, response.data.root_group);

                            setGroup(response.data);
                        }
                    }

                    fetchData();
                }
            }
        }

        getServicesExtended();

    }, [location.state, fetchServicesExtended, fetchServiceGroupDetails]);

    const ReturnButtonHandler = () => {
        navigate("/servicegroups");
    }

    const formik = useFormik({
        initialValues: group,
        enableReinitialize: true,
        validate: values => {
            const errors = {};

            if (values.name === null || values.name === "") {
                errors.name = "Назва групи не повинна бути пустою";
            }

            return errors;
        },

        onSubmit: (values) => {

            if (values.root_group !== undefined && values.root_group !== null) {
                if (values.root_group.toString().includes('-')) {
                    var keys = values.root_group.split('-');
                    values.root_group = keys[(keys.length - 1)];
                }
            }
            else {
                values.root_group = null;
            }

            if (values.is_active === "Активний") {
                values.is_active = true;
            }
            else {
                values.is_active = false;
            }

            console.log(values);

            const saveChanges = async () => {

                const response = isEdit ? await updateServiceGroup(values.id, values) : await createServiceGroup(values);

                const successStatus = isEdit ? 200 : 201;

                let toastMessage = {
                    severity: "error", summary: isEdit ? "Помилка редагування" : "Помилка створення",
                    detail: "Групу не " + (isEdit ? "відредаговано. " : " створено. ") + "Помилка сервера " + response.status, life: 3000
                };

                if(response.status === successStatus){
                    toastMessage = isEdit ? { severity: "success", summary: "Успіх редагування", detail: "Групу успішно відредаговано", life: 3000 } :
                    { severity: "success", summary: "Успіх створення", detail: "Групу успішно створено", life: 3000 }
                }

                navigate("/servicegroups", { state: { toast: toastMessage } });
            }

            saveChanges();
        }
    });

    const dialogFormik = useFormik({
        initialValues: {
            name: '',
            field_type: null,
            mandatory: false
        },
        validate: values => {
            const errors = {};

            if (values.name === null || values.name === "") {
                errors.name = "Назва поля не повинна бути пустою";
            }

            if (values.field_type === null || values.field_type === '') {
                errors.field_type = "Виберіть тип поля";
            }

            return errors;
        },
        onSubmit: values => {
            console.log(values);

            if (formik.values.fields.length === 0) {
                formik.setFieldValue('fields', [values]);
            }
            else {
                formik.setFieldValue('fields', [...formik.values.fields, values])
            }
            dialogFormik.resetForm();
            setVisible(false);
        }
    });

    const mainToolbarLeftTemplate = () => {
        return (<h3>Створення групи послуг</h3>);
    }

    const mainToolbarRightTemplate = () => {
        return (
            <div className="flex flex-wrap gap-2">
                <Button label="Зберегти" severity="success" className={commonStyle.addButton} type="submit" />
                <Button label="Вийти" severity="secondary" type="button" onClick={ReturnButtonHandler} className={commonStyle.closeButton} />
            </div>
        );
    }

    const rightToolbarTemplate = () => {
        return (
            <div className="flex flex-wrap justify-content-end">
                <Button label="Додати" icon="pi pi-plus" type="button" className={commonStyle.addButton} onClick={() => setVisible(true)} severity="success" />
            </div>
        );
    };

    const ObligatoryBodyTemplate = (product) => {

        if (product.mandatory) {
            return <Tag className={commonStyle.successTag} value='Так' severity="success" style={{ width: '20%' }} />;
        }

        return <Tag className={commonStyle.deletedTag} value="Ні" severity="danger" style={{ width: '20%' }}></Tag>;
    };

    const actionBodyTemplate = (rowData) => {
        return (<div className="flex flex-wrap gap-2">
            <Button
                icon="pi pi-trash"
                type="button"
                className={`p-button-rounded p-button-danger ${commonStyle.deleteButton}`}
                onClick={() => console.log(rowData.field_name)}
            />
        </div>
        );
    };

    return (
        <form onSubmit={formik.handleSubmit}>
            <Toolbar className="mb-4" start={mainToolbarLeftTemplate} end={mainToolbarRightTemplate}></Toolbar>
            <div className="grid">
                <div className={`${commonStyle.leftMenu} col-3`}>
                    <div className={commonStyle.formInput}>
                        <div className="p-inputgroup flex flex-column">
                            <FloatLabel>
                                <InputText
                                    name="name"
                                    value={formik.values.name}
                                    onChange={(e) => { formik.setFieldValue('name', e.target.value) }}
                                    className={formik.errors.name && formik.touched.name ? 'p-invalid' : ''}
                                />
                                <label>Назва групи</label>
                            </FloatLabel>
                            {formik.errors.name && formik.touched.name && (<small className={commonStyle.errorSmall}>{formik.errors.name}</small>)}
                        </div>
                    </div>
                    <div className={commonStyle.formInput}>
                        <div className="p-inputgroup flex flex-column">
                            <FloatLabel>
                                <TreeSelect name="root_group" value={formik.values.root_group} onChange={(e) => formik.setFieldValue('root_group', e.value)} options={sortedGroups} showClear className="w-full" />
                                <label>Батьківська група</label>
                            </FloatLabel>
                        </div>
                    </div>
                    <div className={commonStyle.formInput}>
                        <div className="p-inputgroup flex flex-column">
                            <FloatLabel>
                                <Dropdown name="status" value={formik.values.is_active} onChange={(e) => formik.setFieldValue('is_active', e.value)} options={statusList} showClear className="w-full" />
                                <label>Статус</label>
                            </FloatLabel>
                        </div>
                    </div>
                </div>
                <div className="col-9">
                    <TabView>
                        <TabPanel header="Налаштування полів">
                            <DataTable header={rightToolbarTemplate} value={formik.values.fields} rows={10} tableStyle={{ minWidth: '60rem' }} >
                                <Column field="name" header="Назва поля" sortable></Column>
                                <Column field="field_type" header="Тип поля" sortable></Column>
                                <Column field="mandatory" dataType="bool" align="center" body={ObligatoryBodyTemplate} header="Обов'язковість" ></Column>
                                <Column field="action" body={actionBodyTemplate} />
                            </DataTable>
                        </TabPanel>
                        <TabPanel header="Опис">
                            <div className={commonStyle.tableStyle}><InputTextarea className={commonStyle.fullWidth} value={formik.values.description} onChange={(e) => { formik.setFieldValue('description', e.target.value) }} rows={10} ></InputTextarea></div>
                        </TabPanel>
                    </TabView>
                </div>
            </div>
            <Dialog header="Додати поле до групи послуг"
                visible={visible}
                position="top"
                style={{ width: '30vw', display: 'flex', flexDirection: 'column' }}
                onHide={() => setVisible(false)}
                draggable={false}
                resizable={false}
            >
                <form onSubmit={dialogFormik.handleSubmit} style={{ flex: '1', display: 'flex', flexDirection: 'column' }}>
                    <div className={commonStyle.dialogFormInput}>
                        <div className="p-inputgroup flex flex-column gap-1 md:w-22rem">
                            <FloatLabel>
                                <InputText
                                    name="name"
                                    value={dialogFormik.values.name}
                                    onChange={(e) => { dialogFormik.setFieldValue('name', e.target.value) }}
                                    className={dialogFormik.errors.name && dialogFormik.touched.name ? 'p-invalid' : ''}
                                />
                                <label>Назва поля</label>
                            </FloatLabel>
                            {dialogFormik.errors.name && dialogFormik.touched.name && (<small className={commonStyle.errorSmall}>{dialogFormik.errors.name}</small>)}
                        </div>
                    </div>
                    <div className={commonStyle.dialogFormInput}>
                        <div className="p-inputgroup flex flex-column gap-1 md:w-22rem">
                            <FloatLabel>
                                <Dropdown
                                    name="field_type"
                                    value={dialogFormik.values.field_type}
                                    onChange={(e) => dialogFormik.setFieldValue('field_type', e.value)}
                                    options={fieldTypes}
                                    showClear
                                    className={dialogFormik.errors.field_type && dialogFormik.touched.field_type ? 'p-invalid w-full' : 'w-full'}
                                />
                                <label>Тип поля</label>
                            </FloatLabel>
                            {dialogFormik.errors.field_type && dialogFormik.touched.field_type && (<small className={commonStyle.errorSmall}>{dialogFormik.errors.field_type}</small>)}
                        </div>
                    </div>
                    <div className={commonStyle.dialogFormInput}>
                        <div className={`${commonStyle.checkboxStyle} p-inputgroup flex-1 md:w-22rem"`}>
                            <Checkbox
                                name="mandatory"
                                onChange={(e) => { dialogFormik.setFieldValue('mandatory', !dialogFormik.values.mandatory) }}
                                checked={dialogFormik.values.mandatory}
                            />
                            <label>Обовязковість</label>
                        </div>
                    </div>
                    <div className={commonStyle.dialogSubmit}>
                        <Button label="Додати поле" icon="pi pi-check" type="submit" className={commonStyle.addButton} severity="success" autoFocus />
                    </div>
                </form>
            </Dialog>
        </form>);
}

export default ServiceGroupCreation;