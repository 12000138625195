import useApi from "../../../../Utils/BackendClient";
import EnumsContext from "../../../../context/enums-context";
import ActStatusCircle from "../../../UIElements/StatusCircle";
import { formatCurrency, formatDate } from "../../../../Utils/Utils";
import { useContext, useEffect, useRef, useState } from "react";
import useGetEnums from "../../../../Utils/EnumsUtils";
import { useLocation, useNavigate } from "react-router-dom";
import { addLocale, locale } from "primereact/api";
import { Translate, UaLocale } from "../../../../Utils/TranslationUtils";

import { Button } from "primereact/button";
import { Chip } from "primereact/chip";
import { Column } from "primereact/column";
import { confirmDialog, ConfirmDialog } from "primereact/confirmdialog";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { SplitButton } from "primereact/splitbutton";
import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";

import commonStyle from "../../CommonStyles.module.css";
import PaymentSideBar from "../../../UIElements/PaymentSidebar";

const Sales = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const ctx = useContext(EnumsContext);
    const toast = useRef(null);
    const buttonRef = useRef(null);
    const { deleteSaleAct, fetchSaleActs, finalizeSaleAct } = useApi();

    const [loading, setLoading] = useState(true);
    const [totalRecords, setTotalRecords] = useState(0);
    const [sales, setSales] = useState(null);
    const [reloadData, setReloadData] = useState(true);
    const [visible, setVisible] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [tableFilters, setTableFilters] = useState({
        cagent: { value: null, matchMode: 'contains' },
        division: {value: null, matchMode: 'contains' },
        pay_status: {value: null, matchMode: 'contains' }
    });
    const [lazyState, setlazyState] = useState({
        first: 0,
        rows: 10,
        page: 1,
        sortField: "created_at",
        sortOrder: 1,
        filters:{
            cagent: { value: null, matchMode: 'contains' },
            division: {value: null, matchMode: 'contains' },
            pay_status: {value: null, matchMode: 'contains' }
        }
    });

    addLocale('ua', UaLocale);
    locale('ua');
    useGetEnums(["divisions", "clients", "pay_statuses"]);

    const showToast = (msg) => {
        toast.current.show(msg);
    }

    useEffect(() => {
        if(location.state !== null){
            showToast(location.state.toast);
        }

        location.state = null;
    },[location.state, location]);

    useEffect(() => {
        const fetchData = async () => {

            const response = await fetchSaleActs(lazyState);

            if (response.status === 200) {
                setTotalRecords(response.data.count);
                setSales(response.data.results);
            }
            else {
                toast.current.show({ severity: "error", summary: "Помилка завантаження списку актів", detail: "Помилка сервера " + response.status, life: 3000 });

                setSales([]);
            }
            setLoading(false);
        }

        fetchData();

    }, [lazyState, fetchSaleActs, reloadData]);
    
    const hideSidebar = () => {
        setVisible(false);
    };

    const PaymenButtonHandler = (rowData) => {

        setSelectedItem(rowData);
        setVisible(true);
    }

    const FinalizeActHandler = (rowData) => {

        const finalizeAct = async () => {

            const response = await finalizeSaleAct(rowData.id);

            if (response.status === 200) {
                toast.current.show({ severity: 'success', summary: 'Фіналізація', detail: 'Акт фіналізовано', life: 3000 });
            }
            else if (response.status === 404) {
                toast.current.show({ severity: "error", summary: "Помилка фіналізації", detail: "Акту " + rowData.id + " не знайдено", life: 3000 });
            }
            else if (response.status === 405) {
                toast.current.show({ severity: "error", summary: "Помилка фіналізації", detail: "Акт " + rowData.id + " уже фіналізовано", life: 3000 });
            }
            else {
                toast.current.show({ severity: "error", summary: "Помилка фіналізації", detail: "Помилка сервера " + response.status, life: 3000 });
            }

            setReloadData(!reloadData);
        }

        finalizeAct();
    }

    const DeleteButtonHandler = (rowData) => {

        const deleteAct = async () => {

            const response = await deleteSaleAct(rowData.id);

            if (response.status === 204) {
                toast.current.show({ severity: "success", summary: 'Видалення', detail: 'Акт успішно видалено', life: 3000 });
            }
            else if (response.status === 404) {
                toast.current.show({ severity: "error", summary: "Помилка видалення", detail: "Акту " + rowData.id + " не знайдено", life: 3000 });
            }
            else if (response.status === 405) {
                toast.current.show({ severity: "error", summary: "Помилка видалення", detail: "Акт " + rowData.id + "фінлізовано і не може бути видалено", life: 3000 });
            }
            else {
                toast.current.show({ severity: "error", summary: "Помилка видалення", detail: "Помилка сервера " + response.status, life: 3000 });
            }

            setReloadData(!reloadData);
        }

        deleteAct();
    }

    const CreateNewSaleButtonHandler = () => {
        navigate('/sales/newsale');
    };

    const EditSaleHandler = rowData => {
        const route = '/sales/editsale/' + rowData.id;
        navigate(route, { state: { id: rowData.id } });
    }

    const ClientReturnHandler = rowData => {
        console.log(rowData);
        const route = '/productsreturn/newproductsreturn/';
        navigate(route, { state: { saleId: rowData.id } });
    }

    const confirmDelete = (event, rowData) => {
        confirmDialog({
            group: 'headless',
            header: 'Підтвердження',
            message: 'Підтвердіть видалення акту',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: "Так",
            rejectLabel: "Hi",
            defaultFocus: 'accept',
            accept: () => {DeleteButtonHandler(rowData)}
        });
    }

    const confirmFinalize = (event, rowData) => {
        confirmDialog({
            group: 'headless',
            header: 'Підтвердження',
            message: 'Після фіналізації акту, буде відсутня можливість його редагування',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: "Так",
            rejectLabel: "Hi",
            defaultFocus: 'accept',
            accept: () => {FinalizeActHandler(rowData)}
        });
    } 

    //End payments

    const onPage = (event) => {
        event.page = event.page + 1;
        setlazyState(event);
    };

    const onSort = (event) => {
        event.page = lazyState.page;
        setlazyState(event);
    }

    const onFilter = (event) => {
        event.page = 1;
        setTableFilters(event.filters);
    }

    const filterApply = () => {
        const editedLazyState = lazyState;
        editedLazyState.filters = tableFilters;
        setlazyState(editedLazyState);
        setReloadData(!reloadData)
    }

    const clearFilter = (paramName, value) => {
        setlazyState(prevState => ({
            ...prevState,
            filters: {
                ...prevState.filters,
                [paramName]: {
                    ...prevState.filters[paramName],
                    value: value
                }
            }
        }));
    }

    const clearFilterFromChips = (paramName, value) => {
        clearFilter(paramName, value);
        setTableFilters(prevState => ({
            ...prevState,
            [paramName]: {
                value: value
            }
        }));
    }

    const formatFilterChips = () => {

        const getChangedValue = (filterKey, value) => {
            if (filterKey === "cagent") {
                return ctx.clients[value];
            }
            else if(filterKey === "division") {
                return ctx.divisions[value];
            }
            else if(filterKey === "pay_status") {
                return ctx.pay_statuses[value];
            }

            return value;
        };
    
        return (
            <div className="flex flex-wrap gap-2">
                {Object.keys(lazyState.filters).map((filterKey) => {
                    const filter = lazyState.filters[filterKey];
    
                    if (filter.value && filter.value !== "") {
                        const translatedKey = filterKey === "cagent" ? "Клієнт" : Translate(filterKey);
                        const changedValue = getChangedValue(filterKey, filter.value);
    
                        return (
                            <Chip
                                key={filterKey}
                                label={`${translatedKey}: ${changedValue}`}
                                removable
                                onRemove={() => clearFilterFromChips(filterKey, null)} 
                            />
                        );
                    }
                    return null;
                })}
            </div>
        );
    };

    const tableHeader = (
        <div className="flex justify-content-between align-items-center w-full">
            {formatFilterChips()}
        </div>
    );

    const toolbarRightTemplate = () => {
        return (<div className="flex flex-wrap gap-2">
            <Button label="Додати замовлення" severity="success" className={commonStyle.addButton} onClick={CreateNewSaleButtonHandler} />
        </div>)
    }

    const formatAdditionalButtons = (rowData) => {
        let buttons = [];
        if(rowData.status !== "FINALIZED"){
            buttons.push({
                label: 'Фіналізувати',
                icon: 'pi pi-lock',
                command: (event) => { confirmFinalize(event, rowData) }
            });
        }
        else{
            if(rowData.pay_status !== "PAYED"){
                buttons.push({
                    label: 'Оплатити',
                    icon: 'pi pi-money-bill',
                    command: () => { PaymenButtonHandler(rowData) }
                });
            }

            buttons.push({
                label: 'Оформити повернення',
                icon: 'pi pi-directions-alt',
                command: (event) => { ClientReturnHandler(rowData) }
            });
        }
        
        if(rowData.can_be_deleted){
            buttons.push({
                label: 'Видалити',
                icon: 'pi pi-trash',
                command: (event) => { confirmDelete(event, rowData) }
            });
        }

        buttons = buttons.length > 0 ? buttons : null;
        return buttons
    };

    const actionBodyTemplate = (rowData) => {
        const buttons = formatAdditionalButtons(rowData);
        
        const buttonLabel = rowData.status !== "FINALIZED" ? "Редагувати" : "Деталі акту";
        const buttonIcon = rowData.status !== "FINALIZED" ? "pi pi-pencil" : "pi pi-eye";

        return (
            <>
                {buttons !== null ? (
                    <SplitButton 
                        label={buttonLabel}
                        icon={buttonIcon}
                        onClick={() => EditSaleHandler(rowData)} 
                        model={buttons}
                        text
                        ref={buttonRef} // Set the ref here
                    />
                ) : (
                    <Button
                        label={buttonLabel}
                        icon={buttonIcon}
                        onClick={() => EditSaleHandler(rowData)} 
                        text
                    />
                )}
            </>
        );
    };

    const dropDownTemplate = (options, values) => {
        const dropdownOptions = Object.entries(values).map(([key, value]) => ({
            value: key,
            label: value,
        }));
    
        return (
            <Dropdown
                value={options.value}
                onChange={(e) => options.filterApplyCallback(e.value)}
                options={dropdownOptions}
                placeholder="Виберіть значення"
                className="p-column-filter"
            />
        );
    };

    return (
        <>
            <Toast ref={toast} />
            <Toolbar className="mb-4" end={toolbarRightTemplate}></Toolbar>
            <div className='grid'>
                <div className='col-12 justify-content-center'>
                    <DataTable value={sales} header={tableHeader} stripedRows lazy rows={lazyState.rows} paginator onPage={onPage} totalRecords={totalRecords} rowsPerPageOptions={[10, 20, 50]} first={lazyState.first}
                        onSort={onSort} sortField={lazyState.sortField} sortOrder={lazyState.sortOrder}
                        dataKey="id" onFilter={onFilter} filters={lazyState.filters} loading={loading} emptyMessage="Товарів не знайдено" tableStyle={{ minWidth: '60rem' }} >
                        <Column field="act_number" header="Номер акту" sortable />
                        <Column field="act_date" dataType="date" header="Дата акту" />
                        <Column field="cagent" header="Клієнт" sortable filter filterElement={(options) => dropDownTemplate(options, ctx.clients)} onFilterApplyClick={filterApply}
                            onFilterClear={() => clearFilter("cagent", null)} showFilterMatchModes={false} body={rowData => ctx.clients[rowData.cagent]} style={{ width: '13%' }} />
                        <Column field="division" header="Підрозділ" sortable filter filterElement={(options) => dropDownTemplate(options, ctx.divisions)} onFilterApplyClick={filterApply}
                            onFilterClear={() => clearFilter("division", null)} showFilterMatchModes={false} body={rowData => ctx.divisions[rowData.division]} style={{ width: '10%' }} />
                        <Column field="amountUAH" header="Сума, грн" body={rowData => formatCurrency(rowData.amountUAH, "UAH")} sortable />
                        <Column field="pay_status" header="Статус оплати" filter filterElement={(options) => dropDownTemplate(options, ctx.pay_statuses)} onFilterApplyClick={filterApply}
                            onFilterClear={() => clearFilter("pay_status", null)} showFilterMatchModes={false} body={rowData => ctx.pay_statuses[rowData.pay_status]} style={{ width: '10%' }} />
                        <Column field="pay_due_date" header="Дата оплати" />
                        <Column field="status" header="Статус" style={{ width: '4%' }} body={rowData => <ActStatusCircle rowData={rowData} />} sortable />
                        <Column field="created_by" header="Менеджер" sortable />
                        <Column field="created_at" header="Дата" body={(rowData) => formatDate(rowData.created_at)} sortable />
                        <Column field="action" body={actionBodyTemplate} />
                    </DataTable>
                </div>
                <ConfirmDialog group="headless" />
            </div>
            <PaymentSideBar visible={visible} onHide={hideSidebar} onMessage={showToast} data={selectedItem} moneyMovementType="INCOME" reloadData={() => setReloadData(!reloadData)}/>
        </>)
};

export default Sales;