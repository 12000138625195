import { useFormik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState} from "react";
import EnumsContext from "../../../../context/enums-context";
import useGetEnums from "../../../../Utils/EnumsUtils";
import { findKeyByValue } from "../../../../Utils/Utils";
import useAuthenticatedRequest from "../../../../Utils/TokenrRefreshUtils";

import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { FloatLabel } from "primereact/floatlabel";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Menubar } from "primereact/menubar";
import { Toolbar } from "primereact/toolbar";

import commonStyle from "../../CommonStyles.module.css";

const NewDivision = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const ctx = useContext(EnumsContext);
    const authenticatedRequest = useAuthenticatedRequest();

    const statuses = ["Активний", "Деактивований"];
    const countries = ["Україна"];

    const [showAddress, setShowAddress] = useState();
    const [isEdit, setIsEdit] = useState(false);
    const [division, setDivision] = useState(
        {
            name: "",
            is_active: "Активний",
            root_division: null,
            country: "Україна",
            region: null,
            city: "",
            street: "",
            building_number: "",
            apartment_number: "",
            notes: "",
        }
    ); 

    const menuItems = [
        {
            label: "Загальні дані",
            className: !showAddress ? commonStyle["active-menu-item"] : "",
            command: () => {
                setShowAddress(false);
            }

        },
        {
            label: "Адреса",
            className: showAddress ? commonStyle["active-menu-item"] : "",
            command: () => {
                setShowAddress(true);
            }
        }
    ]

    useGetEnums(["divisions", "regions"]);

    useEffect(() => {
        if (location.state !== null) {

            setIsEdit(true);
            const fetchData = async () => {

                const url = process.env.REACT_APP_BACKEND_URL + "divisions/get_division/" + location.state.id + "/";
                const options = {
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/json'
                    }
                };

                const response = await authenticatedRequest(url, options);
                const parsedResponse = await response.json();
                //logging to remove later
                console.log(parsedResponse);

                parsedResponse.is_active = parsedResponse.is_active ? "Активний" : "Деактивований";
                parsedResponse.root_division = ctx.divisions[parsedResponse.root_division];
                parsedResponse.country = "Україна";
                parsedResponse.region = ctx.regions[parsedResponse.region];

                setDivision(parsedResponse);
            }

            fetchData();
        }
    }, [location.state, ctx.divisions, ctx.regions, authenticatedRequest]);

    const ReturnButtonHandler = () => {
        navigate("/divisions");
    }

    const formik = useFormik({
        initialValues: division,
        enableReinitialize: true,
        validate: values => {
            const errors = {};

            if (values.name === null || values.name === "") {
                errors.name = "Назва підрозділу не повинна бути пустою"
            }

            if (values.country === null || values.country === undefined) {
                errors.country = "Виберіть будь ласка країну"
            }

            if (values.region === null || values.region === undefined) {
                errors.region = "Виберіть будь ласка область"
            }
            return errors;
        },
        onSubmit: (values) => {

            values.is_active = values.is_active === "Активний" ? true : false;

            if (values.root_division != null) {
                values.root_division = findKeyByValue(ctx.divisions, values.root_division);
            }
            values.country = "UKRAINE";

            values.region = findKeyByValue(ctx.regions, values.region);

            console.log(values);

            const action = !isEdit ? "divisions/create/" : "divisions/update/" + location.state.id + "/";
            const method = !isEdit ? "POST" : "PUT";

            const saveData = async () => {

                const url = process.env.REACT_APP_BACKEND_URL + action;
                const options = {
                    method: method,
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(values)
                };

                const response = await authenticatedRequest(url, options);
                //logging to remove later
                console.log(response);

                const responseMessage = await response.json();

                let toastMessage = { severity: "error", summary: isEdit ? "Помилка редагування" : "Помилка створення", 
                    detail: "Підрозділ " + values.name + " не " + (isEdit ? "відредаговано. " : " створено. ") + " Код помилки: " + response.status + " Помилка: " + responseMessage.detail, life: 3000 };

                if(isEdit && response.status === 200){
                    toastMessage = {severity: "success", summary: "Успіх редагування", detail: "Підрозділ " + values.name + " успішно відредаговано", life: 3000}
                }
                else if(!isEdit && response.status === 201){
                    toastMessage = {severity: "success", summary: "Успіх створення", detail: "Підрозділ " + values.name + " успішно створено", life: 3000}
                }

                navigate("/divisions", { state: { toast: toastMessage}});
            }

            saveData();
        }
    });

    const mainToolbarLeftTemplate = () => {
        return (<h3>Створення нового підрозділу</h3>);
    }

    const mainToolbarRightTemplate = () => {
        return (<>
            <div className="flex flex-wrap gap-2">
                <Button label="Зберегти" severity="success" className={commonStyle.addButton} type="submit" />
                <Button label="Вийти" severity="secondary" type="button" className={commonStyle.closeButton} onClick={ReturnButtonHandler} />
            </div>
        </>);
    }

    return (<form onSubmit={formik.handleSubmit}>
        <Toolbar className="mb-4" start={mainToolbarLeftTemplate} end={mainToolbarRightTemplate}></Toolbar>
        <Menubar model={menuItems} />
        {!showAddress && <div className="grid">
            <div className="col-4">
                <div className={`${commonStyle.inputfields} flex-column p-inputgroup`}>
                    <FloatLabel>
                        <InputText
                            name="name"
                            value={formik.values.name}
                            onChange={(e) => { formik.setFieldValue('name', e.target.value) }}
                            className={formik.errors.name && formik.touched.name ? 'p-invalid' : ''}
                        />
                        <label>Назва підрозділу</label>
                    </FloatLabel>
                    {formik.errors.name && formik.touched.name && (<small className={commonStyle.errorSmall}>{formik.errors.name}</small>)}
                </div>
            </div>
            <div className="col-4">
                <div className={`${commonStyle.inputfields} flex-column p-inputgroup`}>
                    <FloatLabel>
                        <Dropdown name="is_active" value={formik.values.is_active}
                            onChange={(e) => formik.setFieldValue('is_active', e.value)}
                            options={statuses}
                            showClear />
                        <label>Статус</label>
                    </FloatLabel>
                </div>
            </div>
            <div className="col-4">
                <div className={`${commonStyle.inputfields} flex-column p-inputgroup`}>
                    <FloatLabel>
                        <Dropdown name="root_division" value={formik.values.root_division}
                            onChange={(e) => formik.setFieldValue('root_division', e.value)}
                            options={Object.values(ctx.divisions)}
                            showClear />
                        <label>Батьківський підрозділ</label>
                    </FloatLabel>
                </div>
            </div>
        </div>}
        {showAddress && <><div className="grid">
            <div className="col-4">
                <div className={`${commonStyle.inputfields} flex-column p-inputgroup`}>
                    <FloatLabel>
                        <Dropdown name="country" value={formik.values.country}
                            onChange={(e) => formik.setFieldValue('country', e.value)}
                            options={countries}
                            className={formik.errors.country ? 'p-invalid' : ''}
                            showClear />
                        <label>Країна</label>
                    </FloatLabel>
                    {formik.errors.country && (<small className={commonStyle.errorSmall}>{formik.errors.country}</small>)}
                </div>
                <div className={`${commonStyle.inputfields} flex-column p-inputgroup`}>
                    <FloatLabel>
                        <InputText value={formik.values.street} onChange={(e) => { formik.setFieldValue('street', e.target.value) }} />
                        <label>Вулиця</label>
                    </FloatLabel>
                </div>
            </div>
            <div className="col-4">
                <div className={`${commonStyle.inputfields} flex-column p-inputgroup`}>
                    <FloatLabel>
                        <Dropdown name="region" value={formik.values.region}
                            onChange={(e) => formik.setFieldValue('region', e.value)}
                            options={Object.values(ctx.regions)}
                            className={formik.errors.region && formik.touched.region ? 'p-invalid' : ''}
                            showClear />
                        <label>Область</label>
                    </FloatLabel>
                    {formik.errors.region && formik.touched.region && (<small className={commonStyle.errorSmall}>{formik.errors.region}</small>)}
                </div>
                <div className={`${commonStyle.inputfields} flex-column p-inputgroup`}>
                    <FloatLabel>
                        <InputText value={formik.values.building_number} onChange={(e) => { formik.setFieldValue('building_number', e.target.value) }} />
                        <label>Номер будинку</label>
                    </FloatLabel>
                </div>
            </div>
            <div className="col-4">
                <div className={`${commonStyle.inputfields} flex-column p-inputgroup`}>
                    <FloatLabel>
                        <InputText value={formik.values.city}
                            onChange={(e) => { formik.setFieldValue('city', e.target.value) }}
                            className={formik.errors.city && formik.touched.city ? 'p-invalid' : ''} />
                        <label>Населений пункт</label>
                    </FloatLabel>
                    {formik.errors.city && formik.touched.city && (<small className={commonStyle.errorSmall}>{formik.errors.city}</small>)}
                </div>
                <div className={`${commonStyle.inputfields} p-inputgroup`}>
                    <FloatLabel>
                        <InputText value={formik.values.apartment_number} onChange={(e) => { formik.setFieldValue('apartment_number', e.target.value) }} />
                        <label>Квартира</label>
                    </FloatLabel>
                </div>
            </div>
        </div>
            <div className={`${commonStyle.inputfields} p-inputgroup col-8`}>
                <span className="p-float-label md:w-90">
                    <InputTextarea value={formik.values.description} onChange={(e) => { formik.setFieldValue('description', e.target.value) }} rows={10} cols={80} />
                    <label>Примітки</label>
                </span>
            </div>
        </>}
    </form>)
}

export default NewDivision;