import React, { useContext, useState } from 'react';
import { UserPermissionContext } from '../../context/UserPermissionProvider';

import { PanelMenu } from 'primereact/panelmenu';
import { useNavigate } from 'react-router-dom';

import './NavigationBar.module.css';

const NavigationBar = params => {

    const [expandedKeys, setExpandedKeys] = useState({});

    const permCtx = useContext(UserPermissionContext);
    console.log(permCtx.permissions);
    
    const navigate = useNavigate();

    const closeSideBar = () =>{
        params.switchPage();
    }

    const IncludeDashboards = items => {
        if (permCtx.permissions?.includes("dashboards.view_dashboard")) {
            items.push(
                {
                    key: "1",
                    label: 'Дашборд',
                    icon: 'pi pi-fw pi-chart-line',
                    items: [
                        {
                            label: 'Віджети',
                            command: (event) => {
                                navigate('/widgets');
                                closeSideBar();
                            }
                        },
                        /*{
                           // label: 'Динаміка продажів',
                        },
                        {
                            label: 'Порівняння проданих товарів',
                        },
                        {
                            label: 'Фінансовий результат',
                        }*/
                    ]
                }
            )
        }
    }

    const IncludeProductsAndServices = items => {
        items.push(
            {
                key: "2",
                label: 'Товари та послуги',
                icon: 'pi pi-shopping-cart',
                items: [
                    {
                        label: 'Групи товарів',
                        command: () => {
                            navigate('/productgroups');
                            closeSideBar();
                        }
                    },
                    {
                        label: 'Перелік товарів',
                        command: () => {
                            navigate('/products');
                            closeSideBar();
                        }
                    },
                    {
                        label: 'Групи послуг',
                        command: () => {
                            navigate('/servicegroups');
                            closeSideBar();
                        }
                    },
                    {
                        label: 'Перелік послуг',
                        command: () => {
                            navigate('/services');
                            closeSideBar();
                        }
                    }
                ]
            }
        );
    }
    
    const IncludeAccounting = items => {
        items.push(
            {
                key: "3",
                label: "Облік",
                icon: 'pi pi-fw pi-calendar',
                items: [
                    {
                        label: 'Прийом товарів',
                        command: () => {
                            navigate('/productsreception');
                            closeSideBar();
                        }
                    },
                    /*{
                        label: 'Переміщення товарів',
                        command: () => {
                            navigate('/productsmove');
                        } 
                    },*/
                    {
                        label: 'Залишки',
                        command: () => {
                            navigate('/leftovers');
                            closeSideBar();
                        }
                    },
                    /*{
                        label: 'Списання',
                         command: () => {
                            navigate('/writeoff');
                        }
                    },*/
                    /*{
                        label: 'Повернення',
                         command: () => {
                            navigate('/productsreturn');
                        }
                    },
                    {
                        label: 'Зовнішні товари',
                    }*/
                ]
            }
        );
    }

    const IncludeSales = items => {
        items.push(      
            {
                key: "4",
                label: "Продажі",
                icon: 'pi pi-fw pi-money-bill',
                items: [
                    {
                        label: 'Продажі та послуги',
                        command: () => {
                            navigate('/sales');
                            closeSideBar();
                        }
                    },
                    {
                        label: 'Повернення клієнтів',
                         command: () => {
                            navigate('/productsreturn');
                            closeSideBar();
                        }
                    }
                ]
            }
        );
    }

    const IncludeCounterAgents = items => {
        items.push(
            {
                key: "5",
                label: "Контрагенти ",
                icon: 'pi pi-fw pi-comment',
                items: [
                    {
                        label: 'Постачальники',
                        command: () => {
                            navigate('/partners');
                            closeSideBar();
                        }
                    },
                    {
                        label: 'Клієнти',
                        command: () => {
                            navigate('/clients');
                            closeSideBar();
                        }
                    }
                ]
            }
        );
    }
    
    const IncludeBookkeeping = mainItems => {
        const bookkeepingSection = {
            key: "6",
                label: "Бухгалтерія",
                icon: 'pi pi-fw pi-paperclip',
                items: []
        };

        if(permCtx.permissions?.includes("payments.view_payment")){
            bookkeepingSection.items.push({
                label: 'Платежі',
                command: () => {
                    navigate("/payments");
                    closeSideBar();
                }
            });
        }
    
        if (permCtx.permissions?.includes("transactions.view_transaction")) {
            bookkeepingSection.items.push({
                label: 'Транзакції',
                command: () => {
                    navigate("/transactions");
                    closeSideBar();
                }
            });
        }
        
        if(bookkeepingSection.items.length > 0){
            mainItems.push(bookkeepingSection);
        }
    }

    const IncludeSettings = items => {
        items.push(     
            {
                key: "7",
                label: "Налаштування",
                icon: 'pi pi-fw pi-cog',
                items: [
                    {
                        label: 'Налаштування організації',
                        command: () => {
                            navigate('/organization');
                            closeSideBar();
                        }
                    },
                    {
                        label: 'Користувачі',
                        command: () => {
                            navigate('/users');
                            closeSideBar();
                        }
                    },
                    {
                        label: 'Ролі користувача',
                        command: () => {
                            navigate('/roles');
                            closeSideBar();
                        }
                    },
                    {
                        label: 'Ієрархія підрозділів',
                        command: () => {
                            navigate('/divisions');
                            closeSideBar();
                        }
                    },
                    {
                        label: 'Валюта',
                        command: () => {
                            navigate('/currencies');
                            closeSideBar();
                        }
                    },
                    /*{
                        label: 'Маркетплейс',
                    }*/
                ]
            }
        );
    }

    const GetNavBarItems = () => {
        const items = [];
        IncludeDashboards(items);
        IncludeProductsAndServices(items);
        IncludeAccounting(items);
        IncludeSales(items);
        IncludeCounterAgents(items);
        IncludeBookkeeping(items);
        IncludeSettings(items);
        return items;
    }
  
    return <div className="card flex justify-content-left">
        <PanelMenu
            model={GetNavBarItems()}
            expandedKeys={expandedKeys} 
            onExpandedKeysChange={setExpandedKeys}
            pt={{
                root: { className: 'w-full' },
                headerAction: ({ context }) => ({ className: context.active ? 'bg-primary-200' : undefined }),
                action: ({ context }) => ({ className: context.active ? 'bg-primary-100' : undefined })
            }}
        />
    </div>
}

export default NavigationBar;