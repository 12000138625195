// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProductCreation_leftMenu__stD3D {
    padding-left: 3rem !important;
}

.ProductCreation_formInput__X9DBI {
    margin-top: 2rem;
    margin-bottom: 10px;
}

.ProductCreation_currencyDropDown__oeJhg{
    background-color: #f3f4f6;
    border: 0;
    padding: 0.1rem;
}

.ProductCreation_currencyDropDown__oeJhg span {
    font-size: 0.85rem;
}

.ProductCreation_currencyDropDown__oeJhg div {
    font-size: 2rem;
    width:20%
}

.ProductCreation_currencyDropDown__oeJhg svg{
    width: 12px;
    height: 12px
}

@media (min-width: 576px) {
   
  }
  
  @media (min-width: 768px) {
   
  }
  
  @media (min-width: 992px) {
   
  }
  
  @media (min-width: 1420px) {
   
  }
`, "",{"version":3,"sources":["webpack://./src/components/content/PageContent/ProductsAndServices/ProductCreation.module.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;AACjC;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,yBAAyB;IACzB,SAAS;IACT,eAAe;AACnB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf;AACJ;;AAEA;IACI,WAAW;IACX;AACJ;;AAEA;;EAEE;;EAEA;;EAEA;;EAEA;;EAEA;;EAEA;;EAEA","sourcesContent":[".leftMenu {\n    padding-left: 3rem !important;\n}\n\n.formInput {\n    margin-top: 2rem;\n    margin-bottom: 10px;\n}\n\n.currencyDropDown{\n    background-color: #f3f4f6;\n    border: 0;\n    padding: 0.1rem;\n}\n\n.currencyDropDown span {\n    font-size: 0.85rem;\n}\n\n.currencyDropDown div {\n    font-size: 2rem;\n    width:20%\n}\n\n.currencyDropDown svg{\n    width: 12px;\n    height: 12px\n}\n\n@media (min-width: 576px) {\n   \n  }\n  \n  @media (min-width: 768px) {\n   \n  }\n  \n  @media (min-width: 992px) {\n   \n  }\n  \n  @media (min-width: 1420px) {\n   \n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"leftMenu": `ProductCreation_leftMenu__stD3D`,
	"formInput": `ProductCreation_formInput__X9DBI`,
	"currencyDropDown": `ProductCreation_currencyDropDown__oeJhg`
};
export default ___CSS_LOADER_EXPORT___;
