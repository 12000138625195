import { useState } from "react";
import UserDetailsContext from "./userDetails-context";

const UserDetailsProvider = props => {

    const [userName, setUserName] = useState("Галя Балувана");
    const [position, setPosition] = useState("Продавець");
    const [image, setImage] = useState("balovana_h.png");
    const [roles, setRoles] = useState([]);

    const setUserDetailsHandler = userDetails =>{
        setUserName(userDetails.userName);
        setPosition(userDetails.position);
        setImage(userDetails.image);
        setRoles(userDetails.roles);
    }

    const userDetailsContext = {
        userName: userName,
        position: position,
        image: image,
        roles: roles,
        setUserDetails: setUserDetailsHandler
    }

    return <UserDetailsContext.Provider value={userDetailsContext}>
        {props.children}
    </UserDetailsContext.Provider>
}

export default UserDetailsProvider;