import React from "react";

const EnumsContext = React.createContext({
    product_groups: null,
    statuses: null,
    divisions: null,
    financial_data_types: null,
    cagent_types: null,
    partners: null,
    pay_statuses: null,
    service_groups: null,
    regions: null,
    clients: null,
    setProductGroups: (productGroups) => {},
    setStatuses: (statuses) => {},
    setDivisions:(divisions) => {},
    setFinDataTypes: (finDataTypes) => {},
    setCagentType: (cagentTypes) => {},
    setPartners: (partners) => {},
    setPayStatuses: (payStatuses) => {},
    setServiceGroups: (serviceGroups) => {},
    setRegions: (regions) => {},
    setClients: (client) => {}
});

export default EnumsContext;