export const paymentMethodTranslations = {
    "INCOME": "Прихід",
    "OUTCOME": "Розхід"
}

const paymentTypeTranslations = {
    "CASH": "Готівка",
    "CARD": "Термінал",
    "IBAN": "Рахунок"
}

export const paymentStatusTranslation = {
    "DISTRIBUTED" : "Розподілено",
    "NOT_DISTRIBUTED" : "Не розподілено",
    "PARTLY_DISTRIBUTED": "Частково розподілено"
}

export const transactionTypeTranslation = {
    "DEBIT": "Дебет",
    "CREDIT": "Кредит"
}

const translations = {
    "auth": "Користувачі та ролі",
    "cagents": "Контрагенти",
    "currency": "Валюта",
    "dashboards": "Дашборди",
    "divisions": "Підрозділи",
    "organizations": "Організації",
    "payments": "Платежі",
    "products": "Товари",
    "receive_acts":"Акти отримання",
    "remainings": "Залишки",
    "sales_acts": "Акти продажу",
    "services": "Послуги",
    "transactions": "Транзакції"
}

export const TranslatePaymentMethod = (name) => {
    return paymentMethodTranslations[name];
}

export const TranslatePaymentType = (name) => {
    return paymentTypeTranslations[name];
}

export const TranslatePaymentStatus = (name) => {
    return paymentStatusTranslation[name];
}

export const TranslateTransactionType = (name) => {
    return transactionTypeTranslation[name];
}

export const Translate = (name) => {
    return translations[name] === undefined ? name : translations[name]; 
}

export const UaLocale =
{
    startsWith: 'Починається з',
    contains: 'Містить',
    notContains: 'Не містить',
    endsWith: 'Закінчується на',
    equals: 'Рівно',
    notEquals: 'Не рівно',
    noFilter: 'Без фільтра',
    lt: 'Менше ніж',
    lte: 'Менше або рівно',
    gt: 'Більше ніж',
    gte: 'Більше або рівно',
    dateIs: 'Дата є',
    dateIsNot: 'Дата не є',
    dateBefore: 'Дата до',
    dateAfter: 'Дата після',
    clear: 'Очистити',
    apply: 'Застосувати',
    matchAll: 'Збігається з усім',
    matchAny: 'Збігається з будь-яким',
    addRule: 'Додати правило',
    removeRule: 'Видалити правило',
    accept: 'Так',
    reject: 'Ні',
    choose: 'Вибрати',
    upload: 'Завантажити',
    cancel: 'Скасувати',
    dayNames: [
        'Неділя', 'Понеділок', 'Вівторок', 'Середа', 'Четвер', 'П’ятниця', 'Субота'
    ],
    dayNamesShort: ['Нд', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
    monthNames: [
        'Січень', 'Лютий', 'Березень', 'Квітень', 'Травень', 'Червень',
        'Липень', 'Серпень', 'Вересень', 'Жовтень', 'Листопад', 'Грудень'
    ],
    monthNamesShort: ['Січ', 'Лют', 'Бер', 'Квіт', 'Трав', 'Чер', 'Лип', 'Серп', 'Вер', 'Жов', 'Лис', 'Гру']
}
