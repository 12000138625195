import { buildGroupHierarchy, formatDate, formatShortDate, getGroupCode } from '../../../Utils/Utils';
import { addLocale, locale } from 'primereact/api';
import EnumsContext from '../../../context/enums-context';
import ProductStatusCircle from '../../UIElements/ProductStatusCircle';
import { useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useApi from '../../../Utils/BackendClient';
import useGetEnums from '../../../Utils/EnumsUtils';


import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Column } from 'primereact/column';
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import { DataTable } from 'primereact/datatable';
import { InputSwitch } from 'primereact/inputswitch';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { TreeSelect } from 'primereact/treeselect';

import commonStyle from '../CommonStyles.module.css';
import { Chip } from 'primereact/chip';
import { UaLocale } from '../../../Utils/TranslationUtils';
import { Dropdown } from 'primereact/dropdown';


const ProductGroups = () => {
    const navigate = useNavigate();
    const location = useLocation();
    useGetEnums(["product_groups"]);
    const ctx = useContext(EnumsContext);
    const toast = useRef(null);
    const { changeProductGroupStatus, deleteProductGroup, fetchUserEnum, fetchProductsExtended, fetchProductGroups } = useApi();

    const [reloadData, setReloadData] = useState(false);
    const [productGroups, setProductGroups] = useState([]);
    const [loading, setLoading] = useState(true);
    const [onlyActive, setOnlyActive] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [sortedGroups, setSortedGroups] = useState([]);
    const [users, setUsers] = useState([]);
    const [tableFilters, setTableFilters] = useState(location.state?.productGroupPageState !== undefined ? location.state?.productGroupPageState?.filters :{
            created_at: { value: null, matchMode: 'equals' },
            root_group: { value: null, matchMode: 'equals' },
            group_name: { value: "", matchMode: 'equals' },
            created_by: { value: null, matchMode: 'equals'}
    });
    const [lazyState, setlazyState] = useState(location.state?.productGroupPageState !== undefined ? location.state?.productGroupPageState : {
        first: 0,
        rows: 10,
        page: 1,
        sortField: "created_at",
        sortOrder: 1,
        filters: {
            created_at: { value: null, matchMode: 'btw' },
            root_group: { value: null, matchMode: 'equals' },
            group_name: { value: "", matchMode: 'equals' },
            created_by: { value: null, matchMode: 'equals' }
        }
    });

    addLocale('ua', UaLocale);
    locale('ua');

    const dataFilterMatchModeOptions = [
        {
            label: "Між",
            value: "btw"
        }
    ]
    
    useEffect(() => {
        const fetchUsers = async () => {
            const response = await fetchUserEnum();

            if(response.status === 200){

                setUsers(response.data);
            }
        }

        fetchUsers();

    },[fetchUserEnum])

    useEffect(() => {
        if(location.state !== null)
        {
            if(location.state.toast !== undefined)
            {
                toast.current.show(location.state.toast);
            }

        }
    },[location.state]);

    useEffect(() => {
        const getProductGroups = async () => {
            const response = await fetchProductsExtended();

            if (response.status === 200) {
                const groups = buildGroupHierarchy(response.data);
                setSortedGroups(groups);
            }
            else {
                setSortedGroups([]);
            }
        }

        getProductGroups();
    }, [fetchProductsExtended]);

    useEffect(() => {

        const callBackend = async () => {

            const response = await fetchProductGroups(onlyActive, lazyState);

            if (response.status !== 200){
                toast.current.show({ severity: "error", summary: "Помилка завантаження списку", detail: "Помилка сервера " + response.status, life: 3000 });
                setTotalRecords(0);
                setProductGroups([]);
            }
            else
            {
                setTotalRecords(response.data.count);
                setProductGroups(response.data.results);
            }

            setLoading(false);
        }

        callBackend();

    }, [reloadData, lazyState, fetchProductGroups, onlyActive]);

    const ChangeProductGroupStatusHandler = (rowData) => {

        const action = rowData.is_active ? "deactivate" : "activate";

        const changeStatus = async () => {

            const response = await changeProductGroupStatus(rowData.id, action);

            if (response.status !== 200){ 
                toast.current.show({ severity: "error", summary: "Помилка зміни статусу", detail: "Помилка сервера " + response.status, life: 3000 });
            }
            else
            {
                toast.current.show({ severity: 'success', summary: rowData.is_active ? 'Деактивація' : "Активація", detail: rowData.is_active ? 'Групу товарів деактивовано' : "Групу товарів активовано", life: 3000 });
            }

            setReloadData(!reloadData);
        }

        changeStatus();
    }

    const DeleteButtonHandler = (rowData) => {

        const deleteData = async () => {

            const request = {
                ids: [rowData.id]
            }

            const response = await deleteProductGroup(request);

            if (response.status !== 204){ 
                toast.current.show({ severity: "error", summary: "Помилка видалення", detail: "Помилка сервера " + response.status, life: 3000 });
            }
            else
            {
                toast.current.show({ severity: "success", summary: 'Видалення', detail: 'Групу товарів успішно видалено', life: 3000 });
            }

            setReloadData(!reloadData);
        }

        deleteData();
    }

    const CreateGroupButtonHandler = () => {
        navigate('/productgroups/createproductgroup', { state: { productGroupPageState : lazyState }});
    }

    const EditButtonHandler = (rowData) => {
        const route = '/productgroups/editproductgroup/' + rowData.id;
        navigate(route, { state: { id: rowData.id,  productGroupPageState : lazyState} });
    }

    const confirmDelete = (event, rowData) => {
        confirmPopup({
            group: 'headless',
            target: event.currentTarget,
            message: 'Підтвердження видалення групи',
            icon: 'pi pi-exclamation-triangle',
            defaultFocus: 'accept',
            acceptLabel: "Так",
            rejectLabel: "Hi",
            accept: () => {DeleteButtonHandler(rowData)}
        });
    }

    const changeStatus = (event, rowData) => {
        confirmPopup({
            group: 'headless',
            target: event.currentTarget,
            message: rowData.is_active ? 'Підтвердження деактивації групи' : 'Підтвердження активації групи',
            icon: 'pi pi-exclamation-triangle',
            defaultFocus: 'accept',
            acceptLabel: "Так",
            rejectLabel: "Hi",
            accept: () => {ChangeProductGroupStatusHandler(rowData)}
        });
    }

    const onPage = (event) => {
        event.page = event.page +1;
        setlazyState(event);
    };

    const onSort = (event) => {
        event.page = lazyState.page;
        setlazyState(event);
    }

    const onFilter = (event) => {
        event.page = 1;
        setTableFilters(event.filters);
    }

    const filterApply = () => {
        const editedLazyState = lazyState;
        editedLazyState.filters = tableFilters;
        setlazyState(editedLazyState);
        setReloadData(!reloadData)
    }

    const clearFilter = (paramName, value) => {
        const editedLazyState = lazyState;
        editedLazyState.filters[paramName].value = value;
        setlazyState(editedLazyState);
        setReloadData(!reloadData);
    }

    const clearFilterFromChips = (paramName, value) => {
        const editedLazyState = lazyState;
        editedLazyState.filters[paramName].value = value;
        setlazyState(editedLazyState);
        onFilter(editedLazyState);
        setReloadData(!reloadData);
    }

    const rootGroupFilter = (options) => {
        return (
            <TreeSelect
            value={options.value}
            onChange={
                (e) => options.filterApplyCallback(e.value)
            }
            options={sortedGroups}
            />
        );
    };

    const dateRowFilterTemplate = (options) => {
        return (
            <Calendar 
                value={options.value} 
                onChange={
                    (e) => options.filterApplyCallback(e.value)
                }
                dateFormat="dd.mm.yy"
                selectionMode="range"
                readOnlyInput 
                hideOnRangeSelection 
            />
        );
    };

    const groupNameTemplate = (options) =>{
        return (
            <InputText
                value={options.value}
                onChange={
                    (e) => options.filterApplyCallback(e.target.value)
                }
            />
        );
    }

    const userFilterTemplate = (options) => {

        const userOptions = users.map(user => ({
            label: user.full_name,
            value: user.id
        }));

        return (
            <Dropdown
                value={options.value}
                onChange={
                    (e) => options.filterApplyCallback(e.target.value)
                }
                options={userOptions}
            />
        );
    }

    const formatFilterChips = () => {
        const element = [];
    
        Object.keys(lazyState.filters).forEach((filterKey) => {
            const filter = lazyState.filters[filterKey];
    
            if (filter.value && filter.value !== "") {

                let translatedKey = filterKey;
                let changedValue = filter.value;
                let removeValue = null;

                if(filterKey === "root_group"){
                    translatedKey = "Батьківська група";
                    changedValue = ctx.product_groups[getGroupCode(filter.value)];
                }
                else if(filterKey === "group_name"){
                    translatedKey = "Назва групи";
                    removeValue = "";
                }
                else if(filterKey === "created_at"){
                    translatedKey = "Дата";
                    changedValue = `з ${formatShortDate(filter.value[0])} до ${formatShortDate(filter.value[1] ?? filter.value[0])}`;
                }
                else if(filterKey === "created_by"){
                    translatedKey = "Користувач";
                    changedValue = users.find(x => x.id === changedValue)?.full_name ?? changedValue;
                }
                element.push(
                    <Chip 
                        key={filterKey}
                        label={`${translatedKey}: ${changedValue}`}
                        removable
                        onRemove={() => clearFilterFromChips(filterKey, removeValue)} // Optional: To handle removal
                    />
                );
            }
        });
    
        return <div className="flex flex-wrap gap-2">{element}</div>;
    }

    const OnlyActiveHandler = (event) => {
        setOnlyActive(event);
    }

    const tableHeader = (
        <div className="flex justify-content-between align-items-center w-full">
            {formatFilterChips()}
            <div className="flex justify-content-center align-items-center gap-2">
                <InputSwitch checked={onlyActive} onChange={(e) => OnlyActiveHandler(e.value)} />
                <span>Лише активні</span>
            </div>
        </div>
    );

    const toolbarRightTemplate = () => {
        return (<div className="flex flex-wrap gap-2">
            <Button label="Додати групу" severity="success" className={commonStyle.addButton} onClick={CreateGroupButtonHandler} />
        </div>)
    }

    const actionBodyTemplate = (rowData) => {
        return (<>
            <div className="flex flex-wrap gap-2">
                <Button
                    icon={rowData.is_active ? "pi pi-times" : "pi pi-check"}
                    className={`p-button-rounded ${rowData.is_active ? commonStyle.warningTag + " p-button-warning" : commonStyle.addButton + " p-button-success"}`}
                    tooltip={rowData.is_active ? "Деактивувати" : "Активувати"}
                    onClick={(event) => changeStatus(event, rowData)}
                />
                <Button
                    icon="pi pi-trash"
                    className={`p-button-rounded p-button-danger ${rowData.can_be_deleted ? commonStyle.deleteButton : commonStyle.closeButton}`}
                    tooltip={rowData.can_be_deleted ? "Видалити групу" : "Неможливо видалити, приєднані товари"}
                    tooltipOptions={{ showOnDisabled: true }}
                    disabled={!rowData.can_be_deleted}
                    onClick={(event) => confirmDelete(event, rowData)}
                />
                <Button
                    icon="pi pi-pencil"
                    className={`p-button-rounded p-button-info ${commonStyle.editButton}`}
                    tooltip="Редагувати групу"
                    onClick={() => EditButtonHandler(rowData)}
                />
            </div>
        </>);
    };

    const rootGroupTemplate = (rowData) => {
        return ctx.product_groups[rowData.root_group];
    }

    return (<>
        <Toast ref={toast} />
        <Toolbar className="mb-4" end={toolbarRightTemplate}></Toolbar>
        <div className='grid'>
            <div className='col-12 justify-content-center'>
                <DataTable value={productGroups} stripedRows lazy rows={lazyState.rows} paginator onPage={onPage} onSort={onSort} sortField={lazyState.sortField} sortOrder={lazyState.sortOrder}
                    first={lazyState.first} loading={loading} totalRecords={totalRecords} rowsPerPageOptions={[5, 10, 20]} header={tableHeader} tableStyle={{ minWidth: '60rem' }} dataKey="id" onFilter={onFilter} filters={tableFilters}>
                    <Column field="group_name" header="Назва групи" filter filterElement={groupNameTemplate} onFilterApplyClick={filterApply} onFilterClear={() => clearFilter("group_name", "")} showFilterMatchModes={false} style={{ width: '20%' }} sortable />
                    <Column field="root_group" header="Батьківська група" body={rootGroupTemplate} filter filterElement={rootGroupFilter} onFilterApplyClick={filterApply} onFilterClear={() => clearFilter("root_group", null)} showFilterMatchModes={false} style={{ width: '20%' }}sortable />
                    <Column field="created_at" dataType="date" header="Дата" body={(rowData => formatDate(rowData.created_at))} filter filterElement={dateRowFilterTemplate} onFilterApplyClick={filterApply} filterMatchModeOptions={dataFilterMatchModeOptions} onFilterClear={() => clearFilter("created_at", null)} style={{ width: '15%' }} sortable />
                    <Column field="created_by" header="Користувач" filter filterElement={userFilterTemplate} onFilterApplyClick={filterApply} onFilterClear={() => clearFilter("created_by", null)} showFilterMatchModes={false} style={{ width: '20%' }} />
                    <Column field="is_active" header="Статус" sortable body={rowData => <ProductStatusCircle rowData={rowData} type="statusCircle"/>} style={{ width: '10%' }} />
                    <Column field="action" body={actionBodyTemplate} style={{ width: '15%' }} />
                </DataTable>
            </div>
            <ConfirmPopup group="headless" />
        </div>
    </>);
}

export default ProductGroups;