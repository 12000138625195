import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import useAuthenticatedRequest from "../../../../Utils/TokenrRefreshUtils";

import commonStyle from "../../CommonStyles.module.css";


const UserRoles = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const toast = useRef(null);
    const authenticatedRequest = useAuthenticatedRequest();

    const [roles, setRoles] = useState([]);
    const [loading, setLoading] = useState(true);
    const [reloadData, setReloadData] = useState(true);
    const [totalRecords, setTotalRecords] = useState(0);

    const [lazyState, setlazyState] = useState({
        first: 0,
        rows: 10,
        page: 1,
        sortField: null,
        sortOrder: null,
    });

    useEffect(() => {
        if(location.state !== null && (location.state.toast !== undefined && location.state.toast !== null) ){
            toast.current.show(location.state.toast);
            location.state = null;
        }
    },[location]);

    useEffect(() => {

        const fetchData = async () => {

            const url = process.env.REACT_APP_BACKEND_URL + "roles/get_list/?page=" + lazyState.page + "&page_size=" + lazyState.rows;
            const options = {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json'
                }
            };

            const response = await authenticatedRequest(url, options);

            if (response.status !== 200){
                toast.current.show({ severity: "error", summary: "Помилка завантаження списку", detail: "Помилка сервера " + response.status, life: 3000 });
                setTotalRecords(0);
                setRoles([]);
            }
            else{
                const parsedResponse = await response.json();
                //logging to remove later
                console.log(parsedResponse);
    
                setTotalRecords(parsedResponse.count);
                setRoles(parsedResponse.results);
            }
            
            setLoading(false);
        }

        fetchData();

    }, [lazyState, authenticatedRequest, reloadData]);

    const DeleteButtonHandler = async (rowData) => {

        const url = process.env.REACT_APP_BACKEND_URL + "roles/delete/" + rowData.id + "/";
        const options = {
            method: "DELETE",
            headers: {
                'Content-Type': 'application/json'
            }
        };

        const response = await authenticatedRequest(url, options);

        if(response.status === 204){
            toast.current.show({ severity: "success", summary: "Успіх видалення", detail: "Роль " + rowData.name + " успішно видалено", life: 3000 });
            setReloadData(!reloadData);
        }
        else{
            if(response.status === 401){
                toast.current.show({ severity: "error", summary: "Помилка видалення", detail: "Користувач не авторизований", life: 3000 });
            }
            else if(response.status === 403){
                toast.current.show({ severity: "error", summary: "Помилка видалення", detail: "Користувач не має прав видалити роль", life: 3000 });
            }
            else if(response.status === 404){
                toast.current.show({ severity: "error", summary: "Помилка видалення", detail: "Роль не знайдено", life: 3000 });
            }
            else{
                toast.current.show({ severity: "error", summary: "Помилка видалення", detail: "Неочікувана помилка, код помилки: " + response.status, life: 3000 });
            }
        }
    }

    const EditButtonHandler = async (rowData) =>{
        const route = '/roles/edituserrole/' + rowData.id;
        navigate(route, { state: { id: rowData.id } });
    }

    const onPage = (event) => {
        event.page = event.page +1;
        setlazyState(event);
    };

    const CreateNewRoleButtonHandler = () => {
        navigate('/roles/newuserrole');
    };

    const toolbarRightTemplate = () => {
        return (<div className="flex flex-wrap gap-2">
            <Button label="Додати роль" className={commonStyle.addButton} severity="info" onClick={CreateNewRoleButtonHandler} />
        </div>)
    }

    const actionBodyTemplate = rowData => {
        return (<div className="flex flex-wrap gap-2">
            <Button
                icon="pi pi-trash"
                className={`p-button-rounded p-button-danger ${commonStyle.deleteButton}`}
                tooltip="Видалити"
                onClick={() => DeleteButtonHandler(rowData)}
            />
            <Button
                icon="pi pi-pencil"
                className={`p-button-rounded p-button-info ${commonStyle.editButton}`}
                onClick={() => EditButtonHandler(rowData)}
            />
        </div>
        );
    };

    return (<>
        <Toast ref={toast}/>
        <Toolbar className="mb-4" end={toolbarRightTemplate} />
        <div className='grid'>
            <div className='col-12 justify-content-center'>
                <DataTable value={roles} stripedRows lazy rows={lazyState.rows} paginator onPage={onPage} first={lazyState.first} loading={loading} totalRecords={totalRecords} rowsPerPageOptions={[10, 20, 50]} tableStyle={{ minWidth: '60rem' }} >
                    <Column field="name" header="Назва ролі" style={{ width: '80%' }} sortable></Column>
                    <Column field="action" body={actionBodyTemplate} style={{ width: '20%' }} />
                </DataTable>
            </div>
        </div>
    </>);
}

export default UserRoles;