import { useCallback } from 'react';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';

const useAuthenticatedRequest = () => {
    const navigate = useNavigate();

    const refreshToken = async () => {
        const response = await fetch(process.env.REACT_APP_BACKEND_URL + "auth/token/refresh/", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ refresh: Cookies.get('refreshToken') })
        });

        const parsedResponse = await response.json();

        console.log(parsedResponse);

        if (response.status === 200) {
            console.log("Correct token");
            Cookies.set('authToken', parsedResponse.access);
            return parsedResponse.access;
        } else {
            console.log("Incorrect token");
            Cookies.remove('authName');
            Cookies.remove('authToken');
            Cookies.remove('refreshToken');

            return "error";
        }
    };

    const authenticatedRequest = useCallback(async (url, options) => {
        const executeRequest = async () => {
            const response = await fetch(url, {
                ...options,
                headers: {
                    ...options.headers,
                    "Authorization": "Bearer " + Cookies.get('authToken')
                }
            });
            return response;
        };

        let response = await executeRequest();

        if (response.status === 401) { // assuming 401 status code for token expiration

            console.log("Token refreshed");
            const refreshresponse = await refreshToken();

            if (refreshresponse === "error") {
                navigate(0);
            } else {
                response = await executeRequest();
            }
        }

        return response;
    }, [navigate]);

    return authenticatedRequest;
};

export default useAuthenticatedRequest;