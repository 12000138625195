import { createContext, useState } from "react";
import useApi from "../Utils/BackendClient";

const UserPermissionContext = createContext();

const UserPermissionProvider = ({ children }) => {
    const [permissions, setPermissions] = useState(null);
    const { fetchUserPermissions } = useApi();
    
    const loadPermissions = async (id) => {
        
        const response = await fetchUserPermissions(id);

        if (response.status === 200) {
            setPermissions(response.data.permissions);
        }
    };

    const value = {
        permissions,
        loadPermissions
    };

    return (
        <UserPermissionContext.Provider value={value}>
            {children}
        </UserPermissionContext.Provider>
    );
}

export { UserPermissionProvider, UserPermissionContext };