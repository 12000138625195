// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.p-password-input{
    width: 100%;
  }
  .p-icon-field{
    width:100%
  }
  body {
    overflow-x: hidden;
  }`, "",{"version":3,"sources":["webpack://./src/components/Authorization/Styles.css"],"names":[],"mappings":"AAAA;IACI,WAAW;EACb;EACA;IACE;EACF;EACA;IACE,kBAAkB;EACpB","sourcesContent":[".p-password-input{\n    width: 100%;\n  }\n  .p-icon-field{\n    width:100%\n  }\n  body {\n    overflow-x: hidden;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
