import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Tag } from "primereact/tag";

const Writeoff = () => {

    const writeOffProducts = [{
        id: 112,
        date: "2015-09-13",
        unit: "Основний склад",
        status: "Закритий",
        user: "Марян Коць"
    },
    {
        id: 113,
        date: "2019-02-09",
        unit: "Основний склад",
        status: "Закритий",
        user: "Марян Коць"
    },
    {
        id: 114,
        date: "2015-09-13",
        unit: "Основний склад",
        status: "Закритий",
        user: "Марян Коць"
    },
    {
        id: 115,
        date: "2017-05-13",
        unit: "Основний склад",
        status: "Драфт",
        user: "Марян Коць"
    },
    {
        id: 116,
        date: "2017-05-13",
        unit: "Основний склад",
        status: "На узгодженні",
        user: "Марян Коць"
    },
    {
        id: 117,
        date: "2017-05-13",
        unit: "Магазин Кам'янка",
        status: "Очікує оплату",
        user: "Марян Коць"
    }
    ];

    const [selectedProduct, setSelectedProduct] = useState(null);
    const navigate = useNavigate();

    const CreateNewWriteOffButtonHandler = () => {
        navigate("/writeoff/newwriteoff");
    }

    const getStatusSeverity = type => {
        switch (type) {
            case "Закритий":
                return "success";
            case "Очікує оплату":
                return "danger";
            case "Драфт":
                return "info";
            case "На узгодженні":
                return "warning";
            default:
                return "info";
        }
    }

    const statusBodyTemplate = rawElement => {
        const severity = getStatusSeverity(rawElement.status);
        return (<Tag value={rawElement.status} severity={severity} />)
    }

    const toolbarRightTemplate = () => {
        return (<div className="flex flex-wrap gap-2">
            <Button label="Додати aкт списання" severity="info" onClick={CreateNewWriteOffButtonHandler} />
        </div>)
    }

    return (<>
        <Toolbar className="mb-4" end={toolbarRightTemplate}></Toolbar>
        <div className='grid'>
            <div className='col-11 justify-content-center'>
                <DataTable value={writeOffProducts} selection={selectedProduct} onSelectionChange={(e) => setSelectedProduct(e.value)} rows={10} tableStyle={{ minWidth: '60rem' }} >
                    <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                    <Column field="id" header="№" sortable></Column>
                    <Column field="date" dataType="date" header="Дата акту" ></Column>
                    <Column field="unit" header="Підрозділ" sortable></Column>
                    <Column field="status" header="Статус" sortable body={statusBodyTemplate}></Column>
                    <Column field="user" header="Менеджер" sortable></Column>
                </DataTable>
            </div>
        </div>
    </>);
}

export default Writeoff;