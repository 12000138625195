import React, { useContext, useState } from "react";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";

import classes from "./Authorization.module.css";
import "./Styles.css";
import RCTLogo from "../../images/RCTLogo.png";
import { UserPermissionContext } from "../../context/UserPermissionProvider";

import { Button } from "primereact/button";
import { Password } from 'primereact/password';
import { InputText } from "primereact/inputtext";

const Authorization = props => {
    
    const { loadPermissions} = useContext(UserPermissionContext);
    
    const [userLogin, setUserLogin] = useState("");
    const [userPassword, setUserPassword] = useState("");
    const [isError, setIsError] = useState(false);
    const [errorName, setErrorName] = useState("");

    const LoginUserHandler = async event => {
        event.preventDefault();

        if (userLogin === "" || userPassword === "") {

        }
        else {
            try {
                const response = await fetch(process.env.REACT_APP_BACKEND_URL + "auth/token/", {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'X-APP-ID': "ADMIN_PANEL"
                    },
                    body: JSON.stringify({ username: userLogin, password: userPassword })
                });

                if (response.status === 200) {
                    const content = await response.json();

                    const decodedToken = jwtDecode(content.access);

                    Cookies.set("division", decodedToken.division_id);
                    Cookies.set("userId", decodedToken.user_id);

                    const LoginData = {
                        userName: decodedToken.username,
                        accessToken: content.access,
                        refreshToken: content.refresh,
                        avatar: decodedToken.logo,
                    }

                    await loadPermissions(decodedToken.user_id);
                    
                    console.log(LoginData);

                    props.onLoginProcess(LoginData);
                }
                else {
                    setIsError(true);
                    setErrorName("Неправильний логін/пароль");
                }
            }
            catch (error) {
                setIsError(true);
                console.log(error);
            }
            finally {
                setUserLogin("");
                setUserPassword("");
            }
        }
    };

    return (<div className={`grid ${classes.backgroundMain}`}>
        <div className={`col-12 lg:col-4 lg:col-offset-4 sm:col-8 sm:col-offset-2 flex flex-column align-items-center justify-content-center ${classes["login-container"]}`}>
            <div className="align-content-center">
                <h1 className={`${classes["logo-badge"]} ${classes["text-whitesmoke"]}`}><img className="d-inline-block align-text-top" width={175} height={175} src={RCTLogo} alt="RCTLogo" /></h1>
            </div>
            <h2 className={`${classes["text-whitesmoke"]} mt-0`}>RCT CRM System</h2>
            <div className={`${classes["container-content"]} flex flex-column`}>
                <form className="mt-5" onSubmit={LoginUserHandler}>
                    <InputText className="mb-1 w-full" value={userLogin} placeholder="Логін" onChange={(e) => setUserLogin(e.target.value)}/>
                    <Password value={userPassword} className="w-full mb-1" placeholder="Пароль" feedback={false} onChange={(e) => setUserPassword(e.target.value)} toggleMask />
                    <Button type="submit" className={`${classes["form-button"]} mb-3 justify-content-center w-full`}>Увійти</Button>

                    {isError && <p className={`${classes["text-error"]} text-center`}><small>{errorName}</small></p>}
                </form>
                <p className={`${classes["text-whitesmoke"]} text-center`}><small>&copy; 2024-2025 Red Cat Tech. Версія системи 1.11.0</small> </p>
            </div>
        </div>
    </div>
    )
}

export default Authorization;