import EnumsContext from "../../../../context/enums-context";
import useApi from "../../../../Utils/BackendClient";
import useGetEnums from "../../../../Utils/EnumsUtils";
import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserPermissionContext } from "../../../../context/UserPermissionProvider";

import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup";
import { DataTable } from "primereact/datatable";
import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";

import commonStyle from "../../CommonStyles.module.css";

const Clients = () => {

    const navigate = useNavigate();
    const toast = useRef(null);
    const ctx = useContext(EnumsContext);
    const permCtx = useContext(UserPermissionContext)
    const { deleteCagent, fetchClients } = useApi();

    const [clients, setClients] = useState([]);
    const [loading, setLoading] = useState(true);
    const [reloadData, setReloadData] = useState(true);

    const [totalRecords, setTotalRecords] = useState(0);

    const [lazyState, setlazyState] = useState({
        first: 0,
        rows: 10,
        page: 1,
        sortField: null,
        sortOrder: null,
    });

    useGetEnums(["cagent_types"]);

    useEffect(() => {
        const fetchData = async () => {

            const response = await fetchClients(lazyState);

            if (response.status === 200) {
                setTotalRecords(response.data.count);
                setClients(response.data.results);
            }
            else{
                setTotalRecords(0);
                setClients([]);
                if(response.status === 403){
                    toast.current.show({ severity: "error", summary: "Помилка завантаження списку клієнтів", detail: "Користувач не має права переглядати сторінку", life: 3000 });
                }
                else if(response.status === 401){
                    toast.current.show({ severity: "error", summary: "Помилка завантаження списку клієнтів", detail: "Авторизуйтесь повторно на сайт", life: 3000 });
                }
                else{
                    toast.current.show({ severity: "error", summary: "Помилка завантаження списку клієнтів", detail: "Помилка сервера " + response.status, life: 3000 });
                }
            }

            setLoading(false);
        }

        fetchData();

    }, [reloadData, lazyState, fetchClients]);

    const DeleteButtonHandler = (rowData) => {
        const deleteData = async () => {

            const response = await deleteCagent(rowData.id);

            if (response.status !== 204){ 
                toast.current.show({ severity: "error", summary: "Помилка видалення", detail: "Помилка сервера " + response.status, life: 3000 });
            }
            else
            {
                toast.current.show({ severity: "success", summary: 'Видалення', detail: 'Клієнта успішно видалено', life: 3000 });
            }

            setReloadData(!reloadData);
        }

        deleteData();
    }

    const NewCounterAgentButtonHandler = () => {
        navigate('/clients/newcounteragent', { state: { isClient: true } });
    }

    const EditClientButtonHandler = rowData => {
        const route = '/clients/editcounteragent/' + rowData.id;
        navigate(route, { state: { isClient: true, id: rowData.id } });
    }

    const onPage = (event) => {
        event.page = event.page + 1;
        setlazyState(event);
    };

    const confirmDelete = (event, rowData) => {
        confirmPopup({
            group: 'headless',
            target: event.currentTarget,
            message: 'Підтвердіть видалення клієнта',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: "Так",
            rejectLabel: "Hi",
            defaultFocus: 'accept',
            accept: () => {DeleteButtonHandler(rowData)}
        });
    }

    const toolbarRightTemplate = () => {
        return (<div className="flex flex-wrap gap-2">
            {permCtx.permissions?.includes("cagents.add_cagent_client") && <Button label="Додати клієнта" severity="success" className={commonStyle.addButton} onClick={NewCounterAgentButtonHandler} />}
        </div>)
    }

    const cagentTypeTemplate = (rowData) => {
        return ctx.cagent_types[rowData.type];
    }

    const actionBodyTemplate = (rowData) => {
        return (<div className="flex flex-wrap gap-2">
            {permCtx.permissions?.includes("cagents.delete_cagent_client") && <Button
                icon="pi pi-trash"
                className={`p-button-rounded p-button-danger ${rowData.can_be_deleted ? commonStyle.deleteButton : commonStyle.closeButton}`}
                tooltip={rowData.can_be_deleted ? "Видалити клієнта" : "Неможливо видалити"}
                tooltipOptions={{ showOnDisabled: true }}
                disabled={!rowData.can_be_deleted}
                onClick={(event) => confirmDelete(event, rowData)}
            />}
            {permCtx.permissions?.includes("cagents.view_cagent_client") && <Button
                icon="pi pi-pencil"
                className={`p-button-rounded p-button-info ${commonStyle.editButton}`}
                tooltip="Редагувати клієнта"
                onClick={() => EditClientButtonHandler(rowData)}
            />}
        </div>
        );
    };

    return (<>
        <Toast ref={toast} />
        <Toolbar className="mb-4" end={toolbarRightTemplate}></Toolbar>
        <div className='grid'>
            <div className='col-12 justify-content-center'>
                <DataTable value={clients} stripedRows lazy rows={lazyState.rows} paginator onPage={onPage}
                    first={lazyState.first} loading={loading} totalRecords={totalRecords} rowsPerPageOptions={[10, 20, 50]} tableStyle={{ minWidth: '60rem' }} >
                    <Column field="name" header="Назва клієнта" />
                    <Column field="type" header="Тип" body={cagentTypeTemplate} />
                    <Column field="phone" header="Телефон" />
                    <Column field="email" header="Email" />
                    <Column field="created_by" header="Користувач" />
                    <Column field="action" body={actionBodyTemplate} />
                </DataTable>
            </div>
            <ConfirmPopup group="headless" />
        </div>
    </>)
}

export default Clients;