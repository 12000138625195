import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";

import Cookies from "js-cookie";

import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Tag } from "primereact/tag";
import { Toolbar } from "primereact/toolbar";

import classes from "../../CommonStyles.module.css";

const NewWriteOff = () => {

    const storages = ["Основний склад", "Магазин Кам'янка"];
    const statuses = ['Драфт', 'На узгодженні', 'Очікує оплату', 'Закритий']

    const [expandedRows, setExpandedRows] = useState(null);

    const navigate = useNavigate();

    const ReturnButtonHandler = () => {
        navigate("/writeoff");
    }

    const formik = useFormik({
        initialValues: {
            storage: null,
            documentNumber: '',
            documentDate: null,
            status: null,
            products: [{
                productName: "IPhone 12 256Mb",
                groupName: "Мобільні телефони",
                id: 12345,
                imea: "JHJKHJKH32553456234",
                price: 600,
                priceUAH: 1800
            },
            {
                productName: "IPhone 13 256Mb",
                groupName: "Мобільні телефони",
                id: 12345,
                imea: "JHJKHJKH32553456234",
                price: 900,
                priceUAH: 27000
            },
            {
                productName: "IPad Air 256Mb",
                groupName: "Планшети",
                id: 12345,
                imea: "JHJKHJKH32553456234",
                price: 750,
                priceUAH: 25000
            }
            ],
            authToken: Cookies.get("authToken"),
            date: null,
        },
        onSubmit: (values) => {
            let currentdate = new Date();
            values.date = currentdate.getDate() + "/" + (currentdate.getMonth() + 1) + "/" + currentdate.getFullYear();
            console.log(values);
            navigate("/writeoff", { state: { values } });
        }
    });

    const AddProductHandler = () => {
    }

    const getStatusSeverity = type => {
        switch (type) {
            case "Закритий":
                return "success";
            case "Очікує оплату":
                return "danger";
            case "Драфт":
                return "info";
            case "На узгодженні":
                return "warning";
            default:
                return "info";
        }
    }

    const statusBodyTemplate = status => {
        const severity = getStatusSeverity(status);
        return (<Tag value={status} severity={severity} />)
    }


    const mainToolbarLeftTemplate = () => {
        return (<h3>Новий акт списання товарів</h3>);
    }

    const mainToolbarRightTemplate = () => {
        return (
            <div className="flex flex-wrap gap-2">
                <Button label="Зберегти" severity="success" type="submit" />
                <Button label="Вийти" severity="secondary" type="button" onClick={ReturnButtonHandler} />
            </div>
        );
    }

    const contentToolbarRightTemplate = () => {
        return (<>
            <div className="flex flex-wrap gap-2">
                <Button label="+Додати товар" severity="success" onClick={AddProductHandler} />
            </div>
        </>);
    }

    const headerTemplate = (data) => {
        return (
            <span className="vertical-align-middle ml-2 font-bold line-height-3">{data.groupName}</span>
        );
    };

    const footerTemplate = () => {
        return (
            <td colSpan={5}>
                <div className="flex justify-content-end font-bold w-full">Разом: !!!!Додати суму</div>
            </td>
        );
    };

    return (<form onSubmit={formik.handleSubmit}>
        <Toolbar className="mb-4" start={mainToolbarLeftTemplate} end={mainToolbarRightTemplate}></Toolbar>
        <div className="grid">
            <div className={`${classes.leftMenu} col-3`}>
                <div className={classes.formInput}>
                    <div className="p-inputgroup flex-1">
                        <span className="p-float-label w-full md:w-22rem">
                            <Dropdown value={formik.values.storage} onChange={(e) => formik.setFieldValue('storage', e.value)} options={storages} showClear className="w-full" />
                            <label>Підрозділ</label>
                        </span>
                    </div>
                </div>
                <div className={classes.formInput}>
                    <div className="p-inputgroup flex-1">
                        <span className="p-float-label w-full md:w-22rem">
                            <InputText value={formik.values.documentNumber} onChange={(e) => { formik.setFieldValue('documentNumber', e.target.value) }} />
                            <label>Номер акту</label>
                        </span>
                    </div>
                </div>
                <div className={classes.formInput}>
                    <div className="p-inputgroup flex-1">
                        <span className="p-float-label w-full md:w-22rem">
                            <Calendar value={formik.values.documentDate} onChange={(e) => { formik.setFieldValue('documentDate', e.target.value) }} showIcon />
                            <label>Дата акту</label>
                        </span>
                    </div>
                </div>
                <div className={classes.formInput}>
                    <div className="p-inputgroup flex-1">
                        <span className="p-float-label w-full md:w-22rem">
                            <Dropdown value={formik.values.status} onChange={(e) => formik.setFieldValue('status', e.value)} options={statuses}
                                showClear className="w-full" valueTemplate={statusBodyTemplate} itemTemplate={statusBodyTemplate} />
                            <label>Статус</label>
                        </span>
                    </div>
                </div>
            </div>
            <div className="col-8">
                <Toolbar className="mb-4" end={contentToolbarRightTemplate}></Toolbar>
                <DataTable value={formik.values.products} rowGroupMode="subheader" groupRowsBy="groupName"
                    sortMode="single" sortField="groupName" sortOrder={1}
                    expandableRowGroups expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)}
                    rowGroupHeaderTemplate={headerTemplate} rowGroupFooterTemplate={footerTemplate} tableStyle={{ minWidth: '50rem' }}>
                    <Column field="productName" header="Продукт" style={{ width: '30%' }}></Column>
                    <Column field="id" header="Артикул" style={{ width: '30%' }}></Column>
                    <Column field="imea" header="IMEA" style={{ width: '40%' }}></Column>
                </DataTable>
            </div>
        </div>
    </form>);
}

export default NewWriteOff;