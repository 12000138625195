import React from "react";

const UserDetailsContext = React.createContext({
    userName: "",
    position: "",
    image: "",
    roles: [],
    setUserDetails: (userDetails) => {},
});

export default UserDetailsContext;