import { useContext, useEffect } from "react";
import EnumsContext from "../context/enums-context";
import useAuthenticatedRequest from "./TokenrRefreshUtils";

const useGetEnums = (types) => {
    const ctx = useContext(EnumsContext);
    const authenticatedRequest = useAuthenticatedRequest();

    useEffect(() => {
        const fetchEnums = async (type) => {

            const url = process.env.REACT_APP_BACKEND_URL + "enums/" + type + "/";
            const options = {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json'
                }
            };

            const response = await authenticatedRequest(url, options);

            return response;
        };

        // name value
        const convertGenericEnums = (enums) => {
            return enums.reduce((acc, obj) => {
                acc[obj.name] = obj.value;
                return acc;
            }, {});
        };

        // id name
        const convertIdEnums = (enums) => {
            return enums.reduce((acc, obj) => {
                acc[obj.id] = obj.name;
                return acc;
            }, {});
        };

        // id group_name
        const convertProductGroups = (enums) => {
            return enums.reduce((acc, obj) => {
                acc[obj.id] = obj.group_name;
                return acc;
            }, {});
        };

        // hook itself
        const fetchAndSetEnums = async () => {

            for (const type of types) {
                if (type !== null && ctx[type] !== null && ctx[type].length === 0) {
                    const response = await fetchEnums(type);
                    if (response.status !== 200) {
                        ctx[type] = [];
                    }
                    else {
                        const convertedResponse = await response.json();
                        switch (type) {
                            case "product_groups":
                                const formattedProductGroups = convertProductGroups(convertedResponse);
                                ctx.setProductGroups(formattedProductGroups);
                                break;
                            case "currency":
                                const formattedCurrencies = convertGenericEnums(convertedResponse);
                                ctx.setCurrency(formattedCurrencies);
                                break;
                            case "divisions":
                                const formattedDivisions = convertIdEnums(convertedResponse);
                                ctx.setDivisions(formattedDivisions);
                                break;
                            case "financial_data_types":
                                const formattedFinDataTypes = convertGenericEnums(convertedResponse);
                                ctx.setFinDataTypes(formattedFinDataTypes);
                                break;
                            case "cagent_types":
                                const formattedCagentypes = convertGenericEnums(convertedResponse);
                                ctx.setCagentType(formattedCagentypes);
                                break;
                            case "partners":
                                const formattedPartners = convertIdEnums(convertedResponse);
                                ctx.setPartners(formattedPartners);
                                break;
                            case "pay_statuses":
                                const formattedPayStatuses = convertGenericEnums(convertedResponse);
                                ctx.setPayStatuses(formattedPayStatuses);
                                break;
                            case "service_groups":
                                const formattedServiceGroups = convertProductGroups(convertedResponse);
                                ctx.setServiceGroups(formattedServiceGroups);
                                break;
                            case "regions":
                                const formattedRegions = convertGenericEnums(convertedResponse);
                                ctx.setRegions(formattedRegions);
                                break;
                            case "clients":
                                const formattedClients = convertIdEnums(convertedResponse);
                                ctx.setClients(formattedClients);
                                break;
                            default:
                                break;
                        }
                    }
                }
            }
        };

        fetchAndSetEnums();
    }, [types, ctx, authenticatedRequest]);
};

export default useGetEnums;