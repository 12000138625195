import useApi from "../../../../Utils/BackendClient";
import { useContext, useState, useEffect, useRef } from "react";
import { useFormik } from "formik";
import useGetEnums from "../../../../Utils/EnumsUtils";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { Guid } from 'js-guid';

import EnumsContext from "../../../../context/enums-context";
import { convertSumWithLatestCurrencyRate, formatCurrency, findKeyByValue, buildGroupHierarchy } from "../../../../Utils/Utils";

import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { FloatLabel } from "primereact/floatlabel";
import { InputText } from "primereact/inputtext";
import { Sidebar } from "primereact/sidebar";
import { TabPanel, TabView } from "primereact/tabview";
import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";
import { TreeSelect } from "primereact/treeselect";

import commonStyle from "../../CommonStyles.module.css";

const NewProductsReception = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const toast = useRef(null);
    const ctx = useContext(EnumsContext);
    const { createProduct, createReceptionAct, fetchActPayments, fetchProducts, fetchProductsExtended, fetchReceptionActDetails, deletePaymentFromAct, updateReceptionAct } = useApi();

    const currencies = ["UAH", "USD", "EUR"];

    const [products, setProducts] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [loading, setLoading] = useState(false);
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [sortedGroups, setSortedGroups] = useState([]);
    const [visible, setVisible] = useState(false);
    const [productSideBarVisible, setProductSideBarVisible] = useState(false);
    const [showproducts, setShowProducts] = useState(false);
    const [showfields, setShowFields] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isFinalized, setIsFinalized] = useState(false);
    const [totaLActSum, setTotalActSum] = useState(0);
    const [reloadData, setReloadData] = useState(false);
    const [actPayments, setActPayments] = useState(null);
    const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 768);
    const [lazyState, setlazyState] = useState(
        {
            first: 0,
            rows: 20,
            page: 1,
            sortField: "created_at",
            sortOrder: 1,
            filters: {
                product_group: { value: null, matchMode: 'contains' },
            }
        }
    );
    const [actData, setActData] = useState({
        cagent: null,
        division: ctx.divisions[Cookies.get("division")],
        act_number_autogenerate: true,
        act_number: '',
        act_date: new Date(),
        pay_due_date: new Date(),
        status: "Драфт",
        pay_status: "Не оплачено",
        identified_products: [],
        unidentified_products: [],
    });

    useGetEnums(["partners", "divisions", "pay_statuses", "product_groups"]);

    useEffect(() => {
        const handleResize = () => {
            setIsLargeScreen(window.innerWidth >= 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const getProductGroups = async () => {
            const response = await fetchProductsExtended();

            if (response.status === 200) {
                const groups = buildGroupHierarchy(response.data);
                setSortedGroups(groups);
            }
            else {
                setSortedGroups([]);
            }
        }

        getProductGroups();
    }, [fetchProductsExtended]);

    useEffect(() => {
        if (location.state !== null) {

            setIsEdit(true);

            const fetchData = async () => {

                const response = await fetchReceptionActDetails(location.state.id);

                if (response.status === 200) {

                    setIsFinalized(response.data.status !== "PENDING");
                    response.data.act_date = new Date(response.data.act_date);
                    response.data.pay_due_date = new Date(response.data.pay_due_date);

                    response.data.identified_products.forEach(element => {
                        element.product_name = element.product;
                        element.product = element.product_id;
                    });

                    response.data.unidentified_products.forEach(element => {
                        element.product_name = element.product;
                        element.product = element.product_id;
                    });

                    let totalValue = 0
                    if (response.data.identified_products.length > 0) {
                        totalValue = totalValue + response.data.identified_products.reduce((accumulator, element) => accumulator + element.priceUAH, 0);
                    }
                    if (response.data.unidentified_products.length > 0) {
                        totalValue = totalValue + response.data.unidentified_products.reduce((accumulator, element) => accumulator + element.priceUAH * element.quantity, 0);
                    }

                    if (response.data.status === "FINALIZED") {
                        const payments = await fetchActPayments(location.state.id);
                        console.log(payments);
                        setActPayments(payments.data.results);
                    }

                    setTotalActSum(totalValue);
                    setActData(response.data);
                }
            }

            fetchData();
        }
    }, [location.state, fetchActPayments, fetchReceptionActDetails]);

    const formik = useFormik({
        initialValues: actData,
        enableReinitialize: true,
        validate: values => {
            const errors = {};

            if (values.cagent === null || values.cagent === undefined) {
                errors.cagent = "Виберіть контрагента";
            }

            if (values.division === null || values.division === undefined) {
                errors.division = "Виберіть підрозділ";
            }

            if (!values.act_number_autogenerate) {
                if (values.act_number === null || values.act_number === "") {
                    errors.act_number = "Введіть номер акту";
                }
            }
            return errors;
        },
        onSubmit: (values) => {

            function transformDate(date) {
                const day = ('0' + date.getDate()).slice(-2); // Add leading zero if needed
                const month = ('0' + (date.getMonth() + 1)).slice(-2); // Month is zero-based, so add 1
                const year = date.getFullYear();

                const formattedDate = `${year}-${month}-${day}`;

                return formattedDate
            }

            function transformProducts(products) {
                return products.map(product => {
                    delete product.fe_id;
                    delete product.product_name;
                    return product;
                });
            }

            values.identified_products = transformProducts(values.identified_products);
            values.unidentified_products = transformProducts(values.unidentified_products);
            values.act_date = transformDate(values.act_date);
            if (values.act_number_autogenerate) {
                delete values.act_number;
            }
            values.pay_due_date = transformDate(values.pay_due_date);
            console.log(values);

            const saveChanges = async () => {

                const response = isEdit ? await updateReceptionAct(location.state.id, values) : await createReceptionAct(values);

                let toastMessage = {
                    severity: "error", summary: isEdit ? "Помилка редагування" : "Помилка створення",
                    detail: "Акт не " + (isEdit ? "відредаговано. " : " створено. ") + " Код помилки: " + response.status, life: 3000
                };

                console.log("Response status", response.status)

                if (isEdit && response.status === 200) {
                    toastMessage = { severity: "success", summary: "Успіх редагування", detail: "Акт успішно відредаговано", life: 3000 }
                }
                else if (!isEdit && response.status === 201) {
                    toastMessage = { severity: "success", summary: "Успіх створення", detail: "Акт успішно створено", life: 3000 }
                }

                navigate("/productsreception", { state: { toast: toastMessage } });
            }

            saveChanges();
        }
    });

    const sideBarFormik = useFormik({
        initialValues: {
            type: '',
            product: "",
            product_id: "",
            identifier: "",
            quantity: "",
            note: "",
            price: "",
            priceUAH: "",
            currency: "UAH",
            use_product_sale_price: false,
            custom_sale_price: "",
            custom_sale_price_currency: "UAH"
        },
        enableReinitialize: true,
        validate: values => {
            const errors = {};

            if (values.type === "INDIVIDUAL" && (values.identifier === null || values.identifier === "")) {
                errors.identifier = "Введіть ідентифікатор";
            }
            if (values.type !== "INDIVIDUAL" && (values.quantity === null || values.quantity === "")) {
                errors.quantity = "Введіть кількість товару";
            }

            if (values.currency === null || values.currency === undefined) {
                errors.currency = "Виберіть валюту"
            }

            if (values.price === null || values.price === "") {
                errors.price = "Введіть вартість в валюті акту";
            }

            if (values.currency !== "UAH" && (values.priceUAH === null || values.priceUAH === "")) {
                errors.priceUAH = "Введіть вартість в гривні";
            }

            if (!values.use_product_sale_price) {

                if (values.custom_sale_price === null || values.custom_sale_price === "") {
                    errors.salePrice = "Введіть вартість продажу"
                }
                if (values.custom_sale_price_currency === undefined || values.custom_sale_price_currency === "") {
                    errors.custom_sale_price_currency = "Виберіть валюту продажу"
                }
            }

            return errors;
        },
        onSubmit: (values) => {

            let newValue = {
                fe_id: Guid.newGuid().toString(),
                product_id: values.product_id,
                product_name: values.product,
                note: values.note,
                price: +values.price,
                priceUAH: +values.priceUAH,
                currency: values.currency,
                use_product_sale_price: values.use_product_sale_price,
                custom_sale_price: +values.custom_sale_price,
                custom_sale_price_currency: values.custom_sale_price_currency
            };

            console.log(newValue);

            if (values.type === "INDIVIDUAL") {
                newValue.identifier = values.identifier;
                setTotalActSum(totaLActSum + +values.priceUAH);
                const updatedIdentifiedProducts = [...formik.values.identified_products, newValue];
                formik.setFieldValue('identified_products', updatedIdentifiedProducts);
            } else {
                newValue.quantity = values.quantity;
                setTotalActSum(totaLActSum + (values.priceUAH * newValue.quantity));
                const updatedUnIdentifiedProducts = [...formik.values.unidentified_products, newValue];
                formik.setFieldValue('unidentified_products', updatedUnIdentifiedProducts);
            }

            setShowFields(false);
            setShowProducts(true);
            sideBarFormik.resetForm();
        }
    });

    const newProductFormik = useFormik({
        initialValues: {
            product_group: null,
            product_name: "",
            product_article: "",
            price: "",
            currency: "UAH",
            is_active: true,
            product_fields_values: []
        },
        validate: values => {
            const errors = {};

            if (!values.product_name) {
                errors.product_name = "Назва продукту не повинна бути пустою";
            }

            if (!values.product_group) {
                errors.product_group = "Назва групи не повинна бути пустою";
            }

            if (!values.price) {
                errors.price = "Введіть ціну товару";
            }

            return errors;
        },
        onSubmit: values => {
            if (values.product_group.toString().includes('-')) {
                var keys = values.product_group.split('-');
                values.product_group = keys[(keys.length - 1)];
            }

            const productCreation = async () => {

                const response = await createProduct(values);

                if (response.status === 201) {
                    toast.current.show({ severity: "success", summary: "Успіх редагування", detail: "Товар " + values.product_name + " успішно створено", life: 3000 });
                    newProductFormik.resetForm();
                    setProductSideBarVisible(false);
                    setReloadData(!reloadData);
                }
                else {
                    toast.current.show({ severity: "error", summary: "Помилка створення", detail: "Товар не створено. Помилка сервера " + response.status, life: 3000 });
                }

            };

            productCreation();
        }
    });

    const ReturnButtonHandler = () => {
        navigate("/productsreception");
    }

    const SelectProductGroup = (id) => {
        setSelectedGroup(id);

        setLoading(true);

        const getProductsByGroupId = async () => {

            const lazyStateToPass = lazyState;
            lazyStateToPass.filters.product_group.value = id;

            console.log(lazyStateToPass);
            const response = await fetchProducts(true, lazyStateToPass);

            if (response.status === 200) {
                setProducts(response.data.results);
                setTotalRecords(response.data.count);
            }
            else {
                setProducts([]);
            }

            setLoading(false);
        }

        getProductsByGroupId();
    }

    const onPage = (event) => {
        event.page = event.page + 1;
        setlazyState(event);
    };

    const AddProductHandler = () => {
        setVisible(true);
        setShowProducts(true);
    }

    const AddProductToTableHandler = rowData => {

        sideBarFormik.setFieldValue('type', rowData.accounting_type);
        sideBarFormik.setFieldValue('product', rowData.product_name);
        sideBarFormik.setFieldValue('product_id', rowData.id);

        setShowProducts(false);
        setShowFields(true);
    }

    const DeleteFieldHandler = (id, fields, name) => {

        const updatedFields = [...fields];

        const index = fields.findIndex(function (field) {
            return field.fe_id === id
        });

        setTotalActSum(totaLActSum - (fields[index].quantity === undefined ? 1 : fields[index].quantity) * +fields[index].priceUAH);

        updatedFields.splice(index, 1);
        formik.setFieldValue(name, updatedFields);
    };

    const closeSideBarHandler = () => {
        setVisible(false);
        setShowFields(false);
        sideBarFormik.resetForm();
    }

    const BackToProductsHandler = () => {
        setShowFields(false);
        setShowProducts(true);
        sideBarFormik.resetForm();
    }

    const SetReceptionCurrencyAmount = value => {
        sideBarFormik.setFieldValue('price', value);
        sideBarFormik.setFieldValue('priceUAH', convertSumWithLatestCurrencyRate(sideBarFormik.values.currency, value));
    };

    const setCurrency = value => {
        sideBarFormik.setFieldValue('currency', value);
        sideBarFormik.setFieldValue('custom_sale_price_currency', value);
    }

    const UnbindPayment = async (paymentId) => {

        const request = {
            act_id: location.state.id,
            payment_id: paymentId
        }

        const response = await deletePaymentFromAct(request);

        console.log(response);
        if(response.status === 204){
            
            const index = actPayments.findIndex(payment => payment.id === paymentId);
            if (index !== -1) {
                // Create a new array with the element removed
                const newPayments = [...actPayments.slice(0, index), ...actPayments.slice(index + 1)];
                console.log(newPayments);
                
                // Set the updated state
                setActPayments(newPayments);
            }

            toast.current.show({ severity: "success", summary: "Платіж відвязано", detail: "Платіж відвязано від акту", life: 3000 });

        }
        else{
            toast.current.show({ severity: "error", summary: "Помилка відвязки платежу", detail: "Платіж не відвязано від акту. Код помилки: " + response.status, life: 3000 });
        }
    }

    //#region Templates

    const mainToolbarLeftTemplate = () => {
        
        let name = "Створення нового акту прийому";
        
        if(isEdit){
            name = "Редагування акту прийому"

            if(isFinalized){
                name = "Інформація про акт прийому"
            }
        }
        return (<h3>{name}</h3>);
    }

    const mainToolbarRightTemplate = () => {
        return (<>
            <div className="flex flex-wrap gap-2">
                {!isFinalized && <Button label="Зберегти" severity="success" className={commonStyle.addButton} type="submit" />}
                <Button label="Вийти" severity="secondary" className={commonStyle.closeButton} type="button" onClick={ReturnButtonHandler} />
            </div>
        </>);
    }

    const contentToolbarRightTemplate = () => {
        return (<>
            <div className="flex flex-wrap gap-2">
                <Button label="+Додати товар" severity="success" className={commonStyle.addButton} type="button" onClick={AddProductHandler} />
            </div>
        </>);
    }

    const actionBodyTemplate = (rowData) => {
        return (<div className="flex flex-wrap gap-2">
            <Button
                icon="pi pi-plus"
                type="button"
                className={`p-button-rounded p-button-success ${commonStyle.addButton}`}
                tooltipOptions={{ showOnDisabled: true }}
                onClick={() => AddProductToTableHandler(rowData)}
            />
        </div>
        );
    };

    const saleCurrencyTemplate = (rowData) => {
        if (rowData.use_product_sale_price) {
            return "-";
        }

        return formatCurrency(rowData.custom_sale_price, rowData.custom_sale_price_currency);
    }

    const setTableHeader = (tablename) => {
        return (
            <div className="flex flex-wrap align-items-center justify-content-between gap-2">
                <span className="text-xl text-900 font-bold">{tablename}</span>
            </div>
        );
    }

    const sideBarTableHeader = (tableName) => {
        return (
            <div className="flex flex-wrap align-items-center justify-content-between gap-2">
                {setTableHeader(tableName)}
                <Button
                    icon="pi pi-plus"
                    type="button"
                    className={`p-button-rounded p-button-success ${commonStyle.addButton}`}
                    tooltipOptions={{ position: "left" }}
                    tooltip="Створити товар"
                    onClick={() => setProductSideBarVisible(true)}
                />
            </div>
        );
    }

    const tableActionBodyTemplate = (rowData) => {

        var isId = Object.hasOwn(rowData, 'identifier');

        return (<div className="flex flex-wrap gap-2">
            {!isFinalized && <Button
                icon="pi pi-trash"
                type="button"
                className={`p-button-rounded p-button-danger ${commonStyle.deleteButton}`}
                tooltip="Видалити з таблиці"
                tooltipOptions={{ showOnDisabled: true }}
                onClick={() => DeleteFieldHandler(rowData.fe_id, isId ? formik.values.identified_products : formik.values.unidentified_products, isId ? "identified_products" : "unidentified_products")}
            />}
        </div>
        );
    };

    const paymentActionBodyTemplate = (rowData) => {
        return (<Button
            icon="pi pi-times"
            type="button"
            className={`p-button-rounded p-button-danger ${commonStyle.deleteButton}`}
            tooltip="Відв'язати платіж"
            tooltipOptions={{ position: "top" }}
            onClick={() => UnbindPayment(rowData.id)}
        />
        );
    }

    const TableFooter = (data) => {

        const totalValue = data.reduce((accumulator, element) => accumulator + element.priceUAH * (element.quantity === undefined ? 1 : element.quantity), 0);

        return (
            <div className={commonStyle.footerContainer}>
                <label>Cума: {formatCurrency(totalValue, "UAH")}</label>
            </div>)
    }

    //#endregion

    return (<form onSubmit={formik.handleSubmit}>
        <Toast ref={toast} />
        <Toolbar className="mb-4" start={mainToolbarLeftTemplate} end={mainToolbarRightTemplate} />
        <TabView>
            <TabPanel header="Деталі акту прийому">
                <div className="grid">
                    <div className={`${commonStyle.leftMenu} col-3`}>
                        <div className={commonStyle.formInput}>
                            <div className="p-inputgroup flex-column">
                                <FloatLabel>
                                    <Dropdown value={ctx.partners[formik.values.cagent]}
                                        onChange={(e) => formik.setFieldValue('cagent', findKeyByValue(ctx.partners, e.value))}
                                        options={Object.values(ctx.partners)}
                                        disabled={isFinalized}
                                        showClear
                                        className={formik.errors.cagent && formik.touched.cagent ? 'p-invalid w-full' : "w-full"} />
                                    <label>Контрагент</label>
                                </FloatLabel>
                                {formik.errors.cagent && formik.touched.cagent && (<small className={commonStyle.errorSmall}>{formik.errors.cagent}</small>)}
                            </div>
                        </div>
                        <div className={commonStyle.formInput}>
                            <div className="p-inputgroup flex-column">
                                <FloatLabel>
                                    <Dropdown value={ctx.divisions[formik.values.division]}
                                        onChange={(e) => formik.setFieldValue('division', findKeyByValue(ctx.divisions, e.value))}
                                        options={Object.values(ctx.divisions)}
                                        disabled={isFinalized}
                                        showClear
                                        className={formik.errors.division && formik.touched.division ? 'p-invalid w-full' : "w-full"} />
                                    <label>Підрозділ</label>
                                </FloatLabel>
                                {formik.errors.division && formik.touched.division && (<small className={commonStyle.errorSmall}>{formik.errors.division}</small>)}
                            </div>
                        </div>
                        <div className={commonStyle.formInput}>
                            <div className="p-inputgroup flex-1">
                                <FloatLabel>
                                    <Calendar value={formik.values.act_date}
                                        onChange={(e) => { formik.setFieldValue('act_date', e.target.value) }}
                                        disabled={isFinalized}
                                        showIcon />
                                    <label>Дата акту</label>
                                </FloatLabel>
                            </div>
                        </div>
                        <div className={commonStyle.formInput}>
                            <div className="p-inputgroup flex-1">
                                <FloatLabel>
                                    <Calendar value={formik.values.pay_due_date}
                                        onChange={(e) => { formik.setFieldValue('pay_due_date', e.target.value) }}
                                        disabled={isFinalized}
                                        showIcon />
                                    <label>Очікувана дата оплати</label>
                                </FloatLabel>
                            </div>
                        </div>
                        <div className={commonStyle.checkBoxInput}>
                            <div className={`${commonStyle.checkboxStyle} p-inputgroup flex-1 md:w-22rem"`}>
                                <Checkbox
                                    name="act_number_autogenerate"
                                    onChange={(e) => { formik.setFieldValue('act_number_autogenerate', !formik.values.act_number_autogenerate) }}
                                    checked={formik.values.act_number_autogenerate}
                                />
                                <label>Автоматично генерувати номер акту</label>
                            </div>
                        </div>
                        {!formik.values.act_number_autogenerate && <div className={commonStyle.formInput}>
                            <div className="p-inputgroup flex-column">
                                <FloatLabel>
                                    <InputText value={formik.values.act_number}
                                        onChange={(e) => { formik.setFieldValue('act_number', e.target.value) }}
                                        disabled={isFinalized}
                                        className={formik.errors.act_number && formik.touched.act_number ? 'p-invalid w-full' : "w-full"} />
                                    <label>Номер акту</label>
                                </FloatLabel>
                                {formik.errors.act_number && formik.touched.act_number && (<small className={commonStyle.errorSmall}>{formik.errors.act_number}</small>)}
                            </div>
                        </div>}
                        <div className={commonStyle.formInput}>
                            <div className="p-inputgroup flex-column">
                                <FloatLabel>
                                    <InputText value={formik.values.status === "FINALIZED" ? "Фіналізований" : "Драфт"}
                                        disabled
                                        className="w-full" />
                                    <label>Статус</label>
                                </FloatLabel>
                            </div>
                        </div>
                        {isFinalized && <div className={commonStyle.formInput}>
                            <div className="p-inputgroup flex-column">
                                <FloatLabel>
                                    <InputText value={ctx.pay_statuses[formik.values.pay_status]}
                                        disabled
                                        className="w-full" />
                                    <label>Статус оплати</label>
                                </FloatLabel>
                            </div>
                        </div>}
                    </div>
                    <div className="col-9">
                        {!isFinalized && <Toolbar className="mb-4" end={contentToolbarRightTemplate}></Toolbar>}
                        {(formik.values.identified_products.length > 0) && <DataTable value={formik.values.identified_products} header={setTableHeader("Товари індивідуального обліку")}
                            footer={() => TableFooter(formik.values.identified_products)} tableStyle={{ minWidth: '50rem' }}>
                            <Column field="product_name" header="Продукт" style={{ width: '25%' }} />
                            <Column field="note" header="Примітка" style={{ width: '15%' }} />
                            <Column field="identifier" header="Ідентифікатор" style={{ width: '15%' }}></Column>
                            <Column field="price" header="Ціна в валюті розрахунку" body={rowData => formatCurrency(rowData.price, rowData.currency)} style={{ width: '10%' }}></Column>
                            <Column field="priceUAH" header="Ціна, грн" body={rowData => formatCurrency(rowData.priceUAH, "UAH")} style={{ width: '10%' }}></Column>
                            <Column field="custom_sale_price" header="Ціна продажу в валюті" body={saleCurrencyTemplate} style={{ width: '10%' }}></Column>
                            <Column field="action" body={tableActionBodyTemplate} style={{ width: '15%' }} />
                        </DataTable>}
                        {(formik.values.unidentified_products.length > 0) && <DataTable value={formik.values.unidentified_products} header={setTableHeader("Товари поштучного обліку")}
                            footer={() => TableFooter(formik.values.unidentified_products)} tableStyle={{ minWidth: '50rem' }}>
                            <Column field="product_name" header="Продукт" style={{ width: '25%' }}></Column>
                            <Column field="note" header="Примітка" style={{ width: '15%' }} />
                            <Column field="quantity" header="Кількість" style={{ width: '15%' }}></Column>
                            <Column field="price" header="Ціна в валюті розрахунку" body={rowData => formatCurrency(rowData.price, rowData.currency)} style={{ width: '10%' }}></Column>
                            <Column field="priceUAH" header="Ціна в гривні" body={(rowData => formatCurrency(rowData.priceUAH, "UAH"))} style={{ width: '10%' }}></Column>
                            <Column field="custom_sale_price" header="Ціна продажу в валюті" body={saleCurrencyTemplate} style={{ width: '10%' }}></Column>
                            <Column field="action" body={tableActionBodyTemplate} style={{ width: '15%' }} />
                        </DataTable>}
                        {(formik.values.unidentified_products.length > 0 || formik.values.identified_products.length > 0) && <div className={commonStyle.footerContainer}>
                            <h3>Загальна сума акту: {formatCurrency(totaLActSum, "UAH")}</h3>
                        </div>}
                    </div>
                </div>
            </TabPanel>
            {isFinalized && <TabPanel header="Платежі">
                <DataTable value={actPayments} emptyMessage="Платежів по акту не знайдено">
                    <Column field="id" header="ID" style={{ width: '10%' }} />
                    <Column field="date" header="Дата оплати" style={{ width: '20%' }} />
                    <Column field="cagent_name" header="Контрагент" body={(rowData) => rowData.cagent_name === null ? "(Клієнта не вказано)" : rowData.cagent_name} style={{ width: '20%' }} />
                    <Column field="distribution_sum" header="Сума" body={(rowdata) => formatCurrency(rowdata.distribution_sum, rowdata.currency)} style={{ width: '20%' }} />
                    <Column field="distribution_sum_UAH" header="Еквівалент, грн" body={(rowdata) => formatCurrency(rowdata.distribution_sum_UAH, "UAH")} style={{ width: '20%' }} />
                    <Column field="action" body={paymentActionBodyTemplate} style={{ width: '10%' }}/>
                </DataTable>
            </TabPanel>}
        </TabView>
        <Sidebar visible={visible} position="right"
            onHide={() => closeSideBarHandler()}
            className={`${commonStyle.shortSideBar} ${productSideBarVisible && isLargeScreen ? commonStyle.moveLeft : ''}`}>
            {showfields && <Button label="<<<" type="button" className={commonStyle.blankButton} onClick={() => BackToProductsHandler()} />}
            <form onSubmit={sideBarFormik.handleSubmit}>
                {showproducts && (<>
                    {selectedGroup === null && <label>Вибуріть групу товарів для вибору товару</label>}
                    <div className="p-inputgroup flex-column mt-4">
                        <FloatLabel>
                            <TreeSelect
                                name="sel_product_group"
                                value={selectedGroup}
                                onChange={(e) => SelectProductGroup(e.value)}
                                options={sortedGroups}
                                className="w-full" />
                            <label>Назва групи</label>
                        </FloatLabel>
                    </div>
                    {selectedGroup !== null && <DataTable value={products}
                        header={sideBarTableHeader("Товари")}
                        lazy
                        className="mt-3"
                        emptyMessage="Товари не знайдено"
                        rows={lazyState.rows} paginator onPage={onPage} first={lazyState.first} loading={loading} totalRecords={totalRecords} rowsPerPageOptions={[20, 50]}>
                        <Column field="product_name" header="Продукт" style={{ width: '90%' }} />
                        <Column field="action" body={actionBodyTemplate} style={{ width: '10%' }} />
                    </DataTable>}
                </>)}
                {showfields && (<>
                    <div className={commonStyle.sideBarInput}>
                        <div className="p-inputgroup flex-column">
                            <FloatLabel>
                                <InputText
                                    name="product"
                                    value={sideBarFormik.values.product}
                                    disabled
                                />
                                <label>Назва товару</label>
                            </FloatLabel>
                        </div>
                    </div>
                    {sideBarFormik.values.type === "INDIVIDUAL" && (<>
                        <div className={commonStyle.sideBarInput}>
                            <div className="p-inputgroup flex-column">
                                <FloatLabel>
                                    <InputText
                                        name="identifier"
                                        value={sideBarFormik.values.identifier}
                                        onChange={(e) => { sideBarFormik.setFieldValue('identifier', e.target.value) }}
                                        className={sideBarFormik.errors.identifier && sideBarFormik.touched.identifier ? 'p-invalid' : ''}
                                    />
                                    <label>Ідентифікатор</label>
                                </FloatLabel>
                                {sideBarFormik.errors.identifier && sideBarFormik.touched.identifier && (<small className={commonStyle.errorSmall}>{sideBarFormik.errors.identifier}</small>)}
                            </div>
                        </div>
                    </>)}
                    {sideBarFormik.values.type === "PIECE" && (<>
                        <div className={commonStyle.sideBarInput}>
                            <div className="p-inputgroup flex-column">
                                <FloatLabel>
                                    <InputText
                                        name="quantity"
                                        value={sideBarFormik.values.quantity}
                                        onChange={(e) => { sideBarFormik.setFieldValue('quantity', e.target.value) }}
                                        className={sideBarFormik.errors.quantity && sideBarFormik.touched.quantity ? 'p-invalid' : ''}
                                    />
                                    <label>Кількість</label>
                                </FloatLabel>
                                {sideBarFormik.errors.quantity && sideBarFormik.touched.quantity && (<small className={commonStyle.errorSmall}>{sideBarFormik.errors.quantity}</small>)}
                            </div>
                        </div>
                    </>)}
                    <div className={commonStyle.sideBarInput}>
                        <div className="p-inputgroup flex-column">
                            <FloatLabel>
                                <InputText
                                    name="note"
                                    value={sideBarFormik.values.note}
                                    onChange={(e) => { sideBarFormik.setFieldValue("note", e.target.value) }}
                                />
                                <label>Примітка</label>
                            </FloatLabel>
                        </div>
                    </div>
                    <div className={commonStyle.sideBarInput}>
                        <div className="p-inputgroup flex-column">
                            <FloatLabel>
                                <Dropdown value={sideBarFormik.values.currency}
                                    onChange={(e) => setCurrency(e.value)}
                                    options={currencies}
                                    showClear
                                    className={sideBarFormik.errors.currency && sideBarFormik.touched.currency ? 'p-invalid w-full' : "w-full"} />
                                <label>Валюта</label>
                            </FloatLabel>
                            {sideBarFormik.errors.currency && sideBarFormik.touched.currency && (<small className={commonStyle.errorSmall}>{sideBarFormik.errors.currency}</small>)}
                        </div>
                    </div>
                    <div className={commonStyle.sideBarInput}>
                        <div className="p-inputgroup flex-column">
                            <FloatLabel>
                                <InputText
                                    name="price"
                                    value={sideBarFormik.values.price}
                                    onChange={(e) => { SetReceptionCurrencyAmount(e.target.value) }}
                                    className={sideBarFormik.errors.price && sideBarFormik.touched.price ? 'p-invalid' : ''}
                                />
                                <label>Ціна в валюті розрахунку, за одиницю</label>
                            </FloatLabel>
                            {sideBarFormik.errors.price && sideBarFormik.touched.price && (<small className={commonStyle.errorSmall}>{sideBarFormik.errors.price}</small>)}
                        </div>
                    </div>
                    {(sideBarFormik.values.currency !== undefined && sideBarFormik.values.currency !== "UAH") && <div className={commonStyle.sideBarInput}>
                        <div className="p-inputgroup flex-column">
                            <FloatLabel>
                                <InputText
                                    name="priceUAH"
                                    value={sideBarFormik.values.priceUAH}
                                    onChange={(e) => { sideBarFormik.setFieldValue('priceUAH', e.target.value) }}
                                    className={sideBarFormik.errors.priceUAH && sideBarFormik.touched.priceUAH ? 'p-invalid' : ''}
                                />
                                <label>Ціна в гривні, за одиницю</label>
                            </FloatLabel>
                            {sideBarFormik.errors.priceUAH && sideBarFormik.touched.priceUAH && (<small className={commonStyle.errorSmall}>{sideBarFormik.errors.priceUAH}</small>)}
                        </div>
                    </div>}
                    <div className={commonStyle.formInput}>
                        <div className={`${commonStyle.checkboxStyle} p-inputgroup flex-1 md:w-22rem"`}>
                            <Checkbox
                                name="use_product_sale_price"
                                onChange={(e) => { sideBarFormik.setFieldValue('use_product_sale_price', !sideBarFormik.values.use_product_sale_price) }}
                                checked={sideBarFormik.values.use_product_sale_price}
                            />
                            <label>Використовувати ціну продажу з довідника</label>
                        </div>
                    </div>
                    {!sideBarFormik.values.use_product_sale_price && <>
                        <div className={commonStyle.sideBarInput}>
                            <div className="p-inputgroup flex-column">
                                <FloatLabel>
                                    <Dropdown value={sideBarFormik.values.custom_sale_price_currency}
                                        onChange={(e) => sideBarFormik.setFieldValue('custom_sale_price_currency', e.value)}
                                        options={currencies}
                                        showClear
                                        className={sideBarFormik.errors.custom_sale_price_currency && sideBarFormik.touched.custom_sale_price_currency ? 'p-invalid w-full' : "w-full"} />
                                    <label>Валюта продажу</label>
                                </FloatLabel>
                                {sideBarFormik.errors.custom_sale_price_currency && sideBarFormik.touched.custom_sale_price_currency && (<small className={commonStyle.errorSmall}>{sideBarFormik.errors.custom_sale_price_currency}</small>)}
                            </div>
                        </div>
                        <div className={commonStyle.sideBarInput}>
                            <div className="p-inputgroup flex-column">
                                <FloatLabel>
                                    <InputText
                                        name="custom_sale_price"
                                        value={sideBarFormik.values.custom_sale_price}
                                        onChange={(e) => { sideBarFormik.setFieldValue('custom_sale_price', e.target.value) }}
                                        className={sideBarFormik.errors.custom_sale_price && sideBarFormik.touched.custom_sale_price ? 'p-invalid' : ''}
                                    />
                                    <label>Ціна продажу в валюті розрахунку, за одиницю</label>
                                </FloatLabel>
                                {sideBarFormik.errors.custom_sale_price && sideBarFormik.touched.custom_sale_price && (<small className={commonStyle.errorSmall}>{sideBarFormik.errors.custom_sale_price}</small>)}
                            </div>
                        </div>
                    </>}
                    <div>
                        <Button label="Додати" severity="success" type="submit" className={`${commonStyle.sideBarButton} ${commonStyle.addButton}`} autoFocus />
                    </div>
                </>)}
            </form>
        </Sidebar>
        <Sidebar
            visible={productSideBarVisible}
            header={<h2>Створення нового товару</h2>}
            position="right"
            onHide={() => setProductSideBarVisible(false)} className={`${commonStyle.sidebarmoved} ${commonStyle.shortSideBar} ${!isLargeScreen && 'sidebar-mobile'}`}>
            <form onSubmit={newProductFormik.handleSubmit}>
                <div className={commonStyle.sideBarInput}>
                    <div className="p-inputgroup flex-column">
                        <FloatLabel>
                            <TreeSelect
                                name="product_group"
                                value={newProductFormik.values.product_group}
                                onChange={(e) => newProductFormik.setFieldValue("product_group", e.value)}
                                options={sortedGroups}
                                showClear
                                className={newProductFormik.errors.product_group && newProductFormik.touched.product_group ? 'p-invalid w-full' : 'w-full'} />
                            <label>Назва групи</label>
                        </FloatLabel>
                        {newProductFormik.errors.product_group && newProductFormik.touched.product_group && (<small className={commonStyle.errorSmall}>{newProductFormik.errors.product_group}</small>)}
                    </div>
                </div>
                <div className={commonStyle.sideBarInput}>
                    <div className="p-inputgroup flex-column">
                        <FloatLabel>
                            <InputText
                                name="product_name"
                                value={newProductFormik.values.product_name}
                                onChange={(e) => { newProductFormik.setFieldValue('product_name', e.target.value) }}
                                className={newProductFormik.errors.product_name && newProductFormik.touched.product_name ? 'p-invalid' : ''}
                            />
                            <label>Назва товару</label>
                        </FloatLabel>
                        {newProductFormik.errors.product_name && newProductFormik.touched.product_name && (<small className={commonStyle.errorSmall}>{newProductFormik.errors.product_name}</small>)}
                    </div>
                </div>
                <div className={commonStyle.sideBarInput}>
                    <div className="p-inputgroup flex-column">
                        <FloatLabel>
                            <InputText
                                name="product_article"
                                value={newProductFormik.values.product_article}
                                onChange={(e) => { newProductFormik.setFieldValue('product_article', e.target.value) }}
                            />
                            <label>Артикул</label>
                        </FloatLabel>
                    </div>
                </div>
                <div className={commonStyle.sideBarInput}>
                    <div className="p-inputgroup py-0 flex-1">
                        <span className="currencySpan p-inputgroup-addon">
                            <Dropdown
                                className="currencyDropDown"
                                value={newProductFormik.values.currency}
                                onChange={e => newProductFormik.setFieldValue('currency', e.value)}
                                options={currencies} />
                        </span>
                        <FloatLabel>
                            <InputText
                                name="price"
                                value={newProductFormik.values.price}
                                onChange={e => newProductFormik.setFieldValue('price', e.target.value)}
                                className={newProductFormik.errors.price && newProductFormik.touched.price ? 'p-invalid' : ''}
                            />
                            <label>Ціна</label>
                        </FloatLabel>
                        {newProductFormik.errors.price && newProductFormik.touched.price && (<small className={commonStyle.errorSmall}>{newProductFormik.errors.price}</small>)}
                    </div>
                </div>
                <div className={commonStyle.formInput}>
                    <div className={`${commonStyle.checkboxStyle} p-inputgroup flex-1 md:w-22rem"`}>
                        <Checkbox
                            name="is_active"
                            onChange={(e) => { newProductFormik.setFieldValue('is_active', !newProductFormik.values.use_product_sale_price) }}
                            checked={newProductFormik.values.is_active}
                        />
                        <label>Активувати товар при створенні</label>
                    </div>
                </div>
                <div>
                    <Button label="Додати" severity="success" type="submit" className={`${commonStyle.sideBarButton} ${commonStyle.addButton}`} autoFocus />
                </div>
            </form>
        </Sidebar>
    </form>);
}

export default NewProductsReception;