import { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import { useNavigate, useLocation } from "react-router-dom";
import useAuthenticatedRequest from "../../../../Utils/TokenrRefreshUtils";
import Cookies from "js-cookie";

import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { FloatLabel } from "primereact/floatlabel";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";

import commonStyle from "../../CommonStyles.module.css";

const CurrencyRates = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const authenticatedRequest = useAuthenticatedRequest();
    const toast = useRef(null);

    const [currencyRates, setCurrencyRates] = useState();
    const [editDialogVisible, setEditDialogVisible] = useState(false);
    const [loading, setLoading] = useState(true);
    const [reloadData, setReloadData] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);

    const [lazyState, setlazyState] = useState({
        first: 0,
        rows: 10,
        page: 1,
        sortField: "date",
        sortOrder: 1,
    });

    useEffect(() => {

        const fetchData = async () => {

            let url = process.env.REACT_APP_BACKEND_URL + "currency/get_list_exchange_rates?currency=" + location.state.currency_short + "&page=" + lazyState.page + "&page_size=" + lazyState.rows;
            url = lazyState.sortField == null ? url : url + "&ordering=" + (lazyState.sortOrder === 1 ? "-" + lazyState.sortField : lazyState.sortField);
            const options = {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json'
                }
            };

            const response = await authenticatedRequest(url, options);

            if (response.status !== 200){ 
                toast.current.show({ severity: "error", summary: "Помилка завантаження списку", detail: "Помилка сервера " + response.status, life: 3000 });
                setTotalRecords(0);
                setCurrencyRates([]);
            }
            else
            {
                const parsedResponse = await response.json();
                //logging to remove later
                console.log(parsedResponse);
    
                setTotalRecords(parsedResponse.count);
                setCurrencyRates(parsedResponse.results);
            }
            
            setLoading(false);
        }

        fetchData();

    }, [location.state.currency_short, reloadData, lazyState, authenticatedRequest]);

    const formik = useFormik({
        initialValues: {
            id: "",
            date: null,
            rate: '',
            currency: null,
        },
        validate: values => {
            const errors = {};

            if (values.rate === null || values.rate === "") {
                errors.rate = "Курс не повинен бути пустим";
            }
            else if (isNaN(Number(values.rate))) {
                errors.rate = 'Курс повинен бути числом';
            }

            return errors;
        },
        onSubmit: values => {

            console.log(values);

            const day = ('0' + formik.values.date.getDate()).slice(-2); // Add leading zero if needed
            const month = ('0' + (formik.values.date.getMonth() + 1)).slice(-2); // Month is zero-based, so add 1
            const year = formik.values.date.getFullYear();

            const formattedDate = `${year}-${month}-${day}`;

            const editCurrenciesRate = async event => {

                const url = process.env.REACT_APP_BACKEND_URL + "currency/update_exchange_rate/" + formik.values.id + "/";
                const options = {
                    method: "PUT",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ "date": formattedDate, "rate": formik.values.rate })
                };

                const response = await authenticatedRequest(url, options);
                //logging to remove later
                console.log(response);

                if (response.status !== 200){ 
                    toast.current.show({ severity: "error", summary: "Помилка редагування", detail: "Помилка сервера " + response.status, life: 3000 });
                    setEditDialogVisible(false);
                }
                else
                {
                    toast.current.show({ severity: "success", summary: 'Редагування', detail: 'Курс валют на '+ formattedDate +' успішно змінено', life: 3000 });
                    setEditDialogVisible(false);
                    Cookies.remove("USDRate");
                    navigate(0)
                }

                formik.resetForm();
                setReloadData(!reloadData);
            }

            editCurrenciesRate();
        }
    });

    const ReturnButtonHandler = () => {
        navigate("/currencies");
    }

    const DeleteButtonHandler = (rowData) => {
        const deleteData = async () => {

            const url = process.env.REACT_APP_BACKEND_URL + "currency/delete_exchange_rate/" + rowData.id + "/";
            const options = {
                method: "DELETE",
                headers: {
                    'Content-Type': 'application/json'
                }
            };

            const response = await authenticatedRequest(url, options);
            console.log(response);

            if (response.status !== 204){ 
                toast.current.show({ severity: "error", summary: "Помилка видалення", detail: "Помилка сервера " + response.status, life: 3000 });
            }
            else
            {
                toast.current.show({ severity: "success", summary: 'Видалення', detail: 'Курс валют успішно видалено', life: 3000 });
                Cookies.remove("USDRate");
                navigate(0)
            }

            setReloadData(!reloadData);
        }

        deleteData();
    }

    const ShowEditDialog = rowData => {
        const date = new Date(rowData.date);
        console.log(date);
        formik.setFieldValue('id', rowData.id);
        formik.setFieldValue('date', date);
        formik.setFieldValue('rate', rowData.rate);
        formik.setFieldValue('currency', location.state.currency_short);
        setEditDialogVisible(true);
    }

    const onPage = (event) => {
        event.page = event.page + 1;
        setlazyState(event);
    };

    const onSort = (event) => {
        event.page = lazyState.page;
        setlazyState(event);
    }

    const confirmDelete = (event, rowData) => {
        confirmPopup({
            group: 'headless',
            target: event.currentTarget,
            message: 'Підтвердження видалення курсу на ' + rowData?.date,
            icon: 'pi pi-exclamation-triangle',
            defaultFocus: 'accept',
            acceptLabel: "Так",
            rejectLabel: "Hi",
            accept: () => {DeleteButtonHandler(rowData)}
        });
    }

    const mainToolbarLeftTemplate = () => {
        return (<h3>Історія курсу валюти {location.state.currency_short}</h3>);
    }

    const mainToolbarRightTemplate = () => {
        return (
            <div className="flex flex-wrap gap-2">
                <Button label="Вийти" severity="secondary" type="button" onClick={ReturnButtonHandler} className={commonStyle.closeButton} />
            </div>
        );
    }

    const actionBodyTemplate = rowData => {
        return (<div className="flex flex-wrap gap-2">
            <Button
                icon="pi pi-times"
                className={`p-button-rounded p-button-danger ${commonStyle.deleteButton}`}
                onClick={(event) => { confirmDelete(event, rowData) }}
            />
            <Button
                icon="pi pi-pencil"
                className={`p-button-rounded p-button-info ${commonStyle.editButton}`}
                onClick={() => { ShowEditDialog(rowData) }}
            />
        </div>
        );
    }

    return (<>
        <Toast ref={toast} />
        <Toolbar className="mb-4" start={mainToolbarLeftTemplate} end={mainToolbarRightTemplate}></Toolbar>
        <div className='grid'>
            <div className='col-12 justify-content-center'>
                <DataTable value={currencyRates} stripedRows lazy rows={lazyState.rows} paginator onSort={onSort} sortField={lazyState.sortField} sortOrder={lazyState.sortOrder}
                    onPage={onPage} first={lazyState.first} loading={loading} totalRecords={totalRecords} rowsPerPageOptions={[10, 20, 50]} tableStyle={{ minWidth: '60rem' }} >
                    <Column field="date" dataType="date" header="Дата" sortable />
                    <Column field="rate" header="Курс" sortable />
                    <Column field="action" body={actionBodyTemplate} />
                </DataTable>
            </div>
            <ConfirmPopup group="headless" />
        </div>
        <Dialog header={"Редагування курсу валюти на"}
            visible={editDialogVisible}
            position="top"
            style={{ width: '35vw', display: 'flex', flexDirection: 'column' }}
            onHide={() => setEditDialogVisible(false)}
            draggable={false}
            resizable={false}
        >
            <form onSubmit={formik.handleSubmit} style={{ flex: '1', display: 'flex', flexDirection: 'column' }}>
                <div className={commonStyle.dialogFormInputFirst}>
                    <div className="p-inputgroup flex-1 w-full md:w-22rem">
                        <FloatLabel>
                            <Calendar value={formik.values.date} onChange={(e) => { formik.setFieldValue('date', e.target.value) }} showIcon />
                            <label>Дата</label>
                        </FloatLabel>
                    </div>
                </div>
                <div className={commonStyle.dialogFormInput}>
                    <div className="p-inputgroup flex-column w-full md:w-22rem">
                        <FloatLabel>
                            <InputText
                                name="rate"
                                value={formik.values.rate}
                                onChange={(e) => { formik.setFieldValue('rate', e.target.value) }}
                                className={formik.errors.rate && formik.touched.rate ? 'p-invalid' : ''}
                            />
                            <label>Курс</label>
                            {formik.errors.rate && formik.touched.rate && (<small className={commonStyle.errorSmall}>{formik.errors.rate}</small>)}
                        </FloatLabel>
                    </div>
                </div>
                <div className={commonStyle.dialogFormInput}>
                    <div className="p-inputgroup flex-1 w-full md:w-22rem">
                        <FloatLabel>
                            <InputText
                                name="currency"
                                value={formik.values.currency}
                                disabled
                                onChange={(e) => { formik.setFieldValue('currency', e.target.value) }}
                                className="w-full"
                            />
                            <label>Валюта</label>
                        </FloatLabel>
                    </div>
                </div>
                <div className={commonStyle.dialogSubmit}>
                    <Button label="Змінити курс" icon="pi pi-check" type="submit" severity="success" className={commonStyle.addButton} autoFocus />
                </div>
            </form>
        </Dialog>

    </>);
};

export default CurrencyRates;