import EnumsContext from "../../../../context/enums-context";
import { formatCurrency } from "../../../../Utils/Utils";
import { useContext, useEffect, useRef, useState } from "react";
import useApi from "../../../../Utils/BackendClient";
import useGetEnums from "../../../../Utils/EnumsUtils";

import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { Sidebar } from "primereact/sidebar";
import { Toast } from "primereact/toast";

import commonStyle from "../../CommonStyles.module.css";
import { transactionTypeTranslation, TranslateTransactionType } from "../../../../Utils/TranslationUtils";

const Transactions = () => {

    const toast = useRef();
    const ctx = useContext(EnumsContext);
    const { deletePaymentFromTransaction, fetchTransactionPayments, fetchTransactions } = useApi();

    const [transactions, setTransactions] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [payments, setPayments] = useState([]);
    const [loading, setLoading] = useState(true);
    const [visible, setVisible] = useState(false);
    const [selectedTransaction, setSelectedTransaction] = useState(null);
    const [lazyState, setlazyState] = useState({
        first: 0,
        rows: 10,
        page: 1,
        sortField: "id",
        sortOrder: 1,
        filters:{
            type: { value: null, matchMode: 'equals' },
            pay_status: { value: null, matchMode: 'equals'}
        }
    });

    useGetEnums(["pay_statuses"]);

    useEffect(() => {
        const fetchData = async () => {
           
            const response = await fetchTransactions(lazyState);

            if (response.status === 200) {
                setTotalRecords(response.data.count);
                setTransactions(response.data.results);
                console.log(response.data.results);
            }
            else{
                setTotalRecords(0);
                setTransactions([]);

                toast.current.show({ severity: "error", summary: "Помилка завантаження списку клієнтів", detail: "Помилка сервера " + response.status, life: 3000 });
            }

            setLoading(false);
        }

        fetchData();

    }, [lazyState, fetchTransactions]);

    const getTransactionPayments = (rowData) => {

        const fetchData = async () => {

            const response = await fetchTransactionPayments(rowData.id);

            setSelectedTransaction(rowData.id);

            if (response.status !== 200){
                toast.current.show({ severity: "error", summary: "Помилка завантаження списку", detail: "Помилка сервера " + response.status, life: 3000 });
                setPayments([]);
            }
            else
            {
                setPayments(response.data.results);
            }
        }

        fetchData();
        setVisible(true);
    }

    const UnbindPayment = async (paymentId) => {

        const request = {
            transaction_id: selectedTransaction,
            payment_id: paymentId
        }

        const response = await deletePaymentFromTransaction(request);

        console.log(response);
        if(response.status === 204){
            
            const index = payments.findIndex(payment => payment.payment_id === paymentId);
            if (index !== -1) {
                // Create a new array with the element removed
                const newPayments = [...payments.slice(0, index), ...payments.slice(index + 1)];
                console.log(newPayments);
                
                // Set the updated state
                setPayments(newPayments);
            }

            toast.current.show({ severity: "success", summary: "Платіж відвязано", detail: "Платіж відвязано від акту", life: 3000 });

        }
        else{
            toast.current.show({ severity: "error", summary: "Помилка відвязки платежу", detail: "Платіж не відвязано від акту. Код помилки: " + response.status, life: 3000 });
        }
    }


    const onPage = (event) => {
        event.page = event.page + 1;
        setlazyState(event);
    };

    const onFilter = (event) => {
        event.page = 1;
        setlazyState(event);
    }

    const closeSideBarHandler = () =>{
        setVisible(false);
    }

    const transactionTemplate = (rowData) => {
        return TranslateTransactionType(rowData.type);
    }

    const payStatusTemplate = (rowData) => {
        return ctx.pay_statuses[rowData.pay_status];
    }

    const typeRowFilterTemplate = (options) => {
        return (
            <Dropdown
                value={options.value}
                options={Object.entries(transactionTypeTranslation).map(([id, name]) => ({
                    label: name,
                    value: id
                }))}
                onChange={(e) => options.filterApplyCallback(e.value)}
                optionLabel="label"
                className="p-column-filter"
                style={{ minWidth: '10rem' }}
            />
        );
    };

    const payStatusRowFilterTemplate = (options) => {
        return (
            <Dropdown
                value={options.value}
                options={Object.entries(ctx.pay_statuses).map(([id, name]) => ({
                    label: name,
                    value: id
                }))}
                onChange={(e) => options.filterApplyCallback(e.value)}
                optionLabel="label"
                className="p-column-filter"
                style={{ minWidth: '10rem' }}
            />
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <Button
                label="Платежі"
                icon="pi pi-eye"
                onClick={() => getTransactionPayments(rowData)}
                text
            />
        );
    };

    const paymentActionBodyTemplate = (rowData) => {
        return (<Button
            icon="pi pi-times"
            type="button"
            className={`p-button-rounded p-button-danger ${commonStyle.deleteButton}`}
            tooltip="Відв'язати платіж"
            tooltipOptions={{ position: "top" }}
            onClick={() => UnbindPayment(rowData.payment_id)}
        />
        );
    }

    return (<>
        <Toast ref={toast} />
        <div className='grid'>
            <div className='col-12 justify-content-center'>
                <DataTable value={transactions} stripedRows lazy rows={lazyState.rows} paginator onPage={onPage}
                    first={lazyState.first} loading={loading} totalRecords={totalRecords} rowsPerPageOptions={[10, 20, 50]} 
                    filterDisplay="row" dataKey="id" onFilter={onFilter} filters={lazyState.filters}
                    tableStyle={{ minWidth: '60rem' }} >
                    <Column field="act_number" header="Номер акту" />
                    <Column field="type" header="Тип" filter filterElement={typeRowFilterTemplate} showFilterMenu={false} body={transactionTemplate}  style={{ width: '10%' }}/>
                    <Column field="sum" header="Сума" body={(rowData) => formatCurrency(rowData.sum, rowData.currency)} />
                    <Column field="sumUAH" header="Сума, грн" body={(rowData) => formatCurrency(rowData.sumUAH, "UAH")} />
                    <Column field="cagent_name" header="Контрагент" />
                    <Column field="pay_status" header="Статус оплати" filter filterElement={payStatusRowFilterTemplate} showFilterMenu={false} body={payStatusTemplate} style={{ width: '15%' }}/>
                    <Column field="unpaid_balance" header="Неоплачений баланс" body={(rowData) => formatCurrency(rowData.unpaid_balance, rowData.currency)} />
                    <Column field="pay_due_date" header="Очікувана дата оплати"/>
                    <Column field="action" body={actionBodyTemplate} />
                </DataTable>
            </div>
        </div>
        <Sidebar visible={visible} position="right" onHide={() => closeSideBarHandler()} className={commonStyle.sidebarTable}>
            <h2>Список платежів</h2>
            <DataTable value={payments} stripedRows >                
                <Column field="payment_id" header="ID платежу"/>
                <Column field="distribution_sum" header="Розподілена сума" body={(rowData) => formatCurrency(rowData.distribution_sum, rowData.currency)} />
                <Column field="distribution_sum_UAH" header="Розподілена сума, грн" body={(rowData) => formatCurrency(rowData.distribution_sum_UAH, "UAH")} />
                <Column field="cagent_name" header="Контрагент" />
                <Column field="action" body={paymentActionBodyTemplate} style={{ width: '10%' }}/>
            </DataTable>
        </Sidebar>
    </>)
}

export default Transactions;