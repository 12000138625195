import ActStatusCircle from "../../../UIElements/StatusCircle";
import Cookies from "js-cookie";
import EnumsContext from "../../../../context/enums-context";
import { formatCurrency, formatDate, formatShortDate } from "../../../../Utils/Utils";
import useApi from "../../../../Utils/BackendClient";
import { useFormik } from "formik";
import useGetEnums from "../../../../Utils/EnumsUtils";
import { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { confirmDialog, ConfirmDialog } from "primereact/confirmdialog";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Message } from "primereact/message";
import { Sidebar } from "primereact/sidebar";
import { SplitButton } from "primereact/splitbutton";
import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";

import commonStyle from "../../CommonStyles.module.css";

const ProductsReturn = () => {

    const currencies = ["UAH", "EUR", "USD"];
    const paymentMethods = ["Термінал", "Готівка", "IBAN"];
    const navigate = useNavigate();
    const location = useLocation();
    const buttonRef = useRef(null);
    const toast = useRef(null);
    const ctx = useContext(EnumsContext);
    const { createPayment, deleteClientReturnAct, fetchClientReturnActs, fetchActUnpaidBalance, finalizeClientReturnAct } = useApi();

    useGetEnums(["divisions", "clients", "pay_statuses"]);

    const [productReturnActs, setProductReturnActs] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [reloadData, setReloadData] = useState(false);
    const [loading, setLoading] = useState(true);
    const [visible, setVisible] = useState(false);
    const [unDividedSum, setUnDividedSum] = useState(0);
    const [totalSumUAH, setTotalSumUAH] = useState(0);
    const [lazyState, setlazyState] = useState({
        first: 0,
        rows: 10,
        page: 1,
        sortField: "created_at",
        sortOrder: 1,
        filters:{
            cagent: { value: null, matchMode: 'contains' },
            division: {value: null, matchMode: 'contains' },
            pay_status: {value: null, matchMode: 'contains' }
        }
    });

    useEffect(() => {
        if(location.state !== null){
            toast.current.show(location.state.toast);
        }

        location.state = null;
    },[location.state, location]);

    useEffect(() => {

        const getClientsReturns = async () => {
            const response = await fetchClientReturnActs(lazyState);

            if(response.status === 200){
                setTotalRecords(response.data.count);
                setProductReturnActs(response.data.results);
            }
            else{
                if(response.status === 403){
                    toast.current.show({ severity: "error", summary: "Помилка завантаження списку повернень", detail: "Користувач не має права переглядати сторінку", life: 3000 });
                }
                else{
                    toast.current.show({ severity: "error", summary: "Помилка завантаження списку повернень", detail: "Помилкв сервера: " + response.status, life: 3000 });
                }
            }

            setLoading(false);
        }

        getClientsReturns();

    },[lazyState, reloadData, fetchClientReturnActs]);
    

    const CreateNewProductsReturnButtonHandler = () => {
        navigate("/productsreturn/newproductsreturn");
    }

    const onPage = (event) => {
        event.page = event.page +1;
        setlazyState(event);
        console.log(event);
    };

    const onSort = (event) => {
        event.page = lazyState.page;
        setlazyState(event);
    }

    const onFilter = (event) => {
        event.page = 1;
        setlazyState(event);
    }

    const cagentRowFilterTemplate = (options) => {
        return (
            <Dropdown
                value={options.value}
                options={Object.entries(ctx.clients).map(([id, name]) => ({ label: name, value: id }))}
                onChange={(e) => options.filterApplyCallback(e.value)} 
                optionLabel="label" 
                className="p-column-filter"
                style={{ minWidth: '14rem' }}
            />
        );
    };

    const divisionRowFilterTemplate = (options) => {
        return (
            <Dropdown
                value={options.value}
                options={Object.entries(ctx.divisions).map(([id, name]) => ({ label: name, value: id }))}
                onChange={(e) => options.filterApplyCallback(e.value)}
                optionLabel="label" 
                className="p-column-filter"
                style={{ minWidth: '10rem' }}
            />
        );
    };

    const payStatusRowFilterTemplate = (options) => {
        return (
            <Dropdown
                value={options.value}
                options={Object.entries(ctx.pay_statuses).map(([id, name]) => ({ label: name, value: id }))}
                onChange={(e) => options.filterApplyCallback(e.value)}
                optionLabel="label" 
                className="p-column-filter"
                style={{ minWidth: '10rem' }}
            />
        );
    };

    const confirmDelete = (event, rowData) => {
        confirmDialog({
            group: 'headless',
            header: 'Підтвердження',
            message: 'Підтвердіть видалення акту',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: "Так",
            rejectLabel: "Hi",
            defaultFocus: 'accept',
            accept: () => {DeleteButtonHandler(rowData)}
        });
    }

    const confirmFinalize = (event, rowData) => {
        confirmDialog({
            group: 'headless',
            header: 'Підтвердження',
            message: 'Після фіналізації акту, буде відсутня можливість його редагування',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: "Так",
            rejectLabel: "Hi",
            defaultFocus: 'accept',
            accept: () => {FinalizeActHandler(rowData)}
        });
    }  

    const DeleteButtonHandler = (rowData) => {
        const deleteAct = async () => {

            const response = await deleteClientReturnAct(rowData.id);
            //logging to remove later
            console.log(response);

            if (response.status !== 204){ 
                toast.current.show({ severity: "error", summary: "Помилка видалення", detail: "Помилка сервера " + response.status, life: 3000 });
            }
            else
            {
                toast.current.show({ severity: "success", summary: 'Видалення', detail: 'Акт успішно видалено', life: 3000 });
            }

            setReloadData(!reloadData);
        }

        deleteAct();
    }

    const FinalizeActHandler = (rowData) => {

        const finalizeAct = async () => {

            const response = await finalizeClientReturnAct(rowData.id);

            if (response.status === 200) {
                toast.current.show({ severity: 'success', summary: 'Фіналізація', detail: 'Акт фіналізовано', life: 3000 });
            }
            else if (response.status === 404) {
                toast.current.show({ severity: "error", summary: "Помилка фіналізації", detail: "Акту " + rowData.id + " не знайдено", life: 3000 });
            }
            else if (response.status === 405) {
                toast.current.show({ severity: "error", summary: "Помилка фіналізації", detail: "Акт " + rowData.id + " уже фіналізовано", life: 3000 });
            }
            else {
                toast.current.show({ severity: "error", summary: "Помилка фіналізації", detail: "Помилка сервера " + response.status, life: 3000 });
            }

            setReloadData(!reloadData);
        }

        finalizeAct();
    }

    const formik = useFormik({
        initialValues: {
            payments: [],

        },
        validate: values => {
            const errors = {};

            values.payments.forEach((payment, index) => {
                if (payment.paymentMethod === null || payment.paymentMethod === '') {
                    if (!errors.payments) errors.payments = [];
                    errors.payments[index] = { paymentMethod: 'Вкажіть спосіб оплати' };
                }
            });

            return errors
        },
        onSubmit: values => {

            let paymentRequest = [];

            values.payments.forEach(payment => {

                let paymentType = "CASH";
                if (payment.paymentMethod === "Термінал") {
                    paymentType = "CARD";
                }
                else if (payment.paymentMethod === "IBAN") {
                    paymentType = "IBAN";
                }

                const singlePaymentRequest = {
                    act_id: values.id,
                    date: formatShortDate(Date.now()),
                    sum: payment.sum,
                    currency: payment.currency,
                    type: paymentType,
                    method: "OUTCOME",
                    cagent_id: values.cagent_id != null ? values.cagent_id : null,
                }

                paymentRequest.push(singlePaymentRequest);
            });

            const callBackEnd = async () => {

                const response = await createPayment(paymentRequest);

                console.log(response);

                if (response.status === 201) {
                    toast.current.show({ severity: "success", summary: 'Оплата', detail: 'Оплата пройшла успішно', life: 2000 });
                    setReloadData(!reloadData);
                    setVisible(false);
                }
                else if (response.status === 403) {
                    toast.current.show({ severity: "error", summary: "Помилка продажу", detail: "Користувач не має прав проводити оплату", life: 3000 });
                }
                else {
                    toast.current.show({ severity: "error", summary: "Помилка продажу", detail: "Помилка створення платежу у валюті, код помилки:" + response.status, life: 3000 });
                }
            }

            callBackEnd();
        }

    });

    useEffect(() => {
        let convertedAmount = 0;
  
        if (formik.values.payments.length !== 0) {
           formik.values.payments.forEach(payment => {
              convertedAmount += payment.sum * (payment.currency === "UAH" ? 1 : Cookies.get(`${payment.currency}Rate`));
           });
        }
        
        setUnDividedSum(totalSumUAH - convertedAmount);
  
    }, [formik.values.payments, totalSumUAH]);

    const PaymenButtonHandler = (rowData) => {

        const getReturnActUnpaidBalance = async () => {
            const response = await fetchActUnpaidBalance(rowData.id);
            console.log(response.data);

            let amountUAH = 0;

            if (response.status === 200) {
                formik.setFieldValue("id", rowData.id);
                formik.setFieldValue("cagent_id", rowData.cagent);
                response.data.forEach(payment => {
                    amountUAH += payment.sum * (payment.currency === "UAH" ? 1 : Cookies.get(`${payment.currency}Rate`));
                    payment.paymentMethod = null;
                });
                formik.setFieldValue("payments", response.data);
                setTotalSumUAH(amountUAH);
                setVisible(true);
            }
            else {
                toast.current.show({ severity: "error", summary: 'Помилка отримання даних', detail: `Помилка отримання даних акту ${rowData.id}`, life: 3000 });
            }

        }

        getReturnActUnpaidBalance();
    }

    const handlePaymentsAmountChange = (index, newAmount) => {
        const newPayments = [...formik.values.payments];
        newPayments[index].sum = newAmount;
        formik.setFieldValue('payments', newPayments);
    };

    const handlePaymentsCurrencyChange = (index, newCurrency) => {
        const newPayments = [...formik.values.payments];
        newPayments[index].currency = newCurrency;
        formik.setFieldValue('payments', newPayments);
    };

    const handlePaymentMethodChange = (index, value) => {
        const newPayments = [...formik.values.payments];
        newPayments[index].paymentMethod = value;
        formik.setFieldValue('payments', newPayments);
    };

    const deletePayment = (index) => {
        console.log(index);
        const newPayments = [...formik.values.payments];
        newPayments.splice(index, 1);
        formik.setFieldValue('payments', newPayments);
    };

    const addPaymentToFormik = () => {
        const newPayment = [
           ...formik.values.payments,
           { currency: "UAH", sum: 0.00, paymentMethod: null }
        ];
        formik.setFieldValue('payments', newPayment);
    };

    const EditReturnActHandler = rowData => {
        const route = '/productsreturn/editproductsreturn/' + rowData.id;
        navigate(route, { state: { id: rowData.id } });
    }

    const formatAdditionalButtons = (rowData) => {
        let buttons = [];
        if(rowData.status !== "FINALIZED"){
            buttons.push({
                label: 'Фіналізувати',
                icon: 'pi pi-lock',
                command: (event) => { confirmFinalize(event, rowData) }
            });
        }
        else {
            if (rowData.pay_status !== "PAYED") {
                buttons.push({
                    label: 'Оплатити',
                    icon: 'pi pi-money-bill',
                    command: () => { PaymenButtonHandler(rowData) }
                });
            }
        }
        
        if(rowData.can_be_deleted){
            buttons.push({
                label: 'Видалити',
                icon: 'pi pi-trash',
                command: (event) => { confirmDelete(event, rowData) }
            });
        }

        buttons = buttons.length > 0 ? buttons : null;
        return buttons
    };

    const actionBodyTemplate = (rowData) => {
        const buttons = formatAdditionalButtons(rowData);
        
        const buttonLabel = rowData.status !== "FINALIZED" ? "Редагувати" : "Деталі акту";
        const buttonIcon = rowData.status !== "FINALIZED" ? "pi pi-pencil" : "pi pi-eye";

        return (
            <>
                {buttons !== null ? (
                    <SplitButton 
                        label={buttonLabel}
                        icon={buttonIcon}
                        onClick={() => EditReturnActHandler(rowData)} 
                        model={buttons}
                        text
                        ref={buttonRef} // Set the ref here
                    />
                ) : (
                    <Button
                        label={buttonLabel}
                        icon={buttonIcon}
                        onClick={() => EditReturnActHandler(rowData)} 
                        text
                    />
                )}
            </>
        );
    };

    const toolbarRightTemplate = () => {
        return (<div className="flex flex-wrap gap-2">
            <Button label="Додати акт повернення товару" severity="success" className={commonStyle.addButton} onClick={CreateNewProductsReturnButtonHandler} />
        </div>)
    }

    return (<>
        <Toast ref={toast}/>
        <Toolbar className="mb-4" end={toolbarRightTemplate}></Toolbar>
        <div className='grid'>
            <div className='col-12 justify-content-center'>
                <DataTable value={productReturnActs} stripedRows lazy rows={lazyState.rows} onPage={onPage} loading={loading} first={lazyState.first} onSort={onSort} sortField={lazyState.sortField} sortOrder={lazyState.sortOrder}
                filterDisplay="row" dataKey="id" onFilter={onFilter} filters={lazyState.filters} emptyMessage="Актів повернення не знайдено" paginator totalRecords={totalRecords} rowsPerPageOptions={[10, 20]}>
                    <Column field="act_number" header="Номер акту" sortable  style={{ width: '7%' }}/>
                    <Column field="act_date" dataType="date" header="Дата акту" />
                    <Column field="division" header="Підрозділ" sortable filter filterElement={divisionRowFilterTemplate} body={rowData => ctx.divisions[rowData.division]} style={{ width: '10%' }} />
                    <Column field="cagent" header="Клієнт" sortable filter filterElement={cagentRowFilterTemplate} body={rowData => ctx.clients[rowData.cagent]} style={{ width: '13%' }} />
                    <Column field="amountUAH" header="Сума, грн" body={rowData => formatCurrency(rowData.amountUAH, "UAH")} sortable />
                    <Column field="pay_status" header="Оплачено" filter filterElement={payStatusRowFilterTemplate} body={rowData => ctx.pay_statuses[rowData.pay_status]} style={{ width: '10%' }} />
                    <Column field="status" header="Статус" body={rowData => <ActStatusCircle rowData={rowData}/>} sortable style={{ width: '4%' }} />
                    <Column field="created_by" header="Користувач" />
                    <Column field="created_at" dataType="date" header="Дата" body={rowData => formatDate(rowData.created_at)} sortable />
                    <Column field="action" body={actionBodyTemplate} />
                </DataTable>
            </div>
            <ConfirmDialog group="headless" />
        </div>
        <Sidebar className="responsive-sidebar" header="Оплата товару" visible={visible} position="right" onHide={() => setVisible(false)}>
                <form onSubmit={formik.handleSubmit}>
                    {formik.values.payments.length === 0 && (
                        <div className="mb-2">
                            <Message className='sideBarMessage' severity="error" text="Додайте валюту і спосіб оплати" />
                        </div>
                    )}
                    {formik.values.payments.map((currencyAmount, index) => (
                        <div className="grid py-3" key={index}>
                            <div className="col-10 p-inputgroup py-0 flex-1">
                                <span className={`${commonStyle.currencySpan} p-inputgroup-addon`}>
                                    <Dropdown
                                        className={commonStyle.currencyDropDown}
                                        value={currencyAmount.currency}
                                        onChange={e => handlePaymentsCurrencyChange(index, e.value)}
                                        options={currencies.map(currency => ({ label: currency, value: currency }))}
                                    />
                                </span>
                                <InputText
                                    name={`currencyValuePair[${index}].amount`}
                                    value={currencyAmount.sum}
                                    onChange={e => handlePaymentsAmountChange(index, e.target.value)}
                                />
                            </div>
                            <div className="col-2">
                                <Button className="noStyleButton" icon="pi pi-trash" severity="danger" type='button' text onClick={() => deletePayment(index)} />
                            </div>
                            <div className="col-10 py-0">
                                <Dropdown
                                    className={formik.errors.payments && formik.errors.payments[index] && formik.touched.payments && formik.touched.payments[index] ? `${commonStyle.fullWidth} p-invalid` : `${commonStyle.fullWidth}`}
                                    value={currencyAmount.paymentMethod}
                                    onChange={e => handlePaymentMethodChange(index, e.value)}
                                    options={paymentMethods.map(method => ({ label: method, value: method }))}
                                    placeholder="Спосіб оплати"
                                />
                                {formik.errors.payments && formik.errors.payments[index] && formik.touched.payments && formik.touched.payments[index] && (
                                    <small className={commonStyle.errorSmall}>{formik.errors.payments[index].paymentMethod}</small>
                                )}
                            </div>
                        </div>
                    ))}
                    <div className='mb-2'>
                        <Message className={commonStyle.fullWidth} severity={unDividedSum > 0 ? "warn" : "success"} text={`Нерозподілена сума: ${unDividedSum} грн.`} />
                    </div>
                    <div>
                        <Button label="+ Додати валюту" severity="info" type="button" className={`${commonStyle.fullWidth} ${commonStyle.editButton}`} onClick={addPaymentToFormik} />
                        {formik.values.payments.length !== 0 && <Button label="Оплатити" severity="success" type="submit" className={`${commonStyle.fullWidth} ${commonStyle.addButton} mt-1`} />}
                    </div>
                </form>
            </Sidebar>
    </>);
}

export default ProductsReturn;