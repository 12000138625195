// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Layout_companyLogo__kAVM3{
    text-align: center;
    margin: 0;
}

.Layout_logoStyle__yw5FW{
    width: 60%; /* Make the image take up 100% of the width of its container */
    height: auto; /* Maintain the aspect ratio */
    aspect-ratio: 3/2; /* Set the aspect ratio to 2/3 (height is 2/3 of the width) */
    object-fit: cover; /* Optionally, ensure the image covers the area well */
}`, "",{"version":3,"sources":["webpack://./src/components/Layout.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,SAAS;AACb;;AAEA;IACI,UAAU,EAAE,8DAA8D;IAC1E,YAAY,EAAE,8BAA8B;IAC5C,iBAAiB,EAAE,6DAA6D;IAChF,iBAAiB,EAAE,sDAAsD;AAC7E","sourcesContent":[".companyLogo{\n    text-align: center;\n    margin: 0;\n}\n\n.logoStyle{\n    width: 60%; /* Make the image take up 100% of the width of its container */\n    height: auto; /* Maintain the aspect ratio */\n    aspect-ratio: 3/2; /* Set the aspect ratio to 2/3 (height is 2/3 of the width) */\n    object-fit: cover; /* Optionally, ensure the image covers the area well */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"companyLogo": `Layout_companyLogo__kAVM3`,
	"logoStyle": `Layout_logoStyle__yw5FW`
};
export default ___CSS_LOADER_EXPORT___;
