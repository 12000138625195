import { formatCurrency } from "../../../../Utils/Utils";
import { paymentMethodTranslations, paymentStatusTranslation, TranslatePaymentMethod, TranslatePaymentStatus, TranslatePaymentType, TranslateTransactionType } from "../../../../Utils/TranslationUtils";
import useApi from "../../../../Utils/BackendClient";
import { useEffect, useRef, useState } from "react";

import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import { Sidebar } from "primereact/sidebar";
import { Toast } from "primereact/toast";

import commonStyle from "../../CommonStyles.module.css";

const Payments = () => {
    const toast = useRef();
    const { fetchPayments, fetchPaymentTransactions } = useApi();

    const [payments, setPayments] = useState([]);
    const [transactions, setTransactions] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [loading, setLoading] = useState(true);
    const [visible, setVisible] = useState(false);
    const [lazyState, setlazyState] = useState({
        first: 0,
        rows: 10,
        page: 1,
        sortField: "date",
        sortOrder: 1,
        filters:{
            cagent: { value: null, matchMode: 'equals' },
            method: {value: null, matchMode: 'equals' },
            status: {value: null, matchMode: 'equals' },
            date: { value: null, matchMode: 'equals' }
        }
    });

    useEffect(() => {
        const fetchData = async () => {

            const response = await fetchPayments(lazyState);

            if (response.status === 200) {
                setTotalRecords(response.data.count);
                setPayments(response.data.results);
                console.log("Number of payments", response.data.count);
            }
            else{
                if(response.status === 403){
                    toast.current.show({ severity: "error", summary: "Помилка завантаження списку платежів", detail: "Користувач не має права перегляду списку платежів", life: 3000 });
                }
                else{
                    toast.current.show({ severity: "error", summary: "Помилка завантаження списку платежів", detail: "Помилка серверу " + response.status, life: 3000 });
                }
            }

            setLoading(false);
        }

        fetchData();

    }, [lazyState, fetchPayments]);

    const getPaymentTransactions = (rowData) => {

        const fetchData = async () => {

            const response = await fetchPaymentTransactions(rowData.id);

            if (response.status === 200){
                setTransactions(response.data.results);
            }
            else
            {
                if(response.status === 403){
                    toast.current.show({ severity: "error", summary: "Помилка завантаження списку транзакцій", detail: "Користувач не має права перегляду списку платежів", life: 3000 });
                }
                else{
                    toast.current.show({ severity: "error", summary: "Помилка завантаження списку транзакцій", detail: "Помилка серверу " + response.status, life: 3000 });
                }
            }
        }

        fetchData();
        setVisible(true);
    }

    const onPage = (event) => {
        event.page = event.page + 1;
        setlazyState(event);
    };

    const onSort = (event) => {
        event.page = lazyState.page;
        setlazyState(event);
    }

    const onFilter = (event) => {
        event.page = 1;
        setlazyState(event);
    }

    const methodTemplate = (rowData) => {
        return TranslatePaymentMethod(rowData.method);
    }

    const paymentTypeTemplate = (rowData) => {
        return TranslatePaymentType(rowData.type);
    }

    const statusTemplate = (rowData) => {
        return TranslatePaymentStatus(rowData.status);
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <Button
                label="Транзакції"
                icon="pi pi-eye"
                onClick={() => getPaymentTransactions(rowData)}
                text
            />
        );
    };

    const dateRowFilterTemplate = (options) => {
        return (
            <Calendar 
                value={options.value} 
                onChange={
                    (e) => options.filterApplyCallback(e.value)
                }
                dateFormat="dd.mm.yy"
                selectionMode="range"
                readOnlyInput 
                hideOnRangeSelection 
            />
        );
    };

    const methodRowFilterTemplate = (options) => {
        return (
            <Dropdown
                value={options.value}
                options={Object.entries(paymentMethodTranslations).map(([id, name]) => ({
                    label: name,
                    value: id
                }))}
                onChange={(e) => options.filterApplyCallback(e.value)}
                optionLabel="label"
                className="p-column-filter"
                style={{ minWidth: '10rem' }}
            />
        );
    };

    const statusRowFilterTemplate = (options) => {
        return (
            <MultiSelect
                value={options.value}
                options={Object.entries(paymentStatusTranslation).map(([id, name]) => ({
                    label: name,
                    value: id
                }))}
                onChange={(e) => options.filterApplyCallback(e.value)}
                optionLabel="label"
                maxSelectedLabels={1}
                selectedItemsLabel="{0} вибрано"
                className="p-column-filter"
                style={{ minWidth: '10rem' }}
            />
        );
    };

    return (<>
        <Toast ref={toast} />
        <div className='grid mt-3'>
            <div className='col-12 justify-content-center'>
                <DataTable value={payments} stripedRows lazy rows={lazyState.rows} paginator onPage={onPage}
                    first={lazyState.first} loading={loading} totalRecords={totalRecords} rowsPerPageOptions={[10, 20, 50]} tableStyle={{ minWidth: '60rem' }}
                    onSort={onSort} sortField={lazyState.sortField} sortOrder={lazyState.sortOrder}
                    filterDisplay="row" dataKey="id" onFilter={onFilter} filters={lazyState.filters}>
                    <Column field="date" header="Дата" sortable filter filterElement={dateRowFilterTemplate} showFilterMenu={false}
                    body={(rowData) => new Date(rowData.date).toLocaleDateString('ua-UA', {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric'})}
                     style={{ width: '15%' }} />
                    <Column field="method" header="Метод" filter filterElement={methodRowFilterTemplate} body={methodTemplate} showFilterMenu={false} style={{ width: '10%' }}/>
                    <Column field="type" header="Тип оплати" body={paymentTypeTemplate} style={{ width: '15%' }}/>     
                    <Column field="sum" header="Сума" body={(rowData) => formatCurrency(rowData.sum, rowData.currency)} style={{ width: '10%' }}/>
                    <Column field="status" header="Статус оплати" filter filterElement={statusRowFilterTemplate} showFilterMenu={false} body={statusTemplate} style={{ width: '10%' }} />
                    <Column field="cagent_name" header="Контрагент" style={{ width: '20%' }}/>
                    <Column field="undistributed_sum" header="Нерозподілена сума" body={(rowData) => formatCurrency(rowData.undistributed_sum, rowData.currency)} style={{ width: '10%' }}/>
                    <Column field="action" body={actionBodyTemplate} style={{ width: '10%' }}/>
                </DataTable>
            </div>
        </div>
        <Sidebar visible={visible} position="right" onHide={() => setVisible(false)} className={commonStyle.sidebarTable}>
            <h2>Список транзакцій</h2>
            <DataTable value={transactions} stripedRows >            
                <Column field="transaction_id" header="ID"/>
                <Column field="transaction_type" header="Тип" body={(rowData) => TranslateTransactionType(rowData.transaction_type)}/>
                <Column field="act_id" header="ID акту"/>  
                <Column field="distribution_sum" header="Розподілена сума" body={(rowData) => formatCurrency(rowData.distribution_sum, rowData.currency)} />
                <Column field="distribution_sum_UAH" header="Розподілена сума, грн" body={(rowData) => formatCurrency(rowData.distribution_sum_UAH, "UAH")} />
                <Column field="cagent_name" header="Контрагент" />
            </DataTable>
        </Sidebar>
    </>)
}

export default Payments;