import { Tooltip } from "primereact/tooltip";

import commonStyle from "../content/CommonStyles.module.css";

const ActStatusCircle = ({ rowData }) => {
    return (
        <div>
            {rowData.status === "PENDING" ? (
                <div
                    className={`${commonStyle.statusCircle} ${commonStyle.pending}`}
                    data-pr-tooltip="Драфт"
                    data-pr-position="top"
                    id={`statusCircle-${rowData.id}`}
                ></div>
            ) : (
                <div
                    className={`${commonStyle.statusCircle} ${commonStyle.active}`}
                    data-pr-tooltip="Фіналізований"
                    data-pr-position="top"
                    id={`statusCircle-${rowData.id}`}
                ></div>
            )}
            <Tooltip target={`#statusCircle-${rowData.id}`} />
        </div>
    );
};

export default ActStatusCircle;