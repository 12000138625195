import { useState } from "react";
import EnumsContext from "./enums-context";

const EnumsProvider = props => {

    const [product_groups, setProductGroups] = useState([]);
    const [statuses, setStatuses] = useState([]);
    const [divisions, setDivisions] = useState([]);
    const [financial_data_types, setFinDataTypes] = useState([]);
    const [cagent_types, setCagentTypes] = useState([]);
    const [partners, setPartners] = useState([]);
    const [pay_statuses, setPayStatuses] = useState([]);
    const [service_groups, setServiceGroups] = useState([]);
    const [regions, setRegions] = useState([]);
    const [clients, setClients] = useState([]);

    const setProductGroupsDetails = details => {
        setProductGroups(details);
    };

    const setStatusEnums = statuses => {
        setStatuses(statuses);
    }

    const setDivisionEnums = divisions => {
        setDivisions(divisions);
    }

    const setFinDataTypesEnums = dataTypes => {
        setFinDataTypes(dataTypes);
    }

    const setCagentTypesEnums = cagentTypes => {
        setCagentTypes(cagentTypes);
    }

    const setPartnersEnums = partners => {
        setPartners(partners);
    }

    const setPayStatusesEnums = payStatuses => {
        setPayStatuses(payStatuses);
    }

    const setServiceGroupsEnums = serviceGroups => {
        setServiceGroups(serviceGroups);
    }

    const setRegionsEnums = regions => {
        setRegions(regions);
    }

    const setClientsEnums = clients => {
        setClients(clients);
    }

    const enumsContext = {
        product_groups: product_groups,
        statuses: statuses,
        divisions: divisions,
        financial_data_types: financial_data_types,
        cagent_types: cagent_types,
        partners: partners,
        pay_statuses: pay_statuses,
        service_groups: service_groups,
        regions: regions,
        clients: clients,
        setProductGroups: setProductGroupsDetails,
        setStatuses: setStatusEnums,
        setDivisions: setDivisionEnums,
        setFinDataTypes: setFinDataTypesEnums,
        setCagentType: setCagentTypesEnums,
        setPartners: setPartnersEnums,
        setPayStatuses: setPayStatusesEnums,
        setServiceGroups: setServiceGroupsEnums,
        setRegions: setRegionsEnums,
        setClients: setClientsEnums
    };

    return <EnumsContext.Provider value={enumsContext}>
        {props.children}
    </EnumsContext.Provider>
}

export default EnumsProvider;