import EnumsContext from "../../../../context/enums-context";
import ProductStatusCircle from "../../../UIElements/ProductStatusCircle";
import useAuthenticatedRequest from "../../../../Utils/TokenrRefreshUtils";
import { useContext, useEffect, useRef, useState } from "react";
import useGetEnums from "../../../../Utils/EnumsUtils";
import { useLocation, useNavigate } from "react-router-dom";

import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup";
import { DataTable } from "primereact/datatable";
import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";

import commonStyle from "../../CommonStyles.module.css";
import { formatDate } from "../../../../Utils/Utils";

const Divisions = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const ctx = useContext(EnumsContext);
    const authenticatedRequest = useAuthenticatedRequest();
    const toast = useRef(null);

    const [divisions, setDivisions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [reloadData, setReloadData] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);

    const [lazyState, setlazyState] = useState({
        first: 0,
        rows: 10,
        page: 1,
        sortField: null,
        sortOrder: null,
    });

    useGetEnums(["divisions"]);

    useEffect(() => {
        if(location.state !== null){
            toast.current.show(location.state.toast);
        }
        location.state = null;
    },[location.state, location]);

    useEffect(() => {

        const fetchData = async () => {

            const url = process.env.REACT_APP_BACKEND_URL + "divisions/get_list/?page=" + lazyState.page + "&page_size=" + lazyState.rows;
            const options = {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json'
                }
            };

            const response = await authenticatedRequest(url, options);

            if (response.status !== 200){
                toast.current.show({ severity: "error", summary: "Помилка завантаження списку", detail: "Помилка сервера " + response.status, life: 3000 });
                setTotalRecords(0);
                setDivisions([]);
            }
            else{
                const parsedResponse = await response.json();
                //logging to remove later
                console.log(parsedResponse);
    
                setTotalRecords(parsedResponse.count);
                setDivisions(parsedResponse.results);
            }
            
            setLoading(false);
        }

        fetchData();

    }, [reloadData, lazyState, authenticatedRequest]);

    const ChangeDivisionStatusHandler = (rowData) => {

        const action = rowData.is_active ? "deactivate" : "activate";

        const callApi = async () => {

            const url = process.env.REACT_APP_BACKEND_URL + "divisions/" + action + "/" + rowData.id + "/";
            const options = {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json'
                }
            };

            const response = await authenticatedRequest(url, options);
            //logging to remove later
            console.log(response);

            if (response.status !== 200){ 
                toast.current.show({ severity: "error", summary: "Помилка зміни статусу", detail: "Помилка сервера " + response.status, life: 3000 });
            }
            else
            {
                toast.current.show({ severity: 'success', summary: rowData.is_active ? 'Деактивація' : "Активація", detail: rowData.is_active ? 'Підрозділ деактивовано' : "Підрозділ активовано", life: 3000 });
                ctx.setDivisions([]);
            }

            setReloadData(!reloadData);

        }

        callApi();
    }

    const DeleteButtonHandler = (rowData) => {
        const deleteData = async () => {

            const url = process.env.REACT_APP_BACKEND_URL + "divisions/delete/" + rowData.id + "/";
            const options = {
                method: "DELETE",
                headers: {
                    'Content-Type': 'application/json'
                }
            };

            const response = await authenticatedRequest(url, options);
            //logging to remove later
            console.log(response);

            if (response.status !== 204){ 
                toast.current.show({ severity: "error", summary: "Помилка видалення", detail: "Помилка сервера " + response.status, life: 3000 });
            }
            else
            {
                toast.current.show({ severity: "success", summary: 'Видалення', detail: 'Підрозділ успішно видалено', life: 3000 });
            }

            setReloadData(!reloadData);
        }

        deleteData();
    };

    const CreateNewDivisionHandler = () => {
        navigate("/divisions/newdivision");
    }

    const EditButtonHandler = (rowData) => {

        const route = '/divisions/editdivision/' + rowData.id;
        navigate(route, { state: { id: rowData.id } });
    }

    const onPage = (event) => {
        event.page = event.page +1;
        setlazyState(event);
    };

    const confirmDelete = (event, rowData) => {
        confirmPopup({
            group: 'headless',
            target: event.currentTarget,
            message: 'Підтвердження видалення групи',
            icon: 'pi pi-exclamation-triangle',
            defaultFocus: 'accept',
            acceptLabel: "Так",
            rejectLabel: "Hi",
            accept: () => {DeleteButtonHandler(rowData)}
        });
    }

    const changeStatus = (event, rowData) => {
        confirmPopup({
            group: 'headless',
            target: event.currentTarget,
            message: rowData.is_active ? 'Підтвердження деактивації групи' : 'Підтвердження активації групи',
            icon: 'pi pi-exclamation-triangle',
            defaultFocus: 'accept',
            acceptLabel: "Так",
            rejectLabel: "Hi",
            accept: () => {ChangeDivisionStatusHandler(rowData)}
        });
    }

    const toolbarRightTemplate = () => {
        return (<div className="flex flex-wrap gap-2">
            <Button label="Додати підрозділ" severity="success" className={commonStyle.addButton} onClick={CreateNewDivisionHandler} />
        </div>)
    }

    const divisionTemplate = (rowData) => {
        return ctx.divisions[rowData.root_division];
    }

    const actionBodyTemplate = rowData => {
        return (<div className="flex flex-wrap gap-2">
            <Button
                icon={rowData.is_active ? "pi pi-times" : "pi pi-check"}
                className={`p-button-rounded ${rowData.is_active ? commonStyle.warningTag + " p-button-warning" : commonStyle.addButton + " p-button-success"}`}
                tooltip={rowData.is_active ? "Деактивувати" : "Активувати"}
                onClick={(event) => changeStatus(event, rowData)}
            />
            <Button
                icon="pi pi-trash"
                className={`p-button-rounded p-button-danger ${rowData.can_be_deleted ? commonStyle.deleteButton : commonStyle.closeButton}`}
                tooltip={rowData.can_be_deleted ? "Видалити" : "Неможливо видалити"}
                tooltipOptions={{ showOnDisabled: true }}
                disabled={!rowData.can_be_deleted}
                onClick={(event) => confirmDelete(event, rowData)}
            />
            <Button
                icon="pi pi-pencil"
                className={`p-button-rounded p-button-info ${commonStyle.editButton}`}
                onClick={() => EditButtonHandler(rowData)}
            />
        </div>
        );
    };

    return (<>
        <Toast ref={toast} />   
        <Toolbar className="mb-4" end={toolbarRightTemplate} />
        <div className='grid'>
            <div className='col-12 justify-content-center'>
                <DataTable value={divisions} stripedRows lazy rows={lazyState.rows} paginator onPage={onPage} first={lazyState.first} loading={loading} totalRecords={totalRecords} rowsPerPageOptions={[10, 20, 50]} tableStyle={{ minWidth: '60rem' }} >
                    <Column field="name" header="Підрозділ" sortable />
                    <Column field="root_division" header="Підпорядковується" sortable body={divisionTemplate} />
                    <Column field="created_by" header="Користувач" sortable/>
                    <Column field="created_at" header="Дата" sortable body={rowData => formatDate(rowData.created_at)}/>
                    <Column field="is_active" header="Статус" body={rowData => <ProductStatusCircle rowData={rowData} type="statusCircle"/>}/>
                    <Column field="action" body={actionBodyTemplate} />
                </DataTable>
            </div>
            <ConfirmPopup group="headless" />
        </div>
    </>);
}

export default Divisions;